import { Actions } from "vuex";
import {
  WalletState,
  WalletGetters,
  WalletMutations,
  WalletActions,
} from "./types";

export const actions: Actions<
  WalletState,
  WalletActions,
  WalletGetters,
  WalletMutations
> = {};
