
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    closable: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  setup(props, context) {
    const close = () => {
      if (props.closable) {
        context.emit("update:open", !props.open);
      }
    };

    return { close };
  },
});
