
import { defineComponent, ref } from "vue";

export default defineComponent({
  inheritAttrs: false,
  props: {
    buttonClass: {
      type: String,
    },
  },
  setup(props) {
    const isOutlined = ref(props.buttonClass === "outlined");
    const isMain = ref(props.buttonClass === "main");

    return {
      isOutlined,
      isMain,
    };
  },
});
