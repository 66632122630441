<template>
  <div class="bg-gray-100 min-h-screen">
    <div class="pt-32 text-center text-primary-dark font-bold">
      <p class="mb-10 text-3xl">認証エラーが発生しました</p>
    </div>
    <div class="p-4 text-sm text-center">
      <p v-if="!!errors && errors.invalid_params">
        {{ errors.invalid_params[0].reason }}
      </p>
      <p>お手数ですが、ログインからやり直ししてください。</p>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "AuthenticationError",
  setup() {
    const store = useStore();
    const errors = computed(() => store.state.promise.errors);
    return { errors };
  },
});
</script>
