
import { computed, defineComponent, ref } from "vue";
import Gift from "./exchangeHistory/Gift.vue";
import ExchangeResult from "./exchangeHistory/ExchangeResult.vue";
import ReceiptChargeRequest from "./exchangeHistory/ReceiptChargeRequest.vue";
import PointLog from "./exchangeHistory/PointLog.vue";
import { useStore } from "@consumer/store";
import {
  getLocaleDateString,
  getLocaleDatetimeString,
} from "@shared/utils/getLocaleDateString";
import informationCircle from "@assets/informationCircle.svg";

export default defineComponent({
  name: "ExchangeHistory",
  components: {
    Gift,
    ExchangeResult,
    ReceiptChargeRequest,
    PointLog,
  },
  setup() {
    return {
      ...useTab(),
      ...useReceipts(),
      ...useGifts(),
      ...useHistories(),
      ...usePointLogs(),
      informationCircle,
    };
  },
});

const useTab = () => {
  const store = useStore();
  const myPageConfig = computed(() => store.state.campaign.my_page_config);
  const hasReceiptChargeConfig = computed(
    () => store.getters["campaign/has_receipt_charge_config"]
  );
  const tabNames = hasReceiptChargeConfig.value
    ? ({
        RECEIPT: "receipt",
        GIFT: "gift",
        HISTORY: "history",
        POINT_LOGS: "pointLogs",
      } as const)
    : ({
        GIFT: "gift",
        HISTORY: "history",
      } as const);
  const tabCount = computed(() =>
    myPageConfig.value
      ? Number(myPageConfig.value.exchanging_history_option ? 1 : 0) +
        Number(myPageConfig.value.receipt_uploading_history_visibility) +
        Number(myPageConfig.value.received_gift_history_option ? 1 : 0)
      : 0
  );

  type TabNames = typeof tabNames;

  const activeTab = ref<TabNames[keyof TabNames]>(
    myPageConfig.value?.receipt_uploading_history_visibility
      ? tabNames.RECEIPT
      : myPageConfig.value?.received_gift_history_option
      ? tabNames.GIFT
      : tabNames.HISTORY
  );
  const isReceiptTabActive = computed(
    () => activeTab.value === tabNames.RECEIPT
  );
  const isGiftTabActive = computed(() => activeTab.value === tabNames.GIFT);
  const isHistoryTabActive = computed(
    () => activeTab.value === tabNames.HISTORY
  );
  const isPointLogsTabActive = computed(
    () => activeTab.value === tabNames.POINT_LOGS
  );
  const changeActiveTab = (tabName: TabNames[keyof TabNames]): void => {
    activeTab.value = tabName;
  };
  const lotteryRabelVisibility = computed(
    () =>
      myPageConfig.value?.exchanging_history_option?.lottery_rabel_visibility ||
      false
  );
  const exchangingRequestDateTitle = computed(
    () =>
      myPageConfig.value?.exchanging_history_option
        ?.exchanging_request_date_title || "抽選日時"
  );
  const exchangingHistoryTitle = computed(
    () => myPageConfig.value?.exchanging_history_option?.title || ""
  );

  return {
    tabCount,
    myPageConfig,
    hasReceiptChargeConfig,
    tabNames,
    isReceiptTabActive,
    isGiftTabActive,
    isHistoryTabActive,
    isPointLogsTabActive,
    activeTab,
    changeActiveTab,
    lotteryRabelVisibility,
    exchangingRequestDateTitle,
    exchangingHistoryTitle,
  };
};

const useReceipts = () => {
  const store = useStore();
  const chargeRequests = computed(
    () => store.state.receiptChargeRequests.items
  );

  const openChargeRequestsToggle = ref(false);
  const showableChargeRequests = computed(() =>
    chargeRequests.value === null
      ? []
      : !openChargeRequestsToggle.value
      ? chargeRequests.value.slice(0, 3)
      : chargeRequests.value
  );
  const isCharngeRequestsTogglable = computed(
    () => !!chargeRequests.value && chargeRequests.value.length > 3
  );
  const gettableNextChargeRequests = computed(
    () =>
      openChargeRequestsToggle.value &&
      store.state.receiptChargeRequests.pagenation?.has_next_page
  );
  const getNextChargeRequests = () =>
    store.dispatch("promise/getNextReceiptChargeRequests", {
      campaign_url_code: store.state.campaign.url_code,
    });
  const toggleReadChargeRequestsMore = () => {
    openChargeRequestsToggle.value = !openChargeRequestsToggle.value;
  };

  return {
    openChargeRequestsToggle,
    showableChargeRequests,
    isCharngeRequestsTogglable,
    gettableNextChargeRequests,
    getNextChargeRequests,
    toggleReadChargeRequestsMore,
  };
};

const useGifts = () => {
  const { dateParsedExchangeResults } = useParsedExchangeResults();
  const showFullGifts = ref(false);

  const gifts = computed(() =>
    dateParsedExchangeResults.value.filter(
      (exchangeResult) => !!exchangeResult.gift
    )
  );

  const showableGifts = computed(() =>
    !showFullGifts.value ? gifts.value.slice(0, 3) : gifts.value
  );

  const toggleReadGiftMore = () => {
    showFullGifts.value = !showFullGifts.value;
  };

  return { gifts, showFullGifts, showableGifts, toggleReadGiftMore };
};

const usePointLogs = () => {
  const store = useStore();
  const pointLogs = computed(() => store.state.pointLogs.items);
  const showFullPointLogs = ref(false);

  const showableParsedPointLogs = computed(() => {
    if (!pointLogs.value) return [];

    return showFullPointLogs.value
      ? pointLogs.value.sort(
          (a, b) =>
            new Date(b.logged_at).getTime() - new Date(a.logged_at).getTime()
        )
      : pointLogs.value
          .sort(
            (a, b) =>
              new Date(b.logged_at).getTime() - new Date(a.logged_at).getTime()
          )
          .slice(0, 3);
  });

  const togglePointLogsMore = () => {
    showFullPointLogs.value = !showFullPointLogs.value;
  };
  return { showableParsedPointLogs, togglePointLogsMore, showFullPointLogs };
};

const useHistories = () => {
  const { dataTimeParsedExchangeResults } = useParsedExchangeResults();
  const showFullExchangeResults = ref(false);

  const showableExchangeResults = computed(() =>
    !showFullExchangeResults.value
      ? dataTimeParsedExchangeResults.value.slice(0, 5)
      : dataTimeParsedExchangeResults.value
  );

  const toggleReadExchangeResults = () => {
    showFullExchangeResults.value = !showFullExchangeResults.value;
  };

  return {
    dataTimeParsedExchangeResults,
    showFullExchangeResults,
    showableExchangeResults,
    toggleReadExchangeResults,
  };
};

const useParsedExchangeResults = () => {
  const store = useStore();
  const exchangeResults = computed(() => store.state.exchangeResults.items);

  const dateSortedExchangeResults = computed(() => {
    if (!exchangeResults.value) return [];
    return exchangeResults.value.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  });

  const dateParsedExchangeResults = computed(() => {
    return dateSortedExchangeResults.value.map((exchangeResult) => ({
      ...exchangeResult,
      created_at: getLocaleDateString(exchangeResult.created_at),
    }));
  });

  const dataTimeParsedExchangeResults = computed(() => {
    return dateSortedExchangeResults.value.map((exchangeResult) => ({
      ...exchangeResult,
      created_at: getLocaleDatetimeString(exchangeResult.created_at),
    }));
  });

  return { dateParsedExchangeResults, dataTimeParsedExchangeResults };
};
