import { createStore, createLogger, ExStore } from "vuex";
import actionCable from "./modules/actionCable";
import campaign from "./modules/campaign";
import internalApiCampaign from "./modules/internalApiCampaign";
import loginUser from "./modules/loginUser";
import wallet from "./modules/wallet";
import directUpload from "./modules/directUpload";
import receiptChargeRequests from "./modules/receiptChargeRequests";
import failedExchangeRequests from "./modules/failedExchangeRequests";
import exchangeResults from "./modules/exchangeResults";
import exchange from "./modules/exchange";
import receiptCharging from "./modules/receiptCharging";
import pointLogs from "./modules/pointLogs";
import ui from "./modules/ui";
import promise from "./modules/promise";
import snackbar from "./modules/snackbar";

const debug = process.env.NODE_ENV !== "production";

export const store = createStore({
  modules: {
    actionCable,
    campaign,
    internalApiCampaign,
    loginUser,
    wallet,
    directUpload,
    receiptChargeRequests,
    failedExchangeRequests,
    exchangeResults,
    exchange,
    receiptCharging,
    pointLogs,
    ui,
    promise,
    snackbar,
  },
  plugins: debug ? [createLogger()] : [],
});

export const useStore = () => {
  return store as ExStore;
};
