<template>
  <div class="flex justify-center m-2">
    <div v-if="uploadingGifVisible">
      <div
        class="z-40 absolute left-0 top-0 w-full h-full bg-black bg-gray-600 bg-opacity-70 items-center flex justify-center"
      >
        <img :src="uploadingGif" />
      </div>
    </div>
    <CardWithTitle
      v-else
      :title="cameraShootingMethodTitle"
      class="sm:w-6/12 flex flex-col items-center text-center"
    >
      <ReceiptUploadingNote />
      <form
        method="post"
        class="m-0 w-full"
        @submit.prevent="postChargeRequestReceiptCharging"
      >
        <BaseButton type="button" class="w-4/5 mt-5 mb-2" buttonClass="main">
          <label class="w-full text-center">
            {{ activeCameraButtonLabel }}
            <input
              type="file"
              @change="onChangeReceiptImage"
              style="display: none"
              accept="image/*"
              capture="camera"
              required
            /> </label
        ></BaseButton>
        <a
          class="mb-3 block text-gray-600 text-sm underline"
          href="https://docs.giftee.biz/business/downloads/mileage_FAQ.pdf"
          target="_blank"
          rel="noopener noreferrer"
          >カメラが立ち上がらない場合</a
        >

        <div v-if="imagePreviewUrl">
          <img :src="imagePreviewUrl" class="w-2/5 text-center mx-auto my-2" />
          <div v-if="declarationAmountRequired">
            <div class="text-secondary-700">
              {{ declarationAmountDescription }}
            </div>
            <input
              type="number"
              v-model="declarationAmount"
              min="1"
              class="h-8 w-4/5 px-2 m-2 text-s bg-white rounded-lg border-primary-main border text-gray-800"
              required
            />{{ declarationAmountUnit }}
            <div
              class="text-secondary-700 mx-auto mt-4 w-4/5 align-center text-center"
            >
              <p class="text-left">
                {{ photoConfirmation }}
              </p>
            </div>
          </div>
          <BaseButton class="w-4/5 my-3" buttonClass="main"
            ><span class="text-center w-full">{{
              uploadPhotoButtonLabel
            }}</span></BaseButton
          >
        </div>
      </form>
    </CardWithTitle>
  </div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref, computed, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@consumer/store";
import uploadingGif from "@assets/receiptUploading.gif";
import ReceiptUploadingNote from "./ReceiptUploadingNote.vue";
import CardWithTitle from "@consumer/components/campaign/shared/CardWithTitle.vue";
import imageCompression from "browser-image-compression";
import { routeNames } from "@consumer/router/routeNames";

export default defineComponent({
  name: "ReceiptUploadForm",
  components: { ReceiptUploadingNote, CardWithTitle },
  props: {
    chargeConfigUrlCode: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { chargeConfigUrlCode } = props;
    const store = useStore();
    const { params } = useRoute();
    const router = useRouter();
    let receiptImage: Ref<File | null> = ref(null);
    let imagePreviewUrl: Ref<string | null> = ref(null);
    let declarationAmount: Ref<number | undefined> = ref(undefined);
    const chargeConfig = computed(() =>
      store.state.campaign.charge_configs.find(
        (chargeConfig) => chargeConfig.url_code === chargeConfigUrlCode
      )
    );
    const declarationAmountRequired = computed(
      () => !!chargeConfig?.value?.receipt_option?.declaration_amount_visible
    );
    const uploadingGifVisible = computed(
      () =>
        (store.state.receiptCharging.requestReceiptResult === "requesting" ||
          store.state.receiptCharging.requestReceiptResult === "analysing") &&
        !store.state.receiptCharging.requestTimeout
    );

    const postChargeRequestReceiptCharging = async () => {
      if (!receiptImage.value) return;
      if (declarationAmountRequired.value && !declarationAmount.value) return;

      const compressedImage = await imageCompression(receiptImage.value, {
        maxSizeMB: 1,
      });

      store.dispatch("promise/postChargeRequestReceiptCharging", {
        campaign_url_code: params.campaign_url_code,
        charge_config_url_code: chargeConfigUrlCode,
        receipt_image: compressedImage,
        declaration_amount: declarationAmount.value,
        router,
      });
      receiptImage.value = null;
      imagePreviewUrl.value = null;
    };

    const onChangeReceiptImage = (e: HTMLEvent<HTMLInputElement>) => {
      const target = e.target;
      receiptImage.value = target && target.files ? target.files[0] : null;
      if (receiptImage.value) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (typeof e.target?.result === "string") {
            imagePreviewUrl.value = e.target.result;
          }
        };
        reader.readAsDataURL(receiptImage.value);
      }
    };

    // watchEffectが反応しないように同期的に実行
    store.commit("exchange/updateForceDefeat", false);

    watchEffect(() => {
      if (store.state.exchange.forceDefeat) {
        router.push({
          name: routeNames.EXCHANGE_LOTTERY_IMAGE,
          params: { campaign_url_code: params.campaign_url_code },
        });
      }
    });

    const cameraShootingMethodTitle = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "receipt_charging.upload_receipt.form.camera_shooting_method.title",
        "撮影方法"
      )
    );
    const activeCameraButtonLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "receipt_charging.upload_receipt.form.active_camera_button_label",
        "レシートを撮影する"
      )
    );
    const declarationAmountUnit = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "receipt_charging.upload_receipt.form.declaration_amount.unit",
        "円"
      )
    );
    const declarationAmountDescription = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "receipt_charging.upload_receipt.form.declaration_amount.description",
        "読み取り時の誤認を防ぐためレシートに記載の税込金額の合計をご入力ください"
      )
    );
    const photoConfirmation = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "receipt_charging.upload_receipt.form.photo_confirmation",
        "日付・合計金額・電話番号が写っているかご確認ください。"
      )
    );
    const uploadPhotoButtonLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "receipt_charging.upload_receipt.form.upload_photo_button_label",
        "レシートをアップロードする"
      )
    );

    return {
      uploadingGif,
      uploadingGifVisible,
      imagePreviewUrl,
      onChangeReceiptImage,
      declarationAmount,
      postChargeRequestReceiptCharging,
      declarationAmountRequired,
      cameraShootingMethodTitle,
      activeCameraButtonLabel,
      declarationAmountUnit,
      declarationAmountDescription,
      photoConfirmation,
      uploadPhotoButtonLabel,
    };
  },
});
</script>
