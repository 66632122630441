<template>
  <Spinner />
</template>
<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@consumer/store/index";
export default defineComponent({
  name: "Login",
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      store.dispatch("promise/postAuthentication", router);
    });
  },
});
</script>
