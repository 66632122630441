<template>
  <div class="bg-white w-full rounded overflow-hidden shadow-lg p-2">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>
