<template>
  <div v-if="exchangeResultAccessable">
    <router-view />
  </div>
  <div v-else />
</template>
<script lang="ts">
import { computed, defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "Exchange",
  setup() {
    const store = useStore();
    const router = useRouter();
    const { params } = useRoute();
    const exchangeResultAccessable = computed(
      () => store.state.exchange && !!store.state.exchange.exchangeConfigUrlCode
    );

    if (!exchangeResultAccessable.value) {
      router.push({
        name: routeNames.CAMPAING_MY_PAGE,
        params: { campaign_url_code: params.campaign_url_code },
      });
    }

    return {
      exchangeResultAccessable,
    };
  },
});
</script>
