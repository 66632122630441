
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "@consumer/store";
import { routeNames } from "@consumer/router/routeNames";

export default defineComponent({
  name: "Spinner",
  setup() {
    const store = useStore();
    const route = useRoute();
    const loading = computed(() => store.state.promise.loading);
    const isEnabledRoute = computed(() => {
      return route.name !== routeNames.EXCHANGE_LOTTERY_IMAGE;
    });

    return { loading, isEnabledRoute };
  },
});
