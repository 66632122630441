<template>
  <div>
    <router-link :to="receiptChargingLinkTo" class="cursor-pointer">
      <BaseButton
        buttonClass="main"
        :disabled="!chargeable"
        class="my-4 w-4/5 justify-center"
        :class="{
          'input-disabled': !chargeable,
        }"
        >{{ buttonLabel }}</BaseButton
      ></router-link
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "@consumer/store";
import { routeNames } from "@consumer/router/routeNames";
import { ChargeConfig } from "@consumer/services/api/publicApi/campaigns";

export default defineComponent({
  name: "ReceiptChargeLink",
  props: {
    chargeConfig: {
      type: Object as PropType<ChargeConfig>,
      required: true,
    },
    chargeable: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { chargeable, chargeConfig } = toRefs(props);
    const { params } = useRoute();
    const store = useStore();
    const receiptChargingLinkTo = computed(() => ({
      name: routeNames.CHARGE_RECEIPT_UPLOAD,
      params: {
        campaign_url_code: params.campaign_url_code,
        charge_config_url_code: chargeConfig.value.url_code,
      },
    }));
    const buttonLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "receipt_charging.link_to_receipt_upload_form_link_button_label",
        "レシート画像のアップロードに進む"
      )
    );
    return { chargeable, receiptChargingLinkTo, buttonLabel };
  },
});
</script>
