<template>
  <div class="bg-gray-100 min-h-screen">
    <div class="pt-32 text-center text-primary-dark font-bold">
      <p class="mb-1">
        システムエラーが発生しました
      </p>
      <p class="mb-10 text-3xl">
        INTERNAL SERVER ERROR
      </p>
    </div>
    <div class="p-4 text-sm text-center">
      <p>
        申し訳ありません。お探しのページは一時的にアクセスできない状況です。しばらく経ってからアクセスしてください。
      </p>
      <slot />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "InternalServerError",
});
</script>
