import { publicApiClient } from "../client";
import { RouteParams } from "vue-router";

const path = "/oauth_session";

const postOAuthSession = (
  campaignUrlCode: RouteParams["number"],
  payload: {
    serial_option_attributes: { code: string } | null;
    external_uid_option_attributes: {
      external_uid: string;
      digest: string;
    } | null;
  }
) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];
  return publicApiClient.post<OAuthSession>(`${path}`, {
    url_code: campaignUrlCodeStr,
    ...payload,
  });
};

export type OAuthSession = {
  authorization_url: string;
};

export const oAuthSessions = {
  post: postOAuthSession,
};
