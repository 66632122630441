import { Getters } from "vuex";
import {
  ReceiptChargeRequestsGetters,
  ReceiptChargeRequestsState,
} from "./types";

export const getters: Getters<
  ReceiptChargeRequestsState,
  ReceiptChargeRequestsGetters
> = {
  receiptChargingChargeRequests(state) {
    return state.items
      ? state.items.filter(
          (chargeRequest) =>
            chargeRequest.receipt_charging &&
            chargeRequest.receipt_charging.precheck_success
        )
      : [];
  },
};
