import { internalApiClient } from "../../client";
import { RouteParams } from "vue-router";
import { ExchangeRequest } from "../exchangeRequests";

const path = "/campaigns";

const postImmediatelyExchanging = (
  campaignUrlCode: RouteParams["number"],
  chargeRequestRequestIdentity: string
) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  return internalApiClient.post<ExchangeRequest>(
    `${path}/${campaignUrlCodeStr}/charge_requests/${chargeRequestRequestIdentity}/immediately_exchanging`
  );
};

export const immediatelyExchanging = {
  post: postImmediatelyExchanging,
};
