<template>
  <div class="w-full sm:w-6/12 text-center mx-auto justify-center p-2">
    <BaseCard class="p-2">
      <div class="text-center my-2 text-gray-700">
        <div class="text-lg">{{ exchangeConfigTitle }}</div>
        <div class="text-sm">{{ exchangeConfigSubTitle }}</div>
      </div>
      <img
        v-if="defeatPageConfig?.defeat_image_path"
        :src="defeatPageConfig.defeat_image_path"
        class="w-9/12 m-auto my-3"
      />
      <router-link :to="mypageLinkTo">
        <div class="w-9/12 m-auto">
          <BaseButton class="w-full" buttonClass="main"
            ><span class="mx-auto">マイページに戻る</span></BaseButton
          >
        </div>
      </router-link>
    </BaseCard>
    <ContinuouslyExchangeCards class="mt-2" />
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";
import { useStore } from "@consumer/store";
import ContinuouslyExchangeCards from "@consumer/components/campaign/exchange/ContinuouslyExchangeCards.vue";

export default defineComponent({
  components: {
    ContinuouslyExchangeCards,
  },
  setup() {
    const route = useRoute();
    const mypageLinkTo = computed(() => ({
      name: routeNames.CAMPAING_MY_PAGE,
      params: { campaign_url_code: route.params.campaign_url_code },
    }));

    const store = useStore();
    const exchangeConfigUrlCode = computed(
      () => store.state.exchange.exchangeConfigUrlCode
    );
    const exchangeConfigTitle = computed(() => {
      const defaultValue = "残念...";
      return exchangeConfigUrlCode.value
        ? store.getters["campaign/getCustomizingWord"](
            `exchange_config.${exchangeConfigUrlCode.value}.defeat_page.title`,
            defaultValue
          )
        : defaultValue;
    });
    const exchangeConfigSubTitle = computed(() => {
      const defaultValue = "またチャレンジしてね";
      return exchangeConfigUrlCode.value
        ? store.getters["campaign/getCustomizingWord"](
            `exchange_config.${exchangeConfigUrlCode.value}.defeat_page.sub_title`,
            defaultValue
          )
        : defaultValue;
    });
    const defeatPageConfig = computed(
      () => store.state.campaign.lottery_page_config?.defeat_page_config
    );
    onMounted(() => {
      store.commit("exchange/updateForceDefeat", false);
    });

    return {
      mypageLinkTo,
      defeatPageConfig,
      exchangeConfigTitle,
      exchangeConfigSubTitle,
    };
  },
});
</script>
