<template>
  <div
    v-for="(detail, index) in details"
    :key="index"
    class="flex justify-center m-2"
  >
    <CampaignDetailCard :detail="detail" class="sm:w-6/12 text-center mb-2" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import CampaignDetailCard from "@consumer/components/campaign/shared/CampaignDetailCard.vue";
import { useStore } from "@consumer/store/index";
export default defineComponent({
  components: {
    CampaignDetailCard,
  },
  setup() {
    const store = useStore();
    const details = computed(() => store.getters["campaign/sanitized_details"]);

    return {
      details,
    };
  },
});
</script>
