import { Actions } from "vuex";
import {
  PointLogsState,
  PointLogsGetters,
  PointLogsMutations,
  PointLogsActions,
} from "./types";

export const actions: Actions<
  PointLogsState,
  PointLogsActions,
  PointLogsGetters,
  PointLogsMutations
> = {};
