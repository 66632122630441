
import { defineComponent, computed } from "vue";
import { useStore } from "@consumer/store/index";
import { useRouter } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";

export default defineComponent({
  name: "LineFriendshipStatus",
  setup() {
    const store = useStore();
    const router = useRouter();
    const errors = computed(() => store.state.promise.errors);

    const backToEntry = () => {
      const campaign_url_code = !!errors.value?.extension?.campaign_url_code
        ? errors.value.extension.campaign_url_code
        : "";
      const serial_code = !!errors.value?.extension?.serial_code
        ? errors.value.extension.serial_code
        : "";

      router.push({
        name: routeNames.CAMPAING_ENTRY,
        params: { campaign_url_code },
        query: {
          serial_code,
        },
      });
    };

    return {
      errors,
      backToEntry,
    };
  },
});
