import { Actions } from "vuex";
import {
  LoginUserState,
  LoginUserGetters,
  LoginUserMutations,
  LoginUserActions,
} from "./types";

export const actions: Actions<
  LoginUserState,
  LoginUserActions,
  LoginUserGetters,
  LoginUserMutations
> = {};
