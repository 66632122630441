import { PromiseActionContext, PromiseActions } from "../types";
import { internalApi } from "@consumer/services/api";
import * as Sentry from "@sentry/browser";

export const getInternalApiCampaign = async (
  ctx: PromiseActionContext,
  payload: PromiseActions["getInternalApiCampaign"]
) => {
  ctx.commit("getInternalApiCampaignStart", null);

  try {
    const result = await internalApi.campaigns.get(payload.campaign_url_code);
    ctx.commit("internalApiCampaign/updateCampaign", result.data, {
      root: true,
    });
    ctx.commit("getInternalApiCampaignSuccess", result);
  } catch (error) {
    Sentry.captureException(error);

    ctx.dispatch("handleErrorWithSnackbar", error);
    ctx.commit("getInternalApiCampaignFailure");
  }
};
