import { Actions } from "vuex";
import {
  SnackbarState,
  SnackbarGetters,
  SnackbarMutations,
  SnackbarActions,
} from "./types";

export const actions: Actions<
  SnackbarState,
  SnackbarActions,
  SnackbarGetters,
  SnackbarMutations
> = {};
