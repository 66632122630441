import { PromiseActions, PromiseActionContext } from "../types";
import { useRouter, useRoute } from "vue-router";
import { internalApi, publicApi } from "@consumer/services/api";
import { routeNames } from "@consumer/router/routeNames";

export const getCampaignPage = async (
  ctx: PromiseActionContext,
  payload: PromiseActions["getCampaignPage"]
) => {
  const router = useRouter();
  await ctx.dispatch("checkRefreshToken", payload);
  ctx.commit("getCampaignPageStart", null);
  try {
    const result = await publicApi.campaigns.get(payload.campaign_url_code);
    ctx.commit("campaign/updateCampaign", result.data, { root: true });
    ctx.commit("getCampaignPageSuccess", result);
  } catch (error) {
    ctx.commit("getCampaignPageFailure");
    if (error?.response?.status === 404) {
      router.push({ name: routeNames.NOT_FOUND });
    } else {
      router.push({ name: routeNames.INTERNAL_SERVER_ERROR });
    }
  }
  try {
    if (ctx.rootState.ui.isAuthenticated) {
      ctx.dispatch("subscribeUpdateWalletChannel", payload);
      ctx.dispatch("subscribeIspReceiptResultFinishChannel", payload);
      ctx.dispatch("postDirectUpload", payload);

      ctx.commit("getLoginUserStart", null);
      const [loginUserResult, internalApiCampaign] = await Promise.all([
        internalApi.loginUsers.get(payload.campaign_url_code),
        internalApi.campaigns.get(payload.campaign_url_code),
      ]);
      ctx.commit("loginUser/updateLoginUser", loginUserResult.data, {
        root: true,
      });
      ctx.commit(
        "internalApiCampaign/updateCampaign",
        internalApiCampaign.data,
        {
          root: true,
        }
      );
      ctx.commit("getLoginUserSuccess", loginUserResult);
      ctx.dispatch("checkAfterLoginExtraConditions", payload);
    }
  } catch (error) {
    ctx.commit("getLoginUserFailure");
    router.push({ name: routeNames.INTERNAL_SERVER_ERROR });
  }
};
