
import { defineComponent, computed, ref, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@consumer/store";
import { routeNames } from "@consumer/router/routeNames";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const { params } = useRoute();
    const lottery_page_config = computed(
      () => store.state.campaign.lottery_page_config
    );
    const forceDefeat = computed(() => store.state.exchange.forceDefeat);

    const specifiedTimePassed = ref(false);

    setTimeout(() => {
      specifiedTimePassed.value = true;
    }, 3000);

    const showResult = computed(
      () =>
        specifiedTimePassed.value &&
        ((!store.state.promise.loading && lastExchangeResult.value) ||
          forceDefeat.value)
    );
    const exchangeConfig = computed(
      () =>
        store.state.campaign.exchange_configs &&
        store.state.campaign.exchange_configs.find(
          (exchangeConfig) =>
            exchangeConfig.url_code ===
            store.state.exchange.exchangeConfigUrlCode
        )
    );

    const lastExchangeResult = computed(() => {
      const exchangeResultsItems = store.state.exchangeResults.items;
      return (
        exchangeResultsItems &&
        exchangeResultsItems[exchangeResultsItems.length - 1]
      );
    });

    watchEffect(() => {
      if (showResult.value) {
        if (
          !forceDefeat.value &&
          lastExchangeResult.value &&
          lastExchangeResult.value.gift_issuable
        ) {
          router.push({
            name: routeNames.EXCHANGE_GIFT,
            params: { campaign_url_code: params.campaign_url_code },
          });
        } else {
          router.push({
            name: routeNames.EXCHANGE_DEFEAT,
            params: { campaign_url_code: params.campaign_url_code },
          });
        }
      }
    });

    const lotteryImagePath = computed(() => {
      return (
        exchangeConfig.value?.lottery_image_option?.image_url ||
        lottery_page_config.value?.lottery_image_path
      );
    });

    return {
      lotteryImagePath,
    };
  },
});
