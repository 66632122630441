
import { computed, defineComponent, watchEffect } from "vue";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "RedirectModal",
  setup() {
    const store = useStore();
    const redirectModal = computed(() => store.state.ui.redirectModal);
    const redirectUrlLinkTo = () => {
      window.location.href = redirectModal.value.redirectUrl;
    };

    watchEffect(() => {
      if (redirectModal.value.isOpen) {
        // ref: https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
        // safari で bfcache が有効なため、
        window.addEventListener("pageshow", function (event) {
          if (event.persisted) {
            window.location.reload();
          }
        });

        setTimeout(() => {
          redirectUrlLinkTo();
        }, 2000);
      }
    });

    return { redirectModal, redirectUrlLinkTo };
  },
});
