
import { defineComponent, PropType, computed } from "vue";
import { getFormatDateTime } from "@shared/utils/dayjs";
import {
  ChargeRequest,
  CHARGE_REQUEST_CHARGE_STATUSES,
  CONDITION_MISMATCH_DETAILS,
} from "@consumer/services/api/internalApi/chargeRequests";

export default defineComponent({
  name: "ReceiptChargeRequest",
  props: {
    chargeRequest: {
      type: Object as PropType<ChargeRequest>,
      required: true,
    },
  },
  setup(props) {
    const formatedCreatedAt = computed(() =>
      getFormatDateTime(props.chargeRequest.created_at)
    );
    const receiptImageUrl = computed(() =>
      props.chargeRequest.receipt_charging
        ? props.chargeRequest.receipt_charging.receipt_image_url
        : ""
    );
    const statusLabel = computed(() => {
      switch (props.chargeRequest.charge_status) {
        case CHARGE_REQUEST_CHARGE_STATUSES.CHECKING_RECEIPT:
        case CHARGE_REQUEST_CHARGE_STATUSES.ANALYSIS_RECEIPT:
        case CHARGE_REQUEST_CHARGE_STATUSES.VISUALLY_CHECKING:
        case CHARGE_REQUEST_CHARGE_STATUSES.CHARGING_NOW:
          return "確認中";
        case CHARGE_REQUEST_CHARGE_STATUSES.CONDITION_MISMATCH:
          return "条件に一致しませんでした";
        case CHARGE_REQUEST_CHARGE_STATUSES.CHARGED:
          return "チャージ済";
        default:
          return "-";
      }
    });

    const conditionMismatchDetails = computed(() => {
      if (!props.chargeRequest.condition_mismatch_details) return null;
      return props.chargeRequest.condition_mismatch_details
        .slice(0, 2)
        .map((detail) => {
          switch (detail) {
            case CONDITION_MISMATCH_DETAILS.PURCHASE_PERIOD_IS_OUT_OF_DATE:
              return "購入日付が応募期間外です";
            case CONDITION_MISMATCH_DETAILS.STORE_IS_INVALID:
              return "対象店舗ではありません";
            case CONDITION_MISMATCH_DETAILS.RECEIPT_IS_DUPLICATED:
              return "既に応募されたレシートです";
            case CONDITION_MISMATCH_DETAILS.TARGET_ITEM_NOT_EXISTS:
              return "対象商品がありません";
            case CONDITION_MISMATCH_DETAILS.PURCHASED_ITEM_ARE_INSUFFICIENT:
              return "購入数が不足しています";
            case CONDITION_MISMATCH_DETAILS.PURCHASE_VALUE_IS_INVALID:
              return "入力された購入金額に誤りがあります";
            case CONDITION_MISMATCH_DETAILS.PURCHASE_VALUE_IS_LESS_THAN_THE_LOWER_LIMIT:
              return "購入金額が不足しています";
            case CONDITION_MISMATCH_DETAILS.PURCHASE_VALUE_OF_COMBINED_ITEM_IS_LESS_THAN_LOWER_LIMIT:
              return "対象商品の合わせ買い条件を満たしていません";
            case CONDITION_MISMATCH_DETAILS.RECEIPT_ANALYZE_ERROR:
              return "レシートを解析できませんでした";
            default:
              return "不明なエラーです";
          }
        });
    });
    return {
      statusLabel,
      formatedCreatedAt,
      receiptImageUrl,
      conditionMismatchDetails,
    };
  },
});
