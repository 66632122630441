<template>
  <div
    class="p-4 text-sm text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>
