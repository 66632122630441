import { Mutations } from "vuex";
import { ExchangeState, ExchangeMutations } from "./types";

export const mutations: Mutations<ExchangeState, ExchangeMutations> = {
  updateExchangeConfigUrlCode(state, payload) {
    Object.assign(state, {
      exchangeConfigUrlCode: payload,
    });
  },
  updateForceDefeat(state, payload) {
    Object.assign(state, { forceDefeat: payload });
  },
};
