import { Mutations } from "vuex";
import {
  ReceiptChargeRequestsState,
  ReceiptChargeRequestsMutations,
} from "./types";

export const mutations: Mutations<
  ReceiptChargeRequestsState,
  ReceiptChargeRequestsMutations
> = {
  updateReceiptChargeRequests(state, payload) {
    state.items = [...payload.charge_requests];
    state.pagenation = payload.pagenation;
  },
  addReceiptChargeRequests(state, payload) {
    state.items = state.items
      ? [...state.items, ...payload.charge_requests]
      : [...payload.charge_requests];
    state.pagenation = payload.pagenation;
  },
};
