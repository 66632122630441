import { PointLogsState } from "./types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: PointLogsState = {
  items: [],
  currentPagenation: null,
};

const PointLogsModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default PointLogsModule;
