
import { defineComponent, computed, PropType } from "vue";
import { useStore } from "@consumer/store/index";
export default defineComponent({
  props: {
    infoMessages: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const { infoMessages } = props;

    return {
      infoMessages,
    };
  },
});
