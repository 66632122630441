import ActionCable from "actioncable";
import { getTokenFromCookie } from "@shared/utils/cookie";
import { getApiUriWebsocket } from "./uri";

export const getInternalCable = () => {
  const token = getTokenFromCookie();
  if (!token) {
    throw "login token is not found.";
  }
  return ActionCable.createConsumer(
    `${getApiUriWebsocket()}/cable?token=${token}`
  );
};
