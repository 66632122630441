import { WalletState } from "./types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: WalletState = {
  point: 0,
  inquiry_code: "",
};

const walletModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default walletModule;
