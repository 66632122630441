
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";
import InternalServerError from "@shared/components/InternalServerError.vue";

export default defineComponent({
  components: {
    InternalServerError,
  },
  setup() {
    const router = useRouter();
    const { params } = useRoute();

    const handleGoMyPage = () => {
      router.push({
        name: routeNames.CAMPAING_MY_PAGE,
        params: { ...params },
      });
    };
    return { handleGoMyPage };
  },
});
