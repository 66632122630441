import { Actions } from "vuex";
import {
  FailedExchangeRequestsState,
  FailedExchangeRequestsGetters,
  FailedExchangeRequestsMutations,
  FailedExchangeRequestsActions,
} from "./types";

export const actions: Actions<
  FailedExchangeRequestsState,
  FailedExchangeRequestsActions,
  FailedExchangeRequestsGetters,
  FailedExchangeRequestsMutations
> = {};
