
import { defineComponent, computed } from "vue";
import { useStore } from "@consumer/store/index";
import CardWithTitle from "@consumer/components/campaign/shared/CardWithTitle.vue";

export default defineComponent({
  components: {
    CardWithTitle,
  },
  setup() {
    const store = useStore();
    const inquiryCode = computed(() => store.state.wallet?.inquiry_code ?? "");

    return {
      inquiryCode,
    };
  },
});
