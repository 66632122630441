import { internalApiClient } from "../client";
import { RouteParams } from "vue-router";
import { ExchangeRequests } from "./exchangeRequests";

const path = "/campaigns";

const getFailedExchangeRequests = (campaignUrlCode: RouteParams["number"]) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  return internalApiClient.get<ExchangeRequests>(
    `${path}/${campaignUrlCodeStr}/failed_exchange_requests`
  );
};

export const failedExchangeRequests = {
  get: getFailedExchangeRequests,
};
