
import { defineComponent, computed } from "vue";
import { useStore } from "@consumer/store";
import ChargeSerialCharge from "./charge/ChargeSerialCharge.vue";
import ReceiptChargeLink from "./charge/ReceiptChargeLink.vue";

export default defineComponent({
  name: "Charge",
  components: {
    ChargeSerialCharge,
    ReceiptChargeLink,
  },
  props: {
    consentDescriptionVisible: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { consentDescriptionVisible } = props;
    const store = useStore();
    const chargeConfig = computed(() => store.state.campaign.charge_configs[0]);
    const chargeable_period_option = computed(
      () => store.getters["campaign/formatted_chargeable_period_options"][0]
    );
    const isChargeablePeriod = computed(
      () => store.getters["campaign/is_chargeable_periods"][0]
    );
    const chargeConfigUrlCode = computed(() => chargeConfig.value.url_code);
    const chargeablePeriodText = computed(() =>
      store.getters["campaign/chargeablePeriodText"](chargeConfigUrlCode.value)
    );
    const errorMessage = computed(() =>
      store.getters["internalApiCampaign/chargeErrorMessage"](
        chargeConfigUrlCode.value
      )
    );
    const chargeable = computed(() =>
      store.getters["internalApiCampaign/chargeable"](chargeConfigUrlCode.value)
    );
    const chargeButtonDescription = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "charge.charge_button_description",
        "上記ボタンの押下により、以下のキャンペーン内容及び注意事項に同意したものとみなします。",
        true
      )
    );

    return {
      errorMessage,
      chargeable,
      consentDescriptionVisible,
      chargeConfig,
      chargeable_period_option,
      isChargeablePeriod,
      chargeablePeriodText,
      chargeButtonDescription,
    };
  },
});
