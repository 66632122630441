<template>
  <div class="flex flex-col items-center justify-center m-2">
    <BaseCard class="sm:w-6/12 text-center">
      <div class="py-4 text-gray-700">
        <p>{{ entryablePeriodText }}</p>
        <p class="text-xs">
          {{ entryable_period_option.entryable_begin_at }} ~
          {{ entryable_period_option.entryable_end_at }}
        </p>
        <div v-if="!is_loginable" class="mt-4">
          <p class="text-xs">
            {{ campaignIsClosedMessage }}
          </p>
        </div>
        <div v-else-if="!is_entryable" class="mt-4">
          <span class="error-message">{{
            outOfCampaignEntryablePeriodMessage
          }}</span>
          <p class="text-xs">
            {{ outOfCampaignEntryablePeriodalreadyEntryedMessage }}
          </p>
        </div>
      </div>
      <div v-if="is_loginable">
        <div
          v-for="authenticatorRegistration in authenticatorRegistrations"
          :key="authenticatorRegistration.url_code"
        >
          <LineButton
            @click="login"
            data-cy="lineLoginButton"
            v-if="authenticatorRegistration.authentication_type === 'line'"
          >
            {{ loginButtonLabel }}
          </LineButton>
          <BaseButton
            type="button"
            buttonClass="main"
            class="px-6 py-2 mb-2"
            @click="login"
            data-cy="otherLoginButton"
            v-if="
              authenticatorRegistration.authentication_type ===
              'external_uid_digest'
            "
          >
            {{ loginButtonLabel }}
          </BaseButton>
        </div>
        <p class="pb-4 text-gray-700 text-xs" v-html="loginDescription"></p>
      </div>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@consumer/store/index";
import { useRoute } from "vue-router";
import LineButton from "./LineButton.vue";

export default defineComponent({
  name: "Login",
  components: {
    LineButton,
  },
  setup() {
    return {
      ...useSerialCode(),
    };
  },
});

const useSerialCode = () => {
  const store = useStore();
  const { query, params } = useRoute();
  const serialCode = ref("");
  const externalUid = ref("");
  const digest = ref("");
  onMounted(() => {
    if (Array.isArray(query.serial_code)) {
      if (query.serial_code[0]) {
        serialCode.value = query.serial_code[0];
      }
    } else {
      serialCode.value = query.serial_code || "";
    }
    if (Array.isArray(query.external_uid)) {
      if (query.external_uid[0]) {
        externalUid.value = query.external_uid[0];
      }
    } else {
      externalUid.value = query.external_uid || "";
    }
    if (Array.isArray(query.digest)) {
      if (query.digest[0]) {
        digest.value = query.digest[0];
      }
    } else {
      digest.value = query.digest || "";
    }
  });

  const login = () => {
    const serial_option_attributes = serialCode.value
      ? { code: serialCode.value }
      : null;
    const external_uid_option_attributes =
      externalUid.value && digest.value
        ? { external_uid: externalUid.value, digest: digest.value }
        : null;
    store.dispatch("promise/postOAuthSession", {
      campaign_url_code: params.campaign_url_code,
      serial_option_attributes,
      external_uid_option_attributes,
    });
  };

  const authenticatorRegistrations = computed(
    () => store.state.campaign.authenticator_registrations
  );
  const entryable_period_option = computed(
    () => store.getters["campaign/formatted_entryable_period_option"]
  );
  const is_entryable = computed(() => store.getters["campaign/is_entryable"]);
  const is_loginable = computed(() => store.getters["campaign/is_loginable"]);

  // 複数ログイン方法がある場合には一旦対応しないで最初のタイプでデフォルト値を判定
  const loginButtonLabel = computed(() =>
    store.getters["campaign/getCustomizingWord"](
      "login.button_label",
      authenticatorRegistrations.value[0]?.authentication_type === "line"
        ? "LINEでログイン"
        : "エントリーする",
      true
    )
  );
  const loginDescription = computed(() =>
    store.getters["campaign/getCustomizingWord"](
      "login.description",
      authenticatorRegistrations.value[0]?.authentication_type === "line"
        ? "LINEログイン後にキャンペーンに参加できます"
        : "エントリー後にキャンペーンに参加できます",
      true
    )
  );
  const entryablePeriodText = computed(() =>
    store.getters["campaign/getCustomizingWord"](
      "login.entryable_period_text",
      "エントリー期間"
    )
  );
  const campaignIsClosedMessage = computed(() =>
    store.getters["campaign/getCustomizingWord"](
      "login.campaign_is_closed_message",
      "本キャンペーンは終了いたしました。たくさんのご参加ありがとうございます"
    )
  );
  const outOfCampaignEntryablePeriodMessage = computed(() =>
    store.getters["campaign/getCustomizingWord"](
      "login.out_of_campaign_entryable_period.message",
      "エントリー期間外です。"
    )
  );
  const outOfCampaignEntryablePeriodalreadyEntryedMessage = computed(() =>
    store.getters["campaign/getCustomizingWord"](
      "login.out_of_campaign_entryable_period.already_entryed_message",
      "すでにエントリー済みの方は、引き続きログインできます。"
    )
  );

  return {
    login,
    authenticatorRegistrations,
    serialCode,
    entryable_period_option,
    is_entryable,
    is_loginable,
    loginButtonLabel,
    loginDescription,
    entryablePeriodText,
    campaignIsClosedMessage,
    outOfCampaignEntryablePeriodMessage,
    outOfCampaignEntryablePeriodalreadyEntryedMessage,
  };
};
</script>
