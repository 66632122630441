import { Getters } from "vuex";
import { CampaignGetters, CampaignState } from "./types";

export const getters: Getters<CampaignState, CampaignGetters> = {
  chargeErrorMessage:
    (_state, getters, rootState, rootGetters) =>
    (chargeConfigUrlCode: string) =>
      (rootGetters["campaign/chargeErrorMessage"](chargeConfigUrlCode) ||
        getters.chargeConfig(chargeConfigUrlCode)?.error_message) ??
      null,
  chargeable: (_state, getters) => (chargeConfigUrlCode: string) =>
    !getters.chargeErrorMessage(chargeConfigUrlCode),
  chargeConfig: (state) => (chargeConfigUrlCode: string) =>
    (state &&
      state.charge_configs.find(
        (internalApiChargeConfig) =>
          internalApiChargeConfig.url_code === chargeConfigUrlCode
      )) ??
    null,
  exchangeErrorMessage:
    (_state, getters, _rootState, rootGetters) =>
    (exchangeConfigUrlCode: string) =>
      getters.exchangeConfig(exchangeConfigUrlCode)?.error_message ?? null,
  exchangeable:
    (state, getters, rootState) => (exchangeConfigUrlCode: string) => {
      const exchangeConfig = getters.exchangeConfig(exchangeConfigUrlCode);
      // ポイント不足によるエラーはキャンペーン申し込み直後に全ての選択肢がエラー文言が表示されるのは体験として厳しいのでグレーアウトのみにする
      return (
        !!exchangeConfig &&
        !getters.exchangeErrorMessage(exchangeConfigUrlCode) &&
        rootState.wallet.point >= exchangeConfig.consumption_point
      );
    },
  exchangeConfig: (state) => (exchangeConfigUrlCode: string) =>
    (state &&
      state.exchange_configs.find(
        (internalApiExchangeConfig) =>
          internalApiExchangeConfig.url_code === exchangeConfigUrlCode
      )) ??
    null,
};
