import { internalApiClient } from "../client";
import { RouteParams } from "vue-router";

const path = "/campaigns";

const postChargeRequestReceiptCharging = (
  campaignUrlCode: RouteParams["number"],
  chargeConfigUrlCode: string,
  blobId: string,
  declarationAmount?: number
) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  const formData = new FormData();
  formData.append("charge_config_url_code", chargeConfigUrlCode);
  formData.append("receipt_image", blobId);
  if (declarationAmount) {
    formData.append("declaration_amount", declarationAmount.toString());
  }

  return internalApiClient.post<ChargeRequestReceiptCharging>(
    `${path}/${campaignUrlCodeStr}/charge_request_receipt_chargings`,
    formData,
    { headers: { "content-type": "multipart/form-data" } }
  );
};

export type ChargeRequestReceiptCharging = {
  // このステータスで表示を出し分けるようになったらちゃんとリテラルに変更
  state: string;
  request_identity: string;
};

export const chargeRequestReceiptChargings = {
  post: postChargeRequestReceiptCharging,
};
