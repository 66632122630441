import Cookies from "js-cookie";
import { isWithSSL } from "./uri";

export const setTokenToCookie = (token: string): void => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);

  const options = {
    path: "/",
    expires: date,
    secure: isWithSSL,
  };

  Cookies.set("mileage_token", token, options);
};

export const getTokenFromCookie = () => {
  return Cookies.get("mileage_token");
};
