import { Actions } from "vuex";
import {
  DirectUploadsState,
  DirectUploadsGetters,
  DirectUploadsMutations,
  DirectUploadsActions,
} from "./types";

export const actions: Actions<
  DirectUploadsState,
  DirectUploadsActions,
  DirectUploadsGetters,
  DirectUploadsMutations
> = {};
