<template>
  <div>
    <img
      :src="landingPageConfig.image_path"
      alt="landingPageImage"
      class="my-0 sm:w-6/12 mx-auto"
    />
    <div class="flex flex-col items-center justify-center m-2">
      <BaseCard class="sm:w-6/12 text-center p-2">
        <p class="text-sm" v-html="description" />

        <router-link
          v-if="chargeable"
          :to="receiptChargingLinkTo"
          class="cursor-pointer"
        >
          <BaseButton class="w-4/5 mt-5" buttonClass="main"
            ><span class="text-center w-full">{{
              continuouslyChargingButtonLabel
            }}</span></BaseButton
          ></router-link
        >
        <router-link :to="mypageLinkTo" class="cursor-pointer">
          <BaseButton class="w-4/5 mt-5" buttonClass="main"
            ><span class="text-center w-full"
              >マイページに戻る</span
            ></BaseButton
          ></router-link
        >
      </BaseCard>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "ReceiptUploadSuccess",
  setup() {
    const { params } = useRoute();
    const store = useStore();
    store.dispatch("promise/getInternalApiCampaign", {
      campaign_url_code: params.campaign_url_code,
    });
    const landingPageConfig = computed(
      () => store.state.campaign.landing_page_config
    );
    const receiptChargingLinkTo = computed(() => ({
      name: routeNames.CHARGE_RECEIPT_UPLOAD,
      params: {
        campaign_url_code: params.campaign_url_code,
        charge_config_url_code: params.charge_config_url_code,
      },
    }));
    const mypageLinkTo = computed(() => ({
      name: routeNames.CAMPAING_MY_PAGE,
      params: { campaign_url_code: params.campaign_url_code },
    }));

    const chargeable = computed(() =>
      store.getters["internalApiCampaign/chargeable"](
        typeof params.charge_config_url_code === "string"
          ? params.charge_config_url_code
          : params.charge_config_url_code[0]
      )
    );

    const description = store.getters["campaign/getCustomizingWord"](
      "receipt_charging.upload_receipt.success.description",
      "ご登録ありがとうございました。<br />最新の読み取り状況は、マイページよりご確認いただけます。<br />なおレシートの内容を確認するため、ポイントの付与までに最大3日程度お時間をいただく場合がございますので、ご了承ください。",
      true
    );
    const continuouslyChargingButtonLabel = store.getters[
      "campaign/getCustomizingWord"
    ](
      "receipt_charging.upload_receipt.success.continuously_charging_button_label",
      "続けて応募する"
    );

    return {
      chargeable,
      landingPageConfig,
      mypageLinkTo,
      receiptChargingLinkTo,
      description,
      continuouslyChargingButtonLabel,
    };
  },
});
</script>
