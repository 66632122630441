import dayjs from "dayjs";
import dayjsIsBetween from "dayjs/plugin/isBetween";

dayjs.extend(dayjsIsBetween);

export const getFormatDateTime = (iso8601String: string): string => {
  return dayjs(iso8601String).format("YYYY年M月D日HH:mm");
};

export const isBetween = (
  begin: string,
  end: string,
  compared = dayjs()
): boolean => {
  return compared.isBefore(end) && compared.isAfter(begin);
};
