import { PromiseActions, PromiseActionContext } from "../types";
import { useRouter } from "vue-router";
import { internalApi } from "@consumer/services/api";
import { routeNames } from "@consumer/router/routeNames";
import { getInternalCable } from "@consumer/services/actioncable/cable";
import { UPDATE_WALLET_CHANNEL } from "@consumer/services/actioncable/channelName";

export const subscribeUpdateWalletChannel = (
  ctx: PromiseActionContext,
  payload: PromiseActions["subscribeUpdateWalletChannel"]
) => {
  try {
    getInternalCable().subscriptions.create(UPDATE_WALLET_CHANNEL, {
      async received(_data) {
        const wallet = await internalApi.wallets.get(payload.campaign_url_code);
        ctx.commit("wallet/updateWallet", wallet.data, { root: true });
      },
    });
  } catch (error) {
    const router = useRouter();
    router.push({ name: routeNames.INTERNAL_SERVER_ERROR });
  }
};
