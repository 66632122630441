<template>
  <div class="flex justify-center" v-if="tabCount > 0">
    <div
      class="bg-white w-full rounded overflow-hidden shadow-lg sm:w-6/12 text-center m-2"
    >
      <ul class="list-reset flex justify-center">
        <li
          v-if="
            hasReceiptChargeConfig &&
            myPageConfig?.receipt_uploading_history_visibility
          "
          class="w-full"
        >
          <a
            class="tab-link-base"
            :class="{
              'tab-link-active': isReceiptTabActive,
              'tab-link-inactive': !isReceiptTabActive,
            }"
            @click="changeActiveTab(tabNames.RECEIPT)"
          >
            <svg
              version="1.1"
              width="21"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 512 512"
              xml:space="preserve"
              class="tab-svg-base"
              :class="{
                'tab-svg-inactive': !isReceiptTabActive,
              }"
            >
              <g>
                <path
                  class="st0"
                  d="M426.489,383.022V8.311c0-2.849-1.44-5.462-3.856-7c-1.348-0.847-2.886-1.301-4.45-1.301
		c-1.214,0-2.39,0.258-3.495,0.774l-72.893,34.073L280.434,6.184l-61.309,28.673l-61.33-28.673L96.486,34.857L23.638,0.774
		C22.528,0.258,21.352,0,20.132,0c-1.574,0-3.112,0.455-4.47,1.311c-2.39,1.538-3.82,4.151-3.82,6.98l0.01,412.634
		c0.01,23.726,8.529,46.018,23.974,62.754c13.428,14.569,31.15,24.222,50.076,27.33v0.815l9.448,0.073l1.415,0.061
		c0.738,0,1.378-0.031,2.922-0.113H417.75c1.146,0.072,2.441,0.154,3.928,0.154c11.053,0,22.184-3.087,32.194-8.92
		c14.099-8.219,25.832-21.486,33.938-38.357c8.193-17.068,12.349-36.705,12.349-58.357v-23.345H426.489z M138.466,406.366
		c0.01,20.712-5.142,39.194-14.517,52.048c-3.928,5.4-8.585,9.726-13.474,12.524c-4.481,2.581-9.096,3.881-13.711,3.881
		c-0.893,0-1.797-0.03-2.757-0.165c-11.378-1.27-22.622-7.145-30.851-16.106c-9.251-10.108-14.156-23.19-14.176-37.841V53.617
		l47.504,22.218l61.309-28.661l61.33,28.661l61.309-28.661l61.361,28.661l47.566-22.218l-0.011,338.966H138.466V406.366z
		 M448.594,458.393c-3.897,5.421-8.522,9.768-13.37,12.587c-4.45,2.57-9.009,3.872-13.547,3.881c-0.738,0-1.481-0.041-2.973-0.154
		H146.158c3.149-4.296,5.963-8.993,8.394-14.011c5.792-11.956,9.432-25.585,10.826-40.536h296.835
		C459.994,439.178,453.639,451.414,448.594,458.393z"
                ></path>
                <rect
                  x="96.083"
                  y="142.731"
                  class="st0"
                  width="248.479"
                  height="23.23"
                ></rect>
                <rect
                  x="96.083"
                  y="230.131"
                  class="st0"
                  width="248.479"
                  height="23.241"
                ></rect>
                <rect
                  x="96.083"
                  y="317.542"
                  class="st0"
                  width="129.02"
                  height="23.23"
                ></rect>
              </g></svg
            >レシート</a
          >
        </li>
        <li
          class="-mb-px w-full"
          v-if="myPageConfig?.received_gift_history_option"
        >
          <a
            class="tab-link-base"
            :class="{
              'tab-link-active': isGiftTabActive,
              'tab-link-inactive': !isGiftTabActive,
            }"
            @click="changeActiveTab(tabNames.GIFT)"
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              class="tab-svg-base"
              :class="{
                'tab-svg-inactive': !isGiftTabActive,
              }"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.5 4.5H16.32C16.43 4.19 16.5 3.85 16.5 3.5C16.5 1.84 15.16 0.5 13.5 0.5C12.45 0.5 11.54 1.04 11 1.85L10.5 2.52L10 1.84C9.46 1.04 8.55 0.5 7.5 0.5C5.84 0.5 4.5 1.84 4.5 3.5C4.5 3.85 4.57 4.19 4.68 4.5H2.5C1.39 4.5 0.51 5.39 0.51 6.5L0.5 17.5C0.5 18.61 1.39 19.5 2.5 19.5H18.5C19.61 19.5 20.5 18.61 20.5 17.5V6.5C20.5 5.39 19.61 4.5 18.5 4.5ZM13.5 2.5C14.05 2.5 14.5 2.95 14.5 3.5C14.5 4.05 14.05 4.5 13.5 4.5C12.95 4.5 12.5 4.05 12.5 3.5C12.5 2.95 12.95 2.5 13.5 2.5ZM8.5 3.5C8.5 2.95 8.05 2.5 7.5 2.5C6.95 2.5 6.5 2.95 6.5 3.5C6.5 4.05 6.95 4.5 7.5 4.5C8.05 4.5 8.5 4.05 8.5 3.5ZM2.5 17.5V15.5H18.5V17.5H2.5ZM2.5 7.5V12.5H18.5V7.5C18.5 6.95 18.05 6.5 17.5 6.5H13.42L14.9 8.52C15.23 8.97 15.13 9.6 14.68 9.92C14.24 10.24 13.61 10.14 13.29 9.7L10.5 5.9L7.71 9.7C7.39 10.14 6.76 10.24 6.32 9.92C5.87 9.6 5.77 8.97 6.1 8.52L7.58 6.5H3.5C2.95 6.5 2.5 6.95 2.5 7.5Z"
              /></svg
            >{{ myPageConfig.received_gift_history_option.title }}</a
          >
        </li>
        <li class="w-full" v-if="myPageConfig?.exchanging_history_option">
          <a
            class="tab-link-base"
            :class="{
              'tab-link-active': isHistoryTabActive,
              'tab-link-inactive': !isHistoryTabActive,
            }"
            @click="changeActiveTab(tabNames.HISTORY)"
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              class="tab-svg-base"
              :class="{
                'tab-svg-inactive': !isHistoryTabActive,
              }"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.99 0.0322266C4.47 0.0322266 0 4.51223 0 10.0322C0 15.5522 4.47 20.0322 9.99 20.0322C15.52 20.0322 20 15.5522 20 10.0322C20 4.51223 15.52 0.0322266 9.99 0.0322266ZM10 18.0322C5.58 18.0322 2 14.4522 2 10.0322C2 5.61223 5.58 2.03223 10 2.03223C14.42 2.03223 18 5.61223 18 10.0322C18 14.4522 14.42 18.0322 10 18.0322ZM9.72 5.03223H9.78C10.18 5.03223 10.5 5.35223 10.5 5.75223V10.2922L14.37 12.5922C14.72 12.7922 14.83 13.2422 14.62 13.5822C14.42 13.9222 13.98 14.0222 13.64 13.8222L9.49 11.3322C9.18 11.1522 9 10.8222 9 10.4722V5.75223C9 5.35223 9.32 5.03223 9.72 5.03223Z"
              />
            </svg>
            {{ exchangingHistoryTitle }}</a
          >
        </li>
        <li class="w-full" v-if="myPageConfig?.point_logs_option">
          <a
            class="tab-link-base"
            :class="{
              'tab-link-active': isPointLogsTabActive,
              'tab-link-inactive': !isPointLogsTabActive,
            }"
            @click="changeActiveTab(tabNames.POINT_LOGS)"
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              class="tab-svg-base"
              :class="{
                'tab-svg-inactive': !isPointLogsTabActive,
              }"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.99 0.0322266C4.47 0.0322266 0 4.51223 0 10.0322C0 15.5522 4.47 20.0322 9.99 20.0322C15.52 20.0322 20 15.5522 20 10.0322C20 4.51223 15.52 0.0322266 9.99 0.0322266ZM10 18.0322C5.58 18.0322 2 14.4522 2 10.0322C2 5.61223 5.58 2.03223 10 2.03223C14.42 2.03223 18 5.61223 18 10.0322C18 14.4522 14.42 18.0322 10 18.0322ZM9.72 5.03223H9.78C10.18 5.03223 10.5 5.35223 10.5 5.75223V10.2922L14.37 12.5922C14.72 12.7922 14.83 13.2422 14.62 13.5822C14.42 13.9222 13.98 14.0222 13.64 13.8222L9.49 11.3322C9.18 11.1522 9 10.8222 9 10.4722V5.75223C9 5.35223 9.32 5.03223 9.72 5.03223Z"
              />
            </svg>
            獲得・消費履歴</a
          >
        </li>
      </ul>
      <BaseCard>
        <div
          v-if="hasReceiptChargeConfig && isReceiptTabActive"
          class="w-full px-2"
        >
          <ReceiptChargeRequest
            v-for="(chargeRequest, index) in showableChargeRequests"
            :key="index"
            :chargeRequest="chargeRequest"
          />
          <BaseButton
            class="py-2 px-10 my-3 mx-auto mr-2"
            buttonClass="outlined"
            @click="getNextChargeRequests"
            v-if="gettableNextChargeRequests"
          >
            もっと見る
          </BaseButton>
          <BaseButton
            class="py-2 px-10 my-3 mx-auto"
            buttonClass="outlined"
            @click="toggleReadChargeRequestsMore"
            v-if="isCharngeRequestsTogglable"
          >
            {{ openChargeRequestsToggle ? "たたむ" : "もっと見る" }}
          </BaseButton>
          <div
            v-if="showableChargeRequests.length === 0"
            class="text-gray-500 my-8"
          >
            <img
              :src="informationCircle"
              alt="no-gifts"
              class="w-6 mx-auto mb-2"
            />
            <p>アップロードされたレシートはありません</p>
          </div>
        </div>
        <div v-if="isGiftTabActive" class="w-full px-2">
          <Gift
            v-for="(showableGift, index) in showableGifts"
            :key="index"
            :exchangeResultWithGift="showableGift"
            :receivedDateLabel="
              myPageConfig?.received_gift_history_option
                ?.gift_received_date_title
            "
          />
          <BaseButton
            class="py-2 px-10 my-3 mx-auto"
            buttonClass="outlined"
            @click="toggleReadGiftMore"
            v-if="gifts.length >= 4"
          >
            {{ showFullGifts ? "たたむ" : "もっと見る" }}
          </BaseButton>
          <div v-if="showableGifts.length === 0" class="text-gray-500 my-8">
            <img
              :src="informationCircle"
              alt="no-gifts"
              class="w-6 mx-auto mb-2"
            />
            <p>
              {{
                myPageConfig?.received_gift_history_option?.title || "当選景品"
              }}はありません
            </p>
          </div>
        </div>
        <div v-if="isHistoryTabActive" class="w-full px-2 mx-auto">
          <ExchangeResult
            v-for="(exchangeResult, index) in showableExchangeResults"
            :key="index"
            :exchangeResult="exchangeResult"
            :lotteryRabelVisibility="lotteryRabelVisibility"
            :exchangingRequestDateTitle="exchangingRequestDateTitle"
          />
          <BaseButton
            class="py-2 px-10 mt-0 mb-1 mx-auto"
            buttonClass="outlined"
            @click="toggleReadExchangeResults"
            v-if="dataTimeParsedExchangeResults.length >= 6"
          >
            {{ showFullExchangeResults ? "たたむ" : "もっと見る" }}
          </BaseButton>
          <div
            v-if="showableExchangeResults.length === 0"
            class="text-gray-500 my-8"
          >
            <img
              :src="informationCircle"
              alt="no-gifts"
              class="w-6 mx-auto mb-2"
            />
            <p>応募履歴はありません</p>
          </div>
        </div>
        <div v-if="isPointLogsTabActive" class="w-full px-2 mx-auto">
          <PointLog
            v-for="(pointLog, index) in showableParsedPointLogs"
            :key="index"
            :pointLog="pointLog"
          />
          <BaseButton
            class="py-2 px-10 mt-0 mb-1 mx-auto"
            buttonClass="outlined"
            @click="togglePointLogsMore"
            v-if="showableParsedPointLogs.length >= 1"
          >
            {{ showFullPointLogs ? "たたむ" : "もっと見る" }}
          </BaseButton>
          <div
            v-if="showableParsedPointLogs.length === 0"
            class="text-gray-500 my-8"
          >
            <img
              :src="informationCircle"
              alt="no-gifts"
              class="w-6 mx-auto mb-2"
            />
            <p>獲得・消費履歴はありません</p>
          </div>
        </div>
      </BaseCard>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import Gift from "./exchangeHistory/Gift.vue";
import ExchangeResult from "./exchangeHistory/ExchangeResult.vue";
import ReceiptChargeRequest from "./exchangeHistory/ReceiptChargeRequest.vue";
import PointLog from "./exchangeHistory/PointLog.vue";
import { useStore } from "@consumer/store";
import {
  getLocaleDateString,
  getLocaleDatetimeString,
} from "@shared/utils/getLocaleDateString";
import informationCircle from "@assets/informationCircle.svg";

export default defineComponent({
  name: "ExchangeHistory",
  components: {
    Gift,
    ExchangeResult,
    ReceiptChargeRequest,
    PointLog,
  },
  setup() {
    return {
      ...useTab(),
      ...useReceipts(),
      ...useGifts(),
      ...useHistories(),
      ...usePointLogs(),
      informationCircle,
    };
  },
});

const useTab = () => {
  const store = useStore();
  const myPageConfig = computed(() => store.state.campaign.my_page_config);
  const hasReceiptChargeConfig = computed(
    () => store.getters["campaign/has_receipt_charge_config"]
  );
  const tabNames = hasReceiptChargeConfig.value
    ? ({
        RECEIPT: "receipt",
        GIFT: "gift",
        HISTORY: "history",
        POINT_LOGS: "pointLogs",
      } as const)
    : ({
        GIFT: "gift",
        HISTORY: "history",
      } as const);
  const tabCount = computed(() =>
    myPageConfig.value
      ? Number(myPageConfig.value.exchanging_history_option ? 1 : 0) +
        Number(myPageConfig.value.receipt_uploading_history_visibility) +
        Number(myPageConfig.value.received_gift_history_option ? 1 : 0)
      : 0
  );

  type TabNames = typeof tabNames;

  const activeTab = ref<TabNames[keyof TabNames]>(
    myPageConfig.value?.receipt_uploading_history_visibility
      ? tabNames.RECEIPT
      : myPageConfig.value?.received_gift_history_option
      ? tabNames.GIFT
      : tabNames.HISTORY
  );
  const isReceiptTabActive = computed(
    () => activeTab.value === tabNames.RECEIPT
  );
  const isGiftTabActive = computed(() => activeTab.value === tabNames.GIFT);
  const isHistoryTabActive = computed(
    () => activeTab.value === tabNames.HISTORY
  );
  const isPointLogsTabActive = computed(
    () => activeTab.value === tabNames.POINT_LOGS
  );
  const changeActiveTab = (tabName: TabNames[keyof TabNames]): void => {
    activeTab.value = tabName;
  };
  const lotteryRabelVisibility = computed(
    () =>
      myPageConfig.value?.exchanging_history_option?.lottery_rabel_visibility ||
      false
  );
  const exchangingRequestDateTitle = computed(
    () =>
      myPageConfig.value?.exchanging_history_option
        ?.exchanging_request_date_title || "抽選日時"
  );
  const exchangingHistoryTitle = computed(
    () => myPageConfig.value?.exchanging_history_option?.title || ""
  );

  return {
    tabCount,
    myPageConfig,
    hasReceiptChargeConfig,
    tabNames,
    isReceiptTabActive,
    isGiftTabActive,
    isHistoryTabActive,
    isPointLogsTabActive,
    activeTab,
    changeActiveTab,
    lotteryRabelVisibility,
    exchangingRequestDateTitle,
    exchangingHistoryTitle,
  };
};

const useReceipts = () => {
  const store = useStore();
  const chargeRequests = computed(
    () => store.state.receiptChargeRequests.items
  );

  const openChargeRequestsToggle = ref(false);
  const showableChargeRequests = computed(() =>
    chargeRequests.value === null
      ? []
      : !openChargeRequestsToggle.value
      ? chargeRequests.value.slice(0, 3)
      : chargeRequests.value
  );
  const isCharngeRequestsTogglable = computed(
    () => !!chargeRequests.value && chargeRequests.value.length > 3
  );
  const gettableNextChargeRequests = computed(
    () =>
      openChargeRequestsToggle.value &&
      store.state.receiptChargeRequests.pagenation?.has_next_page
  );
  const getNextChargeRequests = () =>
    store.dispatch("promise/getNextReceiptChargeRequests", {
      campaign_url_code: store.state.campaign.url_code,
    });
  const toggleReadChargeRequestsMore = () => {
    openChargeRequestsToggle.value = !openChargeRequestsToggle.value;
  };

  return {
    openChargeRequestsToggle,
    showableChargeRequests,
    isCharngeRequestsTogglable,
    gettableNextChargeRequests,
    getNextChargeRequests,
    toggleReadChargeRequestsMore,
  };
};

const useGifts = () => {
  const { dateParsedExchangeResults } = useParsedExchangeResults();
  const showFullGifts = ref(false);

  const gifts = computed(() =>
    dateParsedExchangeResults.value.filter(
      (exchangeResult) => !!exchangeResult.gift
    )
  );

  const showableGifts = computed(() =>
    !showFullGifts.value ? gifts.value.slice(0, 3) : gifts.value
  );

  const toggleReadGiftMore = () => {
    showFullGifts.value = !showFullGifts.value;
  };

  return { gifts, showFullGifts, showableGifts, toggleReadGiftMore };
};

const usePointLogs = () => {
  const store = useStore();
  const pointLogs = computed(() => store.state.pointLogs.items);
  const showFullPointLogs = ref(false);

  const showableParsedPointLogs = computed(() => {
    if (!pointLogs.value) return [];

    return showFullPointLogs.value
      ? pointLogs.value.sort(
          (a, b) =>
            new Date(b.logged_at).getTime() - new Date(a.logged_at).getTime()
        )
      : pointLogs.value
          .sort(
            (a, b) =>
              new Date(b.logged_at).getTime() - new Date(a.logged_at).getTime()
          )
          .slice(0, 3);
  });

  const togglePointLogsMore = () => {
    showFullPointLogs.value = !showFullPointLogs.value;
  };
  return { showableParsedPointLogs, togglePointLogsMore, showFullPointLogs };
};

const useHistories = () => {
  const { dataTimeParsedExchangeResults } = useParsedExchangeResults();
  const showFullExchangeResults = ref(false);

  const showableExchangeResults = computed(() =>
    !showFullExchangeResults.value
      ? dataTimeParsedExchangeResults.value.slice(0, 5)
      : dataTimeParsedExchangeResults.value
  );

  const toggleReadExchangeResults = () => {
    showFullExchangeResults.value = !showFullExchangeResults.value;
  };

  return {
    dataTimeParsedExchangeResults,
    showFullExchangeResults,
    showableExchangeResults,
    toggleReadExchangeResults,
  };
};

const useParsedExchangeResults = () => {
  const store = useStore();
  const exchangeResults = computed(() => store.state.exchangeResults.items);

  const dateSortedExchangeResults = computed(() => {
    if (!exchangeResults.value) return [];
    return exchangeResults.value.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  });

  const dateParsedExchangeResults = computed(() => {
    return dateSortedExchangeResults.value.map((exchangeResult) => ({
      ...exchangeResult,
      created_at: getLocaleDateString(exchangeResult.created_at),
    }));
  });

  const dataTimeParsedExchangeResults = computed(() => {
    return dateSortedExchangeResults.value.map((exchangeResult) => ({
      ...exchangeResult,
      created_at: getLocaleDatetimeString(exchangeResult.created_at),
    }));
  });

  return { dateParsedExchangeResults, dataTimeParsedExchangeResults };
};
</script>
<style lang="scss" scoped>
.tab-link-base {
  @apply w-full p-2 text-gray-700 flex items-center justify-center cursor-pointer;
}
.tab-link-active {
  @apply bg-white;
}
.tab-link-inactive {
  @apply bg-gray-400;
}
.tab-link-inactive:hover {
  @apply bg-gray-300;
}

.tab-svg-base {
  @apply text-primary-main fill-current inline-block mr-2;
}
.tab-svg-inactive {
  @apply text-gray-700;
}
</style>
