<template>
  <div
    class="w-full h-32 md:h-40 flex rounded-lg border border-gray-300 mt-4 relative"
  >
    <div class="h-32 w-32 md:h-40 md:w-40 flex-none items-center flex p-2">
      <img
        :src="exchangeConfig.prize_option.image_path"
        alt="giftImage"
        class="object-contain flex justify-center"
      />
    </div>

    <div class="p-2 w-full text-left">
      <div
        class="h-10 md:h-16 text-sm md:text-base lg:text-lg flex items-center"
      >
        <span class="multiple-truncate">
          {{ exchangeConfig.prize_option.title }}
        </span>
      </div>
      <p class="font-bold my-1 md:text-lg">
        <span class="font-normal text-sm md:text-base ml-1">{{
          needPointLabel
        }}</span>
        {{ formattedConsumptionPoint }}
      </p>
      <BaseButton
        class="p-2 w-full md:text-lg"
        buttonClass="main"
        @click="handleExchangeClick"
        :disabled="!exchangeable"
      >
        <span class="mx-auto">{{ exchangeButtonLabel }}</span>
      </BaseButton>
    </div>

    <div
      v-if="!exchangeable"
      class="absolute w-full h-full bg-gray-400 opacity-75"
    />
    <div v-if="exchangeErrorMessage" class="absolute w-full h-full flex">
      <span
        class="bg-gray-200 text-gray-700 rounded-full m-auto text-sm py-2 px-4"
        >{{ exchangeErrorMessage }}</span
      >
    </div>
  </div>
  <div class="text-2xs text-gray-700 mb-4 mt-1">
    {{ exchangePeriodLabel }}:
    {{ exchangeConfig.exchangeable_period_option.exchangeable_begin_at }}~{{
      exchangeConfig.exchangeable_period_option.exchangeable_end_at
    }}
  </div>
  <BaseModal v-model:open="isModalOpen">
    <img
      :src="exchangeConfig.prize_option.image_path"
      alt="giftImage"
      class="object-contain w-40 mx-auto my-2"
    />
    <div class="text-sm my-3 sm:mx-16 text-left">
      <p>{{ confirmModalMessage }}</p>
    </div>
    <div class="text-center">
      <BaseButton
        class="border mr-3 p-2 md:text-lg"
        @click="handleExchangeCancelClick"
        >{{ confirmModalCancelButtonLabel }}</BaseButton
      >
      <BaseButton
        class="py-2 px-8 md:text-lg"
        buttonClass="main"
        @click="handleExchangeConfirmClick"
        :disabled="!exchangeable"
        >{{ confirmModalOkButtonLabel }}</BaseButton
      >
    </div>
  </BaseModal>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@consumer/store";
import { CampaignGetters } from "@consumer/store/modules/campaign/types";
import { formatPointNumber } from "@shared/utils/formatPointNumber";

export default defineComponent({
  name: "ExchangeCard",
  props: {
    exchangeConfig: {
      type: Object as PropType<
        CampaignGetters["formatted_visible_exchange_configs"][number]
      >,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const exchangeable = computed(() =>
      store.getters["internalApiCampaign/exchangeable"](
        props.exchangeConfig.url_code
      )
    );
    const exchangeErrorMessage = computed(() =>
      store.getters["internalApiCampaign/exchangeErrorMessage"](
        props.exchangeConfig.url_code
      )
    );
    const needPointLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        `exchange_config.${props.exchangeConfig.url_code}.exchange_card.need_point_label`,
        "必要ポイント"
      )
    );
    const exchangeButtonLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        `exchange_config.${props.exchangeConfig.url_code}.exchange_card.exchange_button_label`,
        "応募する"
      )
    );
    const exchangePeriodLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        `exchange_config.${props.exchangeConfig.url_code}.exchange_card.exchange_period_label`,
        "抽選期間"
      )
    );
    const confirmModalMessage = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        `exchange_config.${props.exchangeConfig.url_code}.exchange_card.confirm_modal.message`,
        `${props.exchangeConfig.prize_option.title}に応募しますか？`
      )
    );
    const confirmModalOkButtonLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        `exchange_config.${props.exchangeConfig.url_code}.exchange_card.confirm_modal.ok_button_label`,
        "OK"
      )
    );
    const confirmModalCancelButtonLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        `exchange_config.${props.exchangeConfig.url_code}.exchange_card.confirm_modal.cancel_button_label`,
        "キャンセル"
      )
    );
    const isModalOpen = ref(false);

    const handleExchangeClick = () => {
      isModalOpen.value = true;
    };

    const handleExchangeCancelClick = () => {
      isModalOpen.value = false;
    };

    const handleExchangeConfirmClick = () => {
      const exchangeConfigUrlCode = props.exchangeConfig.url_code;
      store.dispatch("promise/postExchangeRequest", {
        campaign_url_code: route.params.campaign_url_code,
        exchange_config_url_code: exchangeConfigUrlCode,
        router,
      });
    };

    const formattedConsumptionPoint = computed(() =>
      formatPointNumber(props.exchangeConfig.consumption_point)
    );

    return {
      handleExchangeClick,
      handleExchangeCancelClick,
      handleExchangeConfirmClick,
      formattedConsumptionPoint,
      needPointLabel,
      exchangeButtonLabel,
      exchangePeriodLabel,
      confirmModalMessage,
      confirmModalOkButtonLabel,
      confirmModalCancelButtonLabel,
      isModalOpen,
      exchangeable,
      exchangeErrorMessage,
    };
  },
});
</script>
