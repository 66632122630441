<template>
  <div
    v-if="isEnabledRoute && loading"
    class="fixed w-full h-full z-30 inset-0 flex justify-center items-center"
  >
    <div class="w-full h-full inset-0 bg-gray-700 opacity-25 absolute" />
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "@consumer/store";
import { routeNames } from "@consumer/router/routeNames";

export default defineComponent({
  name: "Spinner",
  setup() {
    const store = useStore();
    const route = useRoute();
    const loading = computed(() => store.state.promise.loading);
    const isEnabledRoute = computed(() => {
      return route.name !== routeNames.EXCHANGE_LOTTERY_IMAGE;
    });

    return { loading, isEnabledRoute };
  },
});
</script>
<style lang="scss" scoped>
.spinner > div {
  @apply bg-primary-main w-5 h-5 rounded-full inline-block m-1;
}
.spinner > div {
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
