import { PromiseActionContext, PromiseActions } from "../types";
import { internalApi } from "@consumer/services/api";
import * as Sentry from "@sentry/browser";

export const getMyPage = async (
  ctx: PromiseActionContext,
  payload: PromiseActions["getMyPage"]
) => {
  ctx.commit("getMyPageStart", null);

  try {
    const results = await Promise.all([
      internalApi.wallets.get(payload.campaign_url_code),
      internalApi.chargeRequests.get(payload.campaign_url_code, {
        charge_type: "receipt",
      }),
      internalApi.exchangeResults.get(payload.campaign_url_code),
      internalApi.failedExchangeRequests.get(payload.campaign_url_code),
      internalApi.campaigns.get(payload.campaign_url_code),
      internalApi.pointLogs.get(payload.campaign_url_code, { page: 1 }),
    ]);
    ctx.commit("wallet/updateWallet", results[0].data, { root: true });
    ctx.commit(
      "receiptChargeRequests/updateReceiptChargeRequests",
      results[1].data,
      { root: true }
    );
    ctx.commit(
      "exchangeResults/updateExchangeResults",
      results[2].data.exchange_results,
      { root: true }
    );
    ctx.commit(
      "failedExchangeRequests/updateFailedExchangeRequests",
      results[3].data,
      { root: true }
    );
    ctx.commit("internalApiCampaign/updateCampaign", results[4].data, {
      root: true,
    });
    ctx.commit("pointLogs/initPointLogs", results[5].data, {
      root: true,
    });
    ctx.commit("getMyPageSuccess", results);
  } catch (error) {
    Sentry.captureException(error);

    ctx.dispatch("handleErrorWithSnackbar", error);
    ctx.commit("getMyPageFailure");
  }
};
