<template>
  <button
    class="inline-flex items-center rounded-full p-2"
    v-bind="$attrs"
    :class="{ outlined: isOutlined, main: isMain, disabled: $attrs.disabled }"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  inheritAttrs: false,
  props: {
    buttonClass: {
      type: String,
    },
  },
  setup(props) {
    const isOutlined = ref(props.buttonClass === "outlined");
    const isMain = ref(props.buttonClass === "main");

    return {
      isOutlined,
      isMain,
    };
  },
});
</script>
<style lang="scss" scoped>
.main {
  @apply bg-primary-main text-white;
}

.outlined {
  @apply border border-primary-main text-primary-main;
}

.disabled {
  @apply bg-gray-400 cursor-not-allowed;
}
</style>
