import { PromiseState } from "./types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: PromiseState = {
  status: 200,
  errors: null,
  result: null,
  loading: false,
};

const promiseModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default promiseModule;
