<template>
  <div class="bg-gray-100 min-h-screen">
    <div class="pt-32 text-center text-primary-dark font-bold">
      <p class="mb-1">
        ご指定のページが見つかりません
      </p>
      <p class="mb-10 text-3xl">
        NOT FOUND
      </p>
    </div>
    <div class="p-4 text-sm text-center">
      <p>
        申し訳ありません。一時的にアクセスできない状態か、移動もしくは削除された可能性があります。
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotFound",
});
</script>
