import { PromiseActionContext } from "../types";
import { AxiosError, AxiosResponse } from "axios";
import { useRouter, useRoute } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";

type AxiosKnownError = AxiosError & {
  response: AxiosResponse;
};

const isAxiosKnownError = (error: any): error is AxiosKnownError => {
  return !!error.response;
};

export const handleError = (ctx: PromiseActionContext, error: any) => {
  if (isAxiosKnownError(error)) {
    ctx.commit("updateWithKnownErrors", error.response);
  } else {
    ctx.commit("updateWithUnknownErrors");
  }
};

export const handleErrorWithSnackbar = (
  ctx: PromiseActionContext,
  error: any
) => {
  if (isAxiosKnownError(error)) {
    // 認証エラーだった場合エントリー画面に指しもどすためupdateWithKnownErrorsを更新する
    if(error.response.status === 401) {
      ctx.commit("updateWithKnownErrors", error.response);
    }
    
    ctx.commit("snackbar/updateWithKnownErrors", error.response!, {
      root: true,
    });
  } else {
    ctx.commit("snackbar/updateWithUnknownErrors", null, { root: true });
  }
};
