import { mutations } from "./mutations";
import { actions } from "./actions";
import { UiState } from "./types";

const state: UiState = {
  isAuthenticated: false,
  redirectModal: {
    isOpen: false,
    message: "",
    redirectUrl: "",
  },
};

const uiModule = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default uiModule;
