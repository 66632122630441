import { PromiseActionContext, PromiseActions } from "../types";
import { routeNames } from "@consumer/router/routeNames";
import { internalApi } from "@consumer/services/api";
import * as Sentry from "@sentry/browser";

export const postExchangeRequest = async (
  ctx: PromiseActionContext,
  payload: PromiseActions["postExchangeRequest"]
) => {
  ctx.commit("postExchangeRequestStart");
  ctx.commit(
    "exchange/updateExchangeConfigUrlCode",
    payload.exchange_config_url_code,
    {
      root: true,
    }
  );
  ctx.commit("exchange/updateForceDefeat", false, { root: true });
  payload.router.push({
    name: routeNames.EXCHANGE_LOTTERY_IMAGE,
    params: { campaign_url_code: payload.campaign_url_code },
  });
  try {
    const { campaign_url_code, exchange_config_url_code, request_identity } =
      payload;
    const result = await internalApi.exchangeRequests.post({
      campaignUrlCode: campaign_url_code,
      exchangeConfigUrlCode: exchange_config_url_code,
      requestIdentity: request_identity,
    });
    ctx.commit(
      "exchangeResults/pushExchangeResult",
      result.data.exchange_result,
      {
        root: true,
      }
    );
    ctx.commit("postExchangeRequestSuccess", result);
  } catch (error) {
    Sentry.captureException(error);
    ctx.commit("postExchangeRequestFailure");

    payload.router.push({
      name: routeNames.EXCHANGE_ERROR,
      params: { campaign_url_code: payload.campaign_url_code },
    });
  }
};
