
import { defineComponent, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";
import { useStore } from "@consumer/store";
import ContinuouslyExchangeCards from "@consumer/components/campaign/exchange/ContinuouslyExchangeCards.vue";

export default defineComponent({
  components: {
    ContinuouslyExchangeCards,
  },
  setup() {
    const store = useStore();
    const { params } = useRoute();
    const router = useRouter();
    const lastExchangeResult = computed(() => {
      const exchangeResultsItems = store.state.exchangeResults.items!;
      return exchangeResultsItems[exchangeResultsItems.length - 1];
    });
    const exchangeConfig = computed(() => {
      const { exchange_config_url_code } = lastExchangeResult.value;
      return store.state.campaign.exchange_configs.find(
        (exchangeConfig) => exchangeConfig.url_code === exchange_config_url_code
      );
    });
    const exchangeButtonLabel = computed(() =>
      exchangeConfig.value?.winning_page_config
        ? exchangeConfig.value?.winning_page_config.exchange_button_label
        : "ギフトをもらう"
    );
    const title = computed(() =>
      exchangeConfig.value?.winning_page_config
        ? exchangeConfig.value.winning_page_config.title
        : "あたり"
    );
    const subTitle = computed(() =>
      exchangeConfig.value?.winning_page_config
        ? exchangeConfig.value.winning_page_config.sub_title
        : null
    );

    const handleGoGiftClick = () => {
      location.href = lastExchangeResult.value.gift?.url || "";
    };

    const mypageLinkTo = () => {
      router.push({
        name: routeNames.CAMPAING_MY_PAGE,
        params: { campaign_url_code: params.campaign_url_code },
      });
    };

    return {
      handleGoGiftClick,
      mypageLinkTo,
      lastExchangeResult,
      exchangeButtonLabel,
      title,
      subTitle,
    };
  },
});
