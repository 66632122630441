
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "@consumer/store";
import Status from "@consumer/components/campaign/myPage/Status.vue";
import ExchangeHistory from "@consumer/components/campaign/myPage/ExchangeHistory.vue";
import InfoMessages from "@consumer/components/campaign/shared/InfoMessages.vue";
import CampaignDetailCards from "@consumer/components/campaign/shared/CampaignDetailCards.vue";
import Charge from "@consumer/components/campaign/entry/Charge.vue";
import UserInquiryCard from "@consumer/components/campaign/shared/UserInquiryCard.vue";

export default defineComponent({
  name: "MyPage",
  components: {
    Charge,
    Status,
    InfoMessages,
    ExchangeHistory,
    CampaignDetailCards,
    UserInquiryCard,
  },
  setup() {
    const { params } = useRoute();
    const store = useStore();
    const myPageConfig = computed(() => store.state.campaign.my_page_config);

    const wallet = computed(() => store.state.wallet);
    const failedExchangeRequests = computed(
      () => store.state.failedExchangeRequests
    );

    const infoMessages = computed(
      () => store.getters["campaign/my_page_info_messages"]
    );

    store.dispatch("promise/getMyPage", {
      campaign_url_code: params.campaign_url_code,
    });

    return { wallet, myPageConfig, failedExchangeRequests, infoMessages };
  },
});
