import { Actions } from "vuex";
import { UiRootGetters, UiMutations, UiActions, UiState } from "./types";

export const actions: Actions<UiState, UiActions, UiRootGetters, UiMutations> =
  {
    putRefreshTokenSuccess(ctx, payload) {
      ctx.commit("updateIsAuthenticated", payload);
    },
    putRefreshTokenFailure(ctx, payload) {
      ctx.commit("updateIsAuthenticated", payload);
    },
  };
