
import { computed, defineComponent, watchEffect } from "vue";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "Snackbar",
  setup() {
    const store = useStore();
    const snackbar = computed(() => store.state.snackbar);

    watchEffect(() => {
      if (snackbar.value.isOpen) {
        setTimeout(() => store.commit("snackbar/initializeSnackbar"), 3000);
      }
    });

    return { snackbar };
  },
});
