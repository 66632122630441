import { PromiseActionContext, PromiseActions } from "../types";
import { publicApi } from "@consumer/services/api";

export const postOAuthSession = async (
  ctx: PromiseActionContext,
  payload: PromiseActions["postOAuthSession"]
) => {
  ctx.commit("postOAuthSessionStart", null);
  try {
    const result = await publicApi.oAuthSessions.post(
      payload.campaign_url_code,
      payload
    );
    ctx.commit("postOAuthSessionSuccess", result);
    location.href = result.data.authorization_url;
  } catch (error) {
    ctx.dispatch("handleErrorWithSnackbar", error);
    ctx.commit("postOAuthSessionFailure");
  }
};
