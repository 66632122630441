
import { defineComponent, PropType, Ref, ref } from "vue";
import ExchangeCard from "./ExchangeCard.vue";
import { CampaignGetters } from "@consumer/store/modules/campaign/types";

export default defineComponent({
  name: "ExchangeGroupCard",
  props: {
    exchangeConfigGroup: {
      type: Object as PropType<
        CampaignGetters["formatted_visible_exchange_config_groups"][number]
      >,
      required: true,
    },
  },
  components: {
    ExchangeCard,
  },
  setup(props) {
    let open: Ref<Boolean> = ref(false);

    const { title, thumbnail_image_url, exchange_configs } =
      props.exchangeConfigGroup;

    const toggle = () => {
      open.value = !open.value;
    };

    return { open, title, thumbnail_image_url, exchange_configs, toggle };
  },
});
