
import { computed, defineComponent } from "vue";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "AuthenticationError",
  setup() {
    const store = useStore();
    const errors = computed(() => store.state.promise.errors);
    return { errors };
  },
});
