import { Actions } from "vuex";
import {
  ReceiptChargingState,
  ReceiptChargingGetters,
  ReceiptChargingMutations,
  ReceiptChargingActions,
} from "./types";

export const actions: Actions<
  ReceiptChargingState,
  ReceiptChargingActions,
  ReceiptChargingGetters,
  ReceiptChargingMutations
> = {};
