import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { CampaignState } from "./types";

const state: CampaignState = {
  url_code: "",
  charge_configs: [],
  exchange_configs: [],
};

const campaignModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default campaignModule;
