<template>
  <div class="flex flex-col items-center">
    <img :src="receiptScanOk" class="w-10/12" />
    <div class="mb-4 w-10/12">
      <p v-html="description" />
    </div>
    <div class="flex flex-wrap text-center my-2">
      <div
        v-for="ngReceiptImage in ngReceiptImages"
        :key="ngReceiptImage.image"
        class="w-6/12 p-1"
      >
        <img :src="ngReceiptImage.image" />
        <small>{{ ngReceiptImage.reason }}</small>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "@consumer/store";
import receiptScanOk from "@assets/receiptScanOk.png";
import receiptScanNgOutOfFocus from "@assets/receiptScanNgOutOfFocus.png";
import receiptScanNgTilted from "@assets/receiptScanNgTilted.png";
import receiptScanNgCrumpled from "@assets/receiptScanNgCrumpled.png";
import receiptScanNgCut from "@assets/receiptScanNgCut.png";
import receiptScanNgMultiple from "@assets/receiptScanNgMultiple.png";
import receiptScanNgBackgroundSimilar from "@assets/receiptScanNgBackgroundSimilar.png";

export default defineComponent({
  name: "ReceiptUploadingNote",
  setup() {
    const store = useStore();
    const ngReceiptImages: { image: any; reason: string }[] = [
      { image: receiptScanNgOutOfFocus, reason: "ピンボケしている" },
      { image: receiptScanNgTilted, reason: "斜めになっている" },
      { image: receiptScanNgCrumpled, reason: "クシャクシャ" },
      { image: receiptScanNgCut, reason: "切り取られている" },
      { image: receiptScanNgMultiple, reason: "複数のレシートが写っている" },
      { image: receiptScanNgBackgroundSimilar, reason: "背景色と同化している" },
    ];

    const description = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "receipt_charging.upload_receipt.form.camera_shooting_method.note.description",
        "レシートは真上から、ピントを合わせて真っ直ぐに撮影してください。<br />また撮影は1枚ずつ、行ってください。",
        true
      )
    );
    return { receiptScanOk, ngReceiptImages, description };
  },
});
</script>
