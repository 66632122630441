import { Mutations } from "vuex";
import { SnackbarState, SnackbarMutations } from "./types";

export const mutations: Mutations<SnackbarState, SnackbarMutations> = {
  updateWithKnownErrors(state, payload) {
    state.isOpen = true;
    state.severity = "error";
    state.message = payload.data.title;
  },
  updateWithUnknownErrors(state) {
    state.isOpen = true;
    state.severity = "error";
    state.message = "システムエラーが発生しました";
  },
  updateWithSuccess(state, message) {
    state.isOpen = true;
    state.severity = "success";
    state.message = message;
  },
  updateWithError(state, message) {
    state.isOpen = true;
    state.severity = "error";
    state.message = message;
  },
  initializeSnackbar(state) {
    state.isOpen = false;
    state.severity = "success";
    state.message = "";
  },
};
