import { createApp } from "vue";
import App from "./App.vue";
import { router } from "@consumer/router/index";
import { store } from "./store";
import BaseButton from "./components/base/BaseButton.vue";
import BaseCard from "./components/base/BaseCard.vue";
import BaseModal from "./components/base/BaseModal.vue";
import BaseInfo from "./components/base/BaseInfo.vue";
import { initSentry } from "@shared/utils/sentry";

const app = createApp(App);

initSentry(app, router);

app.use(router);
app.use(store);

app.component("BaseButton", BaseButton);
app.component("BaseCard", BaseCard);
app.component("BaseModal", BaseModal);
app.component("BaseInfo", BaseInfo);

export const mountVue = () => {
  app.mount("#app");
};
