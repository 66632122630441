import { RouteParams } from "vue-router";
import {
  internalApiClient,
  PagenationRequest,
  PagenationResponse,
} from "../client";
import { immediatelyExchanging } from "./chargeRequests/immediatelyExchanging";

export type ChargeRequestsResonse = {
  charge_requests: ChargeRequests;
  pagenation: PagenationResponse;
};

export type ChargeRequests = ChargeRequest[];

export type ReceiptCharging = {
  receipt_image_url: string;
  precheck_success: boolean;
};

export const CHARGE_REQUEST_CHARGE_STATUSES = {
  CHECKING_RECEIPT: "checking_receipt",
  ANALYSIS_RECEIPT: "analysis_receipt",
  VISUALLY_CHECKING: "visually_checking",
  CHARGING_NOW: "charging_now",
  CONDITION_MISMATCH: "condition_mismatch",
  CHARGED: "charged",
} as const;

type ChargeRequestChargeStatus =
  typeof CHARGE_REQUEST_CHARGE_STATUSES[keyof typeof CHARGE_REQUEST_CHARGE_STATUSES];

export const CONDITION_MISMATCH_DETAILS = {
  PURCHASE_PERIOD_IS_OUT_OF_DATE: "purchase_period_is_out_of_period",
  STORE_IS_INVALID: "store_is_invalid",
  RECEIPT_IS_DUPLICATED: "receipt_is_duplicated",
  PURCHASED_ITEM_ARE_INSUFFICIENT: "purchased_items_are_insufficient",
  TARGET_ITEM_NOT_EXISTS: "target_item_not_exists",
  PURCHASE_VALUE_IS_INVALID: "purchase_value_is_invalid",
  PURCHASE_VALUE_IS_LESS_THAN_THE_LOWER_LIMIT:
    "purchase_value_is_less_than_the_lower_limit",
  PURCHASE_VALUE_OF_COMBINED_ITEM_IS_LESS_THAN_LOWER_LIMIT:
    "purchase_value_of_combined_items_is_less_than_the_lower_limit",
  RECEIPT_ANALYZE_ERROR: "receipt_analyze_error",
} as const;

type ConditionMismatchDetail =
  typeof CONDITION_MISMATCH_DETAILS[keyof typeof CONDITION_MISMATCH_DETAILS];

export type ConditionMismatchDetails = ConditionMismatchDetail[];

export type ChargeRequest = {
  created_at: string;
  charge_status: ChargeRequestChargeStatus;
  receipt_charging: ReceiptCharging | null;
  condition_mismatch_details: ConditionMismatchDetails | null;
};

const path = "/campaigns";

const getChargeRequests = (
  campaignUrlCode: RouteParams["number"],
  searchParams: { charge_type?: "serial" | "receipt" } & PagenationRequest = {}
) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  return internalApiClient.get<ChargeRequestsResonse>(
    `${path}/${campaignUrlCodeStr}/charge_requests`,
    { params: searchParams }
  );
};

export const chargeRequests = {
  get: getChargeRequests,
  immediatelyExchanging,
};
