
import { defineComponent, PropType, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@consumer/store";
import { CampaignGetters } from "@consumer/store/modules/campaign/types";
import { formatPointNumber } from "@shared/utils/formatPointNumber";

export default defineComponent({
  name: "ExchangeCard",
  props: {
    exchangeConfig: {
      type: Object as PropType<
        CampaignGetters["formatted_visible_exchange_configs"][number]
      >,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const exchangeable = computed(() =>
      store.getters["internalApiCampaign/exchangeable"](
        props.exchangeConfig.url_code
      )
    );
    const exchangeErrorMessage = computed(() =>
      store.getters["internalApiCampaign/exchangeErrorMessage"](
        props.exchangeConfig.url_code
      )
    );
    const needPointLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        `exchange_config.${props.exchangeConfig.url_code}.exchange_card.need_point_label`,
        "必要ポイント"
      )
    );
    const exchangeButtonLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        `exchange_config.${props.exchangeConfig.url_code}.exchange_card.exchange_button_label`,
        "応募する"
      )
    );
    const exchangePeriodLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        `exchange_config.${props.exchangeConfig.url_code}.exchange_card.exchange_period_label`,
        "抽選期間"
      )
    );
    const confirmModalMessage = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        `exchange_config.${props.exchangeConfig.url_code}.exchange_card.confirm_modal.message`,
        `${props.exchangeConfig.prize_option.title}に応募しますか？`
      )
    );
    const confirmModalOkButtonLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        `exchange_config.${props.exchangeConfig.url_code}.exchange_card.confirm_modal.ok_button_label`,
        "OK"
      )
    );
    const confirmModalCancelButtonLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        `exchange_config.${props.exchangeConfig.url_code}.exchange_card.confirm_modal.cancel_button_label`,
        "キャンセル"
      )
    );
    const isModalOpen = ref(false);

    const handleExchangeClick = () => {
      isModalOpen.value = true;
    };

    const handleExchangeCancelClick = () => {
      isModalOpen.value = false;
    };

    const handleExchangeConfirmClick = () => {
      const exchangeConfigUrlCode = props.exchangeConfig.url_code;
      store.dispatch("promise/postExchangeRequest", {
        campaign_url_code: route.params.campaign_url_code,
        exchange_config_url_code: exchangeConfigUrlCode,
        router,
      });
    };

    const formattedConsumptionPoint = computed(() =>
      formatPointNumber(props.exchangeConfig.consumption_point)
    );

    return {
      handleExchangeClick,
      handleExchangeCancelClick,
      handleExchangeConfirmClick,
      formattedConsumptionPoint,
      needPointLabel,
      exchangeButtonLabel,
      exchangePeriodLabel,
      confirmModalMessage,
      confirmModalOkButtonLabel,
      confirmModalCancelButtonLabel,
      isModalOpen,
      exchangeable,
      exchangeErrorMessage,
    };
  },
});
