<template>
  <CardWithTitle :title="detail.title" class="sm:w-6/12 text-center mb-2">
    <div
      class="description-text w-full text-left gray-700 whitespace-pre-wrap px-4 break-words"
      v-if="detail.description"
      v-html="detail.description"
    />
    <img :src="detail.image_path" v-if="detail.image_path" class="m-auto" />
  </CardWithTitle>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CardWithTitle from "@consumer/components/campaign/shared/CardWithTitle.vue";
import { Detail } from "@consumer/services/api/publicApi/campaigns";
export default defineComponent({
  components: {
    CardWithTitle,
  },
  props: {
    detail: {
      type: Object as () => Detail,
      required: true,
    },
  },
});
</script>
<style lang="scss" scoped>
.description-text {
  ::v-deep a {
    @apply underline;
    @apply mx-1;
  }
}
</style>
