import { Actions } from "vuex";
import {
  ExchangeResultsState,
  ExchangeResultsGetters,
  ExchangeResultsMutations,
  ExchangeResultsActions,
} from "./types";

export const actions: Actions<
  ExchangeResultsState,
  ExchangeResultsActions,
  ExchangeResultsGetters,
  ExchangeResultsMutations
> = {};
