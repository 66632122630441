
import { computed, defineComponent, ref, watchEffect } from "vue";
import Header from "@consumer/components/Header.vue";
import Spinner from "@consumer/components/Spinner.vue";
import Snackbar from "@consumer/components/Snackbar.vue";
import RedirectModal from "@consumer/components/RedirectModal.vue";
import { useStore } from "@consumer/store/index";
import { useRoute, useRouter } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";

export default defineComponent({
  name: "Campaign",
  components: {
    Header,
    Spinner,
    Snackbar,
    RedirectModal,
  },
  setup() {
    const styles = ref({}); // { "--primary-main": "#41BA83" }
    const store = useStore();
    const router = useRouter();
    const campaignName = computed(() => store.state.campaign.name);
    const { params } = useRoute();

    store.dispatch("promise/getCampaignPage", {
      campaign_url_code: params.campaign_url_code,
    });

    watchEffect(() => {
      console.log("(store.state.promise.status", (store.state.promise.status))
      if (store.state.promise.status === 401) {
        router.replace({
          name: routeNames.CAMPAING_ENTRY,
          params: { campaign_url_code: params.campaign_url_code },
        }).then(() => { router.go(0) }); // reload する ref: https://github.com/vuejs/router/issues/1257#issuecomment-1210696145
      }
    });

    return {
      styles,
      campaignName,
    };
  },
});
