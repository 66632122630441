import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { ReceiptChargingState } from "./types";

const state: ReceiptChargingState = {
  requestReceiptResult: "idle",
  requestId: undefined,
  requestTimeout: false,
  requestIdentity: undefined,
};

const ReceiptChargingModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default ReceiptChargingModule;
