import { PromiseActions, PromiseActionContext } from "../types";
import { useRouter } from "vue-router";
import { internalApi } from "@consumer/services/api";
import { routeNames } from "@consumer/router/routeNames";
import { getInternalCable } from "@consumer/services/actioncable/cable";
import { ISP_RECEIPT_RESULT_FINISH_CHANNEL } from "@consumer/services/actioncable/channelName";

export const subscribeIspReceiptResultFinishChannel = (
  ctx: PromiseActionContext,
  payload: PromiseActions["subscribeIspReceiptResultFinishChannel"]
) => {
  if (!ctx.rootGetters["campaign/has_receipt_charge_config"]) return;

  try {
    getInternalCable().subscriptions.create(ISP_RECEIPT_RESULT_FINISH_CHANNEL, {
      async received(data: { successed: boolean; display_dialog: boolean }) {
        try {
          const chargeRequestResponse = await internalApi.chargeRequests.get(
            payload.campaign_url_code,
            {
              charge_type: "receipt",
            }
          );
          ctx.commit(
            "receiptChargeRequests/updateReceiptChargeRequests",
            chargeRequestResponse.data,
            { root: true }
          );
          if (data.display_dialog) {
            const snackbarMessage = ctx.rootGetters[
              "campaign/getCustomizingWord"
            ](
              "snackbar.charge.receipt_charging.receipt_analysing_finished",
              "レシート読み取りが完了しました。ホーム画面にて結果をご確認ください。"
            );
            ctx.commit("snackbar/updateWithSuccess", snackbarMessage, {
              root: true,
            });
          }
          // レシートの再取得に失敗しても致命的ではないので何もしない
        } catch (_e) {}
      },
    });
  } catch (error) {
    const router = useRouter();
    router.push({ name: routeNames.INTERNAL_SERVER_ERROR });
  }
};
