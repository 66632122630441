import { RouteParams } from "vue-router";
import {
  internalApiClient,
  PagenationRequest,
  PagenationResponse,
} from "../client";

export type PointLogsResonse = {
  point_logs: PointLogs;
  pagenation: PagenationResponse;
};

export type PointLogs = PointLog[];

export type PointLog = {
  before_point: number;
  after_point: number;
  logged_at: string;
  charge_config_url_code: string | null;
  exchange_config_url_code: string | null;
};

const path = "/campaigns";

const getPointLogs = (
  campaignUrlCode: RouteParams["number"],
  searchParams: PagenationRequest
) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  return internalApiClient.get<PointLogsResonse>(
    `${path}/${campaignUrlCodeStr}/point_logs`,
    { params: searchParams }
  );
};

export const pointLogs = {
  get: getPointLogs,
};
