import { Mutations } from "vuex";
import { ReceiptChargingState, ReceiptChargingMutations } from "./types";

export const mutations: Mutations<
  ReceiptChargingState,
  ReceiptChargingMutations
> = {
  updateRequestReceiptResult(state, requestReceiptResult) {
    state.requestReceiptResult = requestReceiptResult;
  },
  requestTimeout(state) {
    state.requestTimeout = true;
  },
  updateRequestIdentity(state, requestIdentity) {
    state.requestIdentity = requestIdentity;
  },
  initializeStatus(state, requestId) {
    state.requestReceiptResult = "idle";
    state.requestId = requestId;
    state.requestTimeout = false;
    state.requestIdentity = undefined;
  },
};
