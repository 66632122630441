import { internalApiClient } from "../client";
import { RouteParams } from "vue-router";

const path = "/campaigns";

const getCampaign = (campaignUrlCode: RouteParams["number"]) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  return internalApiClient.get<Campaign>(`${path}/${campaignUrlCodeStr}`);
};

export type Campaign = {
  url_code: string;
  charge_configs: ChargeConfigs;
  exchange_configs: ExchangeConfigs;
};

type ChargeConfigs = ChargeConfig[];
export type ChargeConfig = {
  url_code: string;
  error_message: string;
};

type ExchangeConfigs = ExchangeConfig[];
export type ExchangeConfig = {
  url_code: string;
  error_message: string;
  consumption_point: number;
};

export const campaigns = {
  get: getCampaign,
};
