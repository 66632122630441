import { Getters } from "vuex";
import {
  FailedExchangeRequestsGetters,
  FailedExchangeRequestsState,
} from "./types";

export const getters: Getters<
  FailedExchangeRequestsState,
  FailedExchangeRequestsGetters
> = {};
