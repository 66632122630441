import { PromiseActionContext, PromiseActions } from "../types";
import { internalApi } from "@consumer/services/api";
import { routeNames } from "@consumer/router/routeNames";

const getChargeConfig = (
  ctx: PromiseActionContext,
  payload: PromiseActions["postChargeSerialCharging"]
) =>
  ctx.rootState.campaign.charge_configs.find(
    (localChargeConfig) =>
      localChargeConfig.url_code === payload.charge_config_url_code
  );
const isImmediatelyExchangingChargeRequestConfig = (
  ctx: PromiseActionContext,
  payload: PromiseActions["postChargeSerialCharging"]
) => !!getChargeConfig(ctx, payload)!.immediately_exchanging_option;

export const postChargeSerialCharging = async (
  ctx: PromiseActionContext,
  payload: PromiseActions["postChargeSerialCharging"]
) => {
  ctx.commit("postChargeSerialChargingStart");
  try {
    const result = await internalApi.chargeSerialChargings.post(
      payload.campaign_url_code,
      payload.charge_config_url_code,
      payload.serial_code
    );
    ctx.commit("postChargeSerialChargingSuccess", result);
    if (isImmediatelyExchangingChargeRequestConfig(ctx, payload)) {
      const { campaign_url_code, router } = payload;
      const exchange_config_url_code = getChargeConfig(ctx, payload)!
        .immediately_exchanging_option!.exchange_config_url_code;
      ctx.dispatch("postChargeRequestImmediatelyExchanging", {
        campaign_url_code,
        exchange_config_url_code: exchange_config_url_code,
        charge_request_request_identity: result.data.request_identity,
        router,
      });
    } else {
      const snackbarMessage = ctx.rootGetters["campaign/getCustomizingWord"](
        "snackbar.wallet.point_charge_success",
        "ポイントをチャージしました"
      );
      ctx.commit("snackbar/updateWithSuccess", snackbarMessage, {
        root: true,
      });
      payload.router.push({
        name: routeNames.CAMPAING_MY_PAGE,
        params: { campaign_url_code: payload.campaign_url_code },
      });
    }
  } catch (error) {
    ctx.dispatch("handleError", error);
    ctx.commit("postChargeSerialChargingFailure");
  }
};
