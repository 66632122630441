
import { defineComponent, computed } from "vue";
import ExchangeCard from "./ExchangeCard.vue";
import ExchangeGroupCard from "./ExchangeGroupCard.vue";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "ExchangeCards",
  components: {
    ExchangeCard,
    ExchangeGroupCard,
  },
  setup() {
    const store = useStore();

    const exchangeConfigs = computed(
      () => store.getters["campaign/formatted_visible_exchange_configs"]
    );
    const exchangeConfigGroups = computed(
      () => store.getters["campaign/formatted_visible_exchange_config_groups"]
    );

    return { exchangeConfigs, exchangeConfigGroups };
  },
});
