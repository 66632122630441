<template>
  <BaseModal
    class="p-3"
    v-model:open="redirectModal.isOpen"
    v-bind:closable="false"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      class="text-primary-main opacity-25 w-1/2 mx-auto"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>

    <div class="text-gray-700 text-center">
      {{ redirectModal.message }}
    </div>
    <div class="my-3 text-center">
      <BaseButton buttonClass="outlined" @click="redirectUrlLinkTo"
        >画面が遷移しない場合はこちら</BaseButton
      >
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { computed, defineComponent, watchEffect } from "vue";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "RedirectModal",
  setup() {
    const store = useStore();
    const redirectModal = computed(() => store.state.ui.redirectModal);
    const redirectUrlLinkTo = () => {
      window.location.href = redirectModal.value.redirectUrl;
    };

    watchEffect(() => {
      if (redirectModal.value.isOpen) {
        // ref: https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
        // safari で bfcache が有効なため、
        window.addEventListener("pageshow", function (event) {
          if (event.persisted) {
            window.location.reload();
          }
        });

        setTimeout(() => {
          redirectUrlLinkTo();
        }, 2000);
      }
    });

    return { redirectModal, redirectUrlLinkTo };
  },
});
</script>
