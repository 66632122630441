import { PromiseActions, PromiseActionContext } from "../types";
import { useRouter } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";
import { internalApi } from "@consumer/services/api";

export const checkAfterLoginExtraConditions = async (
  ctx: PromiseActionContext,
  payload: PromiseActions["checkAfterLoginExtraConditions"]
) => {
  const router = useRouter();
  try {
    const extra_conditions =
      ctx.rootGetters["campaign/after_login_extra_conditions"];
    extra_conditions.forEach(async (extra_condition) => {
      switch (extra_condition.type) {
        case "questionnaire":
          if (ctx.rootGetters["loginUser/isQuestionnaireRequestingNeeded"]) {
            const questionnaireRequestingResult =
              await internalApi.loginUsers.questionnaireRequesting.post(
                payload.campaign_url_code
              );
            ctx.commit(
              "loginUser/updateLoginUserQuestionnaireRequesting",
              questionnaireRequestingResult.data,
              { root: true }
            );
            ctx.commit(
              "ui/popupRedirectModal",
              {
                message: "自動でアンケート画面に遷移します",
                redirectUrl:
                  ctx.rootState.loginUser.questionnaire_requesting!.form_url,
              },
              { root: true }
            );
          }
          break;
      }
    });
  } catch (error) {
    ctx.commit("getLoginUserFailure");
    router.push({ name: routeNames.INTERNAL_SERVER_ERROR });
  }
};
