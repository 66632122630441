import { internalApiClient } from "../client";
import { RouteParams } from "vue-router";

const path = "/campaigns";

const postChargeSerialCharging = (
  campaignUrlCode: RouteParams["number"],
  chargeConfigUrlCode: string,
  code: string
) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  return internalApiClient.post<ChargeSerialCharging>(
    `${path}/${campaignUrlCodeStr}/charge_serial_chargings`,
    {
      charge_config_url_code: chargeConfigUrlCode,
      code: code,
    }
  );
};

export type ChargeSerialCharging = {
  code: string;
  point: number;
  request_identity: string;
};

export const chargeSerialChargings = {
  post: postChargeSerialCharging,
};
