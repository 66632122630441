<template>
  <header class="bg-white fixed w-full h-16 z-10">
    <div class="relative flex justify-center items-center w-full h-16">
      <router-link
        v-if="header_option"
        :to="entryPageLinkTo"
        class="cursor-pointer h-16 w-2/3"
      >
        <img
          :src="header_option.logo_url"
          alt="logo"
          class="object-contain h-16 m-auto"
        />
      </router-link>
      <router-link
        v-if="isAuthenticated"
        :to="mypageLinkTo"
        class="absolute cursor-pointer right-0 h-full flex flex-col mr-2 text-primary-main"
      >
        <svg
          viewBox="0 0 17 22"
          class="fill-current h-6 m-auto"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.2 5.0002C13.2 7.6474 11.0472 9.8002 8.4 9.8002C5.7528 9.8002 3.6 7.6474 3.6 5.0002C3.6 2.353 5.7528 0.200195 8.4 0.200195C11.0472 0.200195 13.2 2.353 13.2 5.0002ZM16.8 20.6002C16.8 21.2626 16.2636 21.8002 15.6 21.8002H1.2C0.5364 21.8002 0 21.2626 0 20.6002C0 15.9682 3.7692 12.2002 8.4 12.2002C13.0308 12.2002 16.8 15.9682 16.8 20.6002Z"
          />
        </svg>
        <span class="text-xs">マイページ</span>
      </router-link>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "Header",
  setup() {
    const store = useStore();
    const header_option = computed(() => store.state.campaign.header_option);
    const isAuthenticated = computed(() => store.state.ui.isAuthenticated);

    const { params } = useRoute();
    const mypageLinkTo = computed(() => ({
      name: routeNames.CAMPAING_MY_PAGE,
      params: { campaign_url_code: params.campaign_url_code },
    }));
    const entryPageLinkTo = computed(() => ({
      name: routeNames.CAMPAING_ENTRY,
      params: { campaign_url_code: params.campaign_url_code },
    }));

    return {
      header_option,
      mypageLinkTo,
      entryPageLinkTo,
      isAuthenticated,
    };
  },
});
</script>
