import { internalApiClient } from "../client";
import { RouteParams } from "vue-router";

const path = "/campaigns";

const getWallet = (campaignUrlCode: RouteParams["number"]) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  return internalApiClient.get<Wallet>(`${path}/${campaignUrlCodeStr}/wallet`);
};

export type Wallet = {
  point: number;
  inquiry_code: string;
};

export const wallets = {
  get: getWallet,
};
