
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "Header",
  setup() {
    const store = useStore();
    const header_option = computed(() => store.state.campaign.header_option);
    const isAuthenticated = computed(() => store.state.ui.isAuthenticated);

    const { params } = useRoute();
    const mypageLinkTo = computed(() => ({
      name: routeNames.CAMPAING_MY_PAGE,
      params: { campaign_url_code: params.campaign_url_code },
    }));
    const entryPageLinkTo = computed(() => ({
      name: routeNames.CAMPAING_ENTRY,
      params: { campaign_url_code: params.campaign_url_code },
    }));

    return {
      header_option,
      mypageLinkTo,
      entryPageLinkTo,
      isAuthenticated,
    };
  },
});
