import { RouteParams } from "vue-router";
import { internalApiClient } from "../client";

export type ExchangeResultsResonse = {
  exchange_results: ExchangeResults;
};

export type ExchangeResults = ExchangeResult[];

export type ExchangeResult = {
  exchange_config_url_code: string;
  gift_issuable: boolean;
  created_at: string;
  gift: Gift | null;
};

export type Gift = {
  name: string;
  url: string;
  image_url: string;
};

const path = "/campaigns";

const getExchangeResults = (campaignUrlCode: RouteParams["number"]) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  return internalApiClient.get<ExchangeResultsResonse>(
    `${path}/${campaignUrlCodeStr}/exchange_results`
  );
};

export const exchangeResults = {
  get: getExchangeResults,
};
