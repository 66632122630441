
import { defineComponent, PropType, computed } from "vue";
import { ExchangeResult } from "@consumer/services/api/internalApi/exchangeResults";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "ExchangeResult",
  props: {
    exchangeResult: {
      type: Object as PropType<ExchangeResult>,
      required: true,
    },
    lotteryRabelVisibility: {
      type: Boolean,
      required: true,
    },
    exchangingRequestDateTitle: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const resultLabel = computed(() =>
      props.exchangeResult.gift_issuable
        ? store.getters["campaign/getCustomizingWord"](
            "exchange_result.win_label",
            "アタリ"
          )
        : store.getters["campaign/getCustomizingWord"](
            "exchange_result.defeat_label",
            "ハズレ"
          )
    );

    return { resultLabel };
  },
});
