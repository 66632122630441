import { Actions } from "vuex";
import {
  ExchangeState,
  ExchangeGetters,
  ExchangeMutations,
  ExchangeActions,
} from "./types";

export const actions: Actions<
  ExchangeState,
  ExchangeActions,
  ExchangeGetters,
  ExchangeMutations
> = {};
