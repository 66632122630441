import { Mutations } from "vuex";
import { PromiseState, PromiseMutations } from "./types";

export const mutations: Mutations<PromiseState, PromiseMutations> = {
  updateWithKnownErrors(state, payload) {
    state.status = payload.status;
    state.errors = payload.data;
    state.result = null;
  },
  updateWithUnknownErrors(state) {
    state.status = 500;
    state.errors = {
      title: "システムエラーが発生しました",
      type: "system error",
    };
    state.result = null;
  },
  getMyPageStart(state) {
    state.loading = true;
    state.errors = null;
  },
  getMyPageSuccess(state, payload) {
    state.loading = false;
    state.errors = null;
    state.result = payload;
  },
  getMyPageFailure(state) {
    state.loading = false;
  },
  getCampaignPageStart(state) {
    state.loading = true;
    state.errors = null;
  },
  getCampaignPageSuccess(state, payload) {
    state.loading = false;
    state.errors = null;
    state.result = payload;
  },
  getCampaignPageFailure(state) {
    state.loading = false;
  },
  getLoginUserStart(state) {
    state.loading = true;
    state.errors = null;
  },
  getLoginUserSuccess(state, payload) {
    state.loading = false;
    state.errors = null;
    state.result = payload;
  },
  getLoginUserFailure(state) {
    state.loading = false;
  },
  getNextReceiptChargeRequestsStart(state) {
    state.loading = true;
    state.errors = null;
  },
  getNextReceiptChargeRequestsSuccess(state, payload) {
    state.loading = false;
    state.errors = null;
    state.result = payload;
  },
  getNextReceiptChargeRequestsFailure(state) {
    state.loading = false;
  },
  getInternalApiCampaignStart(state) {
    state.loading = true;
    state.errors = null;
  },
  getInternalApiCampaignSuccess(state, payload) {
    state.loading = false;
    state.errors = null;
    state.result = payload;
  },
  getInternalApiCampaignFailure(state) {
    state.loading = false;
  },
  postOAuthSessionStart(state) {
    state.loading = true;
    state.errors = null;
  },
  postOAuthSessionSuccess(state, payload) {
    state.loading = false;
    state.errors = null;
    state.result = payload;
  },
  postOAuthSessionFailure(state) {
    state.loading = false;
  },
  postAuthenticationStart(state) {
    state.loading = true;
    state.errors = null;
  },
  postAuthenticationSuccess(state, payload) {
    state.loading = false;
    state.errors = null;
    state.result = payload;
  },
  postAuthenticationFailure(state, payload) {
    state.errors = payload;
    state.loading = false;
  },
  postChargeSerialChargingStart(state) {
    state.loading = true;
    state.errors = null;
  },
  postChargeSerialChargingSuccess(state, payload) {
    state.loading = false;
    state.errors = null;
    state.result = payload;
  },
  postChargeSerialChargingFailure(state) {
    state.loading = false;
  },
  postChargeRequestReceiptChargingStart(state) {
    state.loading = true;
    state.errors = null;
  },
  postChargeRequestReceiptChargingSuccess(state, payload) {
    state.loading = false;
    state.errors = null;
    state.result = payload;
  },
  postChargeRequestReceiptChargingFailure(state) {
    state.loading = false;
  },
  postChargeRequestImmediatelyExchangingStart(state) {
    state.loading = true;
    state.errors = null;
  },
  postChargeRequestImmediatelyExchangingSuccess(state, payload) {
    state.loading = false;
    state.errors = null;
    state.result = payload;
  },
  postChargeRequestImmediatelyExchangingFailure(state) {
    state.loading = false;
  },
  postExchangeRequestStart(state) {
    state.loading = true;
    state.errors = null;
  },
  postExchangeRequestSuccess(state, payload) {
    state.loading = false;
    state.errors = null;
    state.result = payload;
  },
  postExchangeRequestFailure(state) {
    state.loading = false;
  },
};
