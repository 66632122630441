
import { defineComponent, computed } from "vue";
import CardWithTitle from "@consumer/components/campaign/shared/CardWithTitle.vue";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "EntryDescription",
  components: {
    CardWithTitle,
  },
  setup() {
    const store = useStore();
    const entrySteps = computed(
      () => store.getters["campaign/sanitized_entry_steps"]
    );
    const title = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "entry_description.title",
        "応募方法"
      )
    );

    return {
      entrySteps,
      title,
    };
  },
});
