import { internalApiClient } from "../client";
import { RouteParams } from "vue-router";

const path = "/campaigns";

const postDirectUploads = (campaignUrlCode: RouteParams["number"]) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  return internalApiClient.post<DirectUpload>(
    `${path}/${campaignUrlCodeStr}/direct_upload`
  );
};

export type DirectUpload = {
  url: string;
};

export const directUploads = {
  post: postDirectUploads,
};
