<template>
  <transition name="fade">
    <div
      v-if="snackbar.isOpen"
      class="fixed bottom-0 w-full p-2 text-left text-white z-30"
    >
      <div
        class="w-full sm:max-w-lg p-4 mx-auto rounded-md shadow-md"
        :class="{
          success: snackbar.severity === 'success',
          error: snackbar.severity === 'error',
        }"
      >
        {{ snackbar.message }}
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { computed, defineComponent, watchEffect } from "vue";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "Snackbar",
  setup() {
    const store = useStore();
    const snackbar = computed(() => store.state.snackbar);

    watchEffect(() => {
      if (snackbar.value.isOpen) {
        setTimeout(() => store.commit("snackbar/initializeSnackbar"), 3000);
      }
    });

    return { snackbar };
  },
});
</script>
<style lang="scss" scoped>
.success {
  @apply bg-green-500;
}

.error {
  @apply bg-red-600;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
