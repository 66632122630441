
import { defineComponent } from "vue";
import lineIcon from "@assets/line_icon.svg";
export default defineComponent({
  name: "LineButton",
  setup() {
    return {
      lineIcon,
    };
  },
});
