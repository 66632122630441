<template>
  <div v-if="inquiryCode" class="flex justify-center m-2">
    <CardWithTitle
      title="ユーザーID"
      class="sm:w-6/12 text-center text-gray-700"
    >
      {{ inquiryCode }}
    </CardWithTitle>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "@consumer/store/index";
import CardWithTitle from "@consumer/components/campaign/shared/CardWithTitle.vue";

export default defineComponent({
  components: {
    CardWithTitle,
  },
  setup() {
    const store = useStore();
    const inquiryCode = computed(() => store.state.wallet?.inquiry_code ?? "");

    return {
      inquiryCode,
    };
  },
});
</script>
