<template>
  <div class="flex justify-center m-2">
    <CardWithTitle :title="title" class="sm:w-6/12 flex flex-col items-center">
      <div
        class="sm:w-5/12 md:w-8/12"
        v-for="(step, index) in entrySteps"
        :key="step.title"
      >
        <div class="flex mb-5">
          <div class="w-full text-left mx-8">
            <p class="font-bold text-sm text-primary-main">
              STEP {{ index + 1 }}
            </p>
            <p class="font-bold text-sm">{{ step.title }}</p>
            <p
              class="w-full text-xs text-left text-gray-500 break-all"
              v-html="step.description"
            />
          </div>
        </div>
        <div class="flex justify-center items-center mb-5">
          <img :src="step.image_path" />
        </div>
      </div>
    </CardWithTitle>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
import CardWithTitle from "@consumer/components/campaign/shared/CardWithTitle.vue";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "EntryDescription",
  components: {
    CardWithTitle,
  },
  setup() {
    const store = useStore();
    const entrySteps = computed(
      () => store.getters["campaign/sanitized_entry_steps"]
    );
    const title = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "entry_description.title",
        "応募方法"
      )
    );

    return {
      entrySteps,
      title,
    };
  },
});
</script>
