import { publicApiClient } from "../client";
import { RouteParams } from "vue-router";

const path = "/campaigns";

const getCampaign = (campaignUrlCode: RouteParams["number"]) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  return publicApiClient.get<Campaign>(`${path}/${campaignUrlCodeStr}`);
};

export type Campaign = {
  name: string;
  url_code: string;
  loginable_end_at: string;
  header_option: HeaderOption;
  footer_option: FooterOption;
  details: Details;
  entry_steps: EntrySteps;
  landing_page_config: LandingPageConfig;
  my_page_config: MyPageConfig;
  lottery_page_config: LotteryPageConfig;
  charge_configs: ChargeConfigs;
  exchange_configs: ExchangeConfigs;
  exchange_config_groups: ExchangeConfigGroups;
  extra_conditions: ExtraConditions;
  entryable_period_option: EntryablePeriodOption;
  info_messages: InfoMessages;
  customizing_words: CustomizingWords;
  authenticator_registrations: AuthenticatorRegistrations;
};

type HeaderOption = {
  logo_url: string;
} | null;

type FooterOption = {
  description: string | null;
} | null;

type Details = Detail[];
export type Detail = {
  title: string;
  description: string | null;
  image_path: string | null;
};

type EntrySteps = EntryStep[];
export type EntryStep = {
  title: string;
  description: string | null;
  image_path: string | null;
};

type LandingPageConfig = {
  image_path: string;
};

type ReceivedGiftHistoryOption = {
  title: string;
  gift_received_date_title: string;
} | null;

type ExchangingHistoryOption = {
  title: string;
  exchanging_request_date_title: string;
  lottery_rabel_visibility: boolean;
} | null;

type PointLogsOption = {
  title: string;
} | null;

type MyPageConfig = {
  image_path: string;
  point_visibility: boolean;
  receipt_uploading_history_visibility: boolean;
  received_gift_history_option: ReceivedGiftHistoryOption;
  exchanging_history_option: ExchangingHistoryOption;
  point_logs_option: PointLogsOption;
} | null;

type LotteryPageConfig = {
  lottery_image_path: string;
  defeat_page_config: DefeatPageConfig | null;
} | null;

export type DefeatPageConfig = {
  defeat_image_path: string;
};

type ChargeConfigs = ChargeConfig[];
export type ChargeConfig = {
  url_code: string;
  chargeable_period_option: ChargeablePeriodOption;
  immediately_exchanging_option?: ChargeConfigImmediatelyExchanging;
  charge_type: "charge_serial" | "point_specify";
  receipt_option?: ReceiptOption;
  serial_option?: SerialOption;
};
type ChargeConfigImmediatelyExchanging = {
  exchange_config_url_code: string;
  prelottery: boolean;
};

type ChargeablePeriodOption = {
  chargeable_begin_at: string;
  chargeable_end_at: string;
};

type ReceiptOption = {
  declaration_amount_visible: boolean;
};

type SerialOption = {
  form_visibility: boolean;
};

type WinningPageConfig = {
  title: string;
  sub_title: string;
  exchange_button_label: string;
};

type LotteryImageOption = {
  image_url: string;
};

type ExchangeConfigGroups = ExchangeConfigGroup[];
type ExchangeConfigGroup = {
  exchange_configs: ExchangeConfigs;
  title: string;
  thumbnail_image_url: string;
};

type ExchangeConfigs = ExchangeConfig[];
export type ExchangeConfig = {
  url_code: string;
  consumption_point: number;
  visibility: boolean;
  prize_option: PrizeOption;
  exchangeable_period_option: ExchangeablePeriodOption;
  winning_page_config: WinningPageConfig;
  lottery_image_option: LotteryImageOption | null;
};

type ExchangeablePeriodOption = {
  exchangeable_begin_at: string;
  exchangeable_end_at: string;
};

type ExtraConditions = ExtraCondition[];
export type ExtraCondition = {
  timing: ExtraConditionTiming;
  type: ExtraConditionType;
};
type ExtraConditionTiming =
  typeof EXTRA_CONDITION_TIMING[keyof typeof EXTRA_CONDITION_TIMING];
export const EXTRA_CONDITION_TIMING = {
  AFTER_LOGIN: "after_login",
} as const;
type ExtraConditionType =
  typeof EXTRA_CONDITION_TYPE[keyof typeof EXTRA_CONDITION_TYPE];
export const EXTRA_CONDITION_TYPE = {
  QUESTIONNAIRE: "questionnaire",
} as const;

type PrizeOption = {
  title: string;
  image_path: string;
};

type EntryablePeriodOption = {
  entryable_begin_at: string;
  entryable_end_at: string;
};

type InfoMessages = InfoMessage[];
type InfoMessage = {
  message: string;
  campaign_page_visible: boolean;
  login_page_visible: boolean;
  my_page_visible: boolean;
};

type CustomizingWords = CustomizingWord[];
export type CustomizingWordKey =
  | "page.title"
  | "login.button_label"
  | "login.description"
  | "login.entryable_period_text"
  | "login.campaign_is_closed_message"
  | "login.out_of_campaign_entryable_period.message"
  | "login.out_of_campaign_entryable_period.already_entryed_message"
  | "entry_description.title"
  | "charge_serial.charge_button_label"
  | "charge_serial.chargeable_period_text"
  | "charge_serial.description"
  | "charge.charge_button_description"
  | "charge.immediately_exchanging.chargeable_period_text"
  | "receipt_charging.chargeable_period_text"
  | "receipt_charging.link_to_receipt_upload_form_link_button_label"
  | "receipt_charging.upload_receipt.form.camera_shooting_method.title"
  | "receipt_charging.upload_receipt.form.camera_shooting_method.note.description"
  | "receipt_charging.upload_receipt.form.active_camera_button_label"
  | "receipt_charging.upload_receipt.form.declaration_amount.unit"
  | "receipt_charging.upload_receipt.form.declaration_amount.description"
  | "receipt_charging.upload_receipt.form.photo_confirmation"
  | "receipt_charging.upload_receipt.form.upload_photo_button_label"
  | "receipt_charging.upload_receipt.success.description"
  | "receipt_charging.upload_receipt.success.continuously_charging_button_label"
  | "my_page.status.point_charge_button_label"
  | "my_page.status.point_label_title"
  | "my_page.status.next_exchange_need_point_prefix"
  | "my_page.status.next_exchange_need_point_suffix"
  | "my_page.status.all_exchange_enable"
  | "exchange.exchange_cards.title"
  | "exchange.exchange_cards.point_prefix"
  | "exchange_result.win_label"
  | "exchange_result.defeat_label"
  | "snackbar.wallet.point_charge_success"
  | "snackbar.charge.receipt_charging.receipt_analysing_finished"
  | "snackbar.charge.receipt_charging.request_receipt_failed"
  | "pointee.api.errors.messages.not_found.code"
  | "pointee.api.errors.reasons.charge_serial_charging_code.has_been_charged"
  | "pointee.api.errors.reasons.charge_serial_charging_code.has_been_charged_today"
  | "pointee.api.errors.reasons.charge_serial_charging_code.is_already_expired"
  | "pointee.api.errors.reasons.wallet_point.is_out_of_balance"
  | "pointee.api.errors.reasons.exchange_config_url_code.is_out_of_stock"
  | string; // exchange_configやcharge_configのurl_codeを含むものを個別定義できないため
type CustomizingWord = {
  key: CustomizingWordKey;
  text: string;
};

type AuthenticatorRegistrations = AuthenticatorRegistration[];
type AuthenticatorRegistrationAuthenticationType =
  | "line"
  | "external_uid_digest";
type AuthenticatorRegistration = {
  authentication_type: AuthenticatorRegistrationAuthenticationType;
  url_code: string;
};

export const campaigns = {
  get: getCampaign,
};
