import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { ActionCableState } from "./types";

const state: ActionCableState = {
  ispRequestReceiptFinishChannelSubscribed: false,
  chargeResultedChannelSubscribed: false,
  receiptChargingPrelotteryResultChannelSubscribed: false,
};

const campaignModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default campaignModule;
