
import { computed, defineComponent } from "vue";
import { useStore } from "@consumer/store";
import Charge from "@consumer/components/campaign/entry/Charge.vue";
import InfoMessages from "@consumer/components/campaign/shared/InfoMessages.vue";
import Login from "@consumer/components/campaign/entry/Login.vue";
import EntryDescription from "@consumer/components/campaign/entry/EntryDescription.vue";
import CampaignDetailCards from "@consumer/components/campaign/shared/CampaignDetailCards.vue";
import FooterDescription from "@consumer/components/campaign/shared/FooterDescription.vue";

export default defineComponent({
  name: "Entry",
  components: {
    Login,
    InfoMessages,
    Charge,
    EntryDescription,
    CampaignDetailCards,
    FooterDescription,
  },
  setup() {
    const store = useStore();
    const landingPageConfig = computed(
      () => store.state.campaign.landing_page_config
    );
    const isAuthenticated = computed(() => store.state.ui.isAuthenticated);
    const infoMessages = computed(() =>
      isAuthenticated.value
        ? store.getters["campaign/campaign_page_info_messages"]
        : store.getters["campaign/login_page_info_messages"]
    );

    return { infoMessages, landingPageConfig, isAuthenticated };
  },
});
