
import { defineComponent, computed, watchEffect } from "vue";
import { useStore } from "@consumer/store";
import { useRoute, useRouter } from "vue-router";
import ReceiptUploadForm from "@consumer/components/campaign/charge/ReceiptUploadForm.vue";
import { routeNames } from "@consumer/router/routeNames";

export default defineComponent({
  name: "ReceiptUpload",
  components: { ReceiptUploadForm },
  setup() {
    const { params } = useRoute();
    const router = useRouter();
    const store = useStore();
    const landingPageConfig = computed(
      () => store.state.campaign.landing_page_config
    );
    const requestReceiptResult = computed(
      () => store.state.receiptCharging.requestReceiptResult
    );

    // onMounted 内でやると watchEffect が反応し、1度アップロード成功後に開いた時に1回success画面に遷移してしまうため同期的に実行
    store.commit("receiptCharging/updateRequestReceiptResult", "idle");

    watchEffect(() => {
      switch (requestReceiptResult.value) {
        case "success":
          router.push({
            name: routeNames.CHARGE_RECEIPT_UPLOADED_SUCCESS,
            params: {
              campaign_url_code: params.campaign_url_code,
              charge_config_url_code: params.charge_config_url_code,
            },
          });
          break;
      }
    });

    return {
      landingPageConfig,
      chargeConfigUrlCode: params.charge_config_url_code,
    };
  },
});
