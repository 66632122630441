import { PromiseActionContext } from "../types";
import { Router } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";
import {
  QueryStrings,
  PostAuthenticationError,
} from "@consumer/services/api/publicApi/authentications";
import { publicApi } from "@consumer/services/api";
import { setTokenToCookie } from "@shared/utils/cookie";
import { parse } from "query-string";
import * as Sentry from "@sentry/browser";

export const postAuthentication = async (
  ctx: PromiseActionContext,
  router: Router
) => {
  ctx.commit("postAuthenticationStart", null);
  try {
    const parsedQueryString = parse(location.search) as QueryStrings;
    const result = await publicApi.authentications.post(parsedQueryString);
    ctx.commit("postAuthenticationSuccess", result);

    setTokenToCookie(result.data.token);
    router.push({
      name: routeNames.CAMPAING_ENTRY,
      params: { campaign_url_code: result.data.campaign_url_code },
      query: { ...result.data.serial_option },
    });
  } catch (error) {
    ctx.commit("postAuthenticationFailure", error?.response?.data);

    if (isLineFriendshipError(error)) {
      router.push({
        name: routeNames.LINE_FRIENDSHIP_STATUS_ERROR,
      });
    } else if (isBlockedLoginUserError(error)) {
      router.push({
        name: routeNames.BLOCKED_LOGIN_USER_ERROR,
      });
    } else {
      if (isUnknownError(error)) {
        Sentry.captureException(error);
      }
      router.push({ name: routeNames.AUTHENTICATION_ERROR });
    }
  }
};

const isPostAuthenticationError = (
  error: any
): error is PostAuthenticationError => {
  return error.isAxiosError;
};

const isLineFriendshipError = (error: any): boolean => {
  return (
    isPostAuthenticationError(error) &&
    !!error.response &&
    !!error.response.data.extension &&
    error.response.data.extension.error_type === "line_friendship_error"
  );
};

const isBlockedLoginUserError = (error: any): boolean => {
  return (
    isPostAuthenticationError(error) &&
    !!error.response &&
    !!error.response.data.extension &&
    error.response.data.extension.error_type === "login_user_blocked"
  );
};

const isUnknownError = (error: any): boolean => {
  return (
    isPostAuthenticationError(error) &&
    !!error.response &&
    error.response.status !== 400
  );
};
