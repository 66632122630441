import { campaigns as publicApicampaigns } from "./publicApi/campaigns";
import { oAuthSessions } from "./publicApi/oAuthSessions";
import { authentications } from "./publicApi/authentications";
import { campaigns as internalApicampaigns } from "./internalApi/campaigns";
import { directUploads } from "./internalApi/directUploads";
import { wallets } from "./internalApi/wallets";
import { loginUsers } from "./internalApi/loginUsers";
import { chargeSerialChargings } from "./internalApi/chargeSerialChargings";
import { chargeRequests } from "./internalApi/chargeRequests";
import { chargeRequestReceiptChargings } from "./internalApi/chargeRequestReceiptChargings";
import { exchangeRequests } from "./internalApi/exchangeRequests";
import { failedExchangeRequests } from "./internalApi/failedExchangeRequests";
import { exchangeResults } from "./internalApi/exchangeResults";
import { refreshTokens } from "./internalApi/refreshTokens";
import { pointLogs } from "./internalApi/pointLogs";

export const publicApi = {
  campaigns: publicApicampaigns,
  oAuthSessions,
  authentications,
};

export const internalApi = {
  campaigns: internalApicampaigns,
  directUploads,
  wallets,
  loginUsers,
  chargeSerialChargings,
  chargeRequests,
  chargeRequestReceiptChargings,
  exchangeRequests,
  failedExchangeRequests,
  exchangeResults,
  refreshTokens,
  pointLogs,
};
