import dayjs from "dayjs";
import { Getters } from "vuex";
import { CampaignGetters, CampaignState } from "./types";
import {
  EXTRA_CONDITION_TIMING,
  ExchangeConfig,
} from "@consumer/services/api/publicApi/campaigns";
import { getFormatDateTime, isBetween } from "@shared/utils/dayjs";
import { sanitizeHtml } from "@shared/utils/sanitizeHtml";

const formatExchangeConfigs = (
  exchangeConfigs: ExchangeConfig[]
): ExchangeConfig[] =>
  exchangeConfigs
    .filter((exchangeConfig) => exchangeConfig.visibility)
    .map((exchangeConfig) => {
      const { exchangeable_period_option } = exchangeConfig;
      return {
        ...exchangeConfig,
        exchangeable_period_option: {
          exchangeable_begin_at: getFormatDateTime(
            exchangeable_period_option.exchangeable_begin_at
          ),
          exchangeable_end_at: getFormatDateTime(
            exchangeable_period_option.exchangeable_end_at
          ),
        },
      };
    });

export const getters: Getters<CampaignState, CampaignGetters> = {
  header_option(state, getters) {
    return state.header_option;
  },
  sanitized_details(state) {
    return state.details.map((detail) => ({
      ...detail,
      description: !!detail.description
        ? sanitizeHtml(detail.description)
        : null,
    }));
  },
  after_login_extra_conditions(state) {
    return state.extra_conditions.filter(
      (extra_condition) =>
        extra_condition.timing === EXTRA_CONDITION_TIMING.AFTER_LOGIN
    );
  },
  formatted_entryable_period_option(state) {
    const { entryable_period_option } = state;
    return {
      entryable_begin_at: getFormatDateTime(
        entryable_period_option.entryable_begin_at
      ),
      entryable_end_at: getFormatDateTime(
        entryable_period_option.entryable_end_at
      ),
    };
  },
  is_entryable(state) {
    const { entryable_period_option } = state;
    return isBetween(
      entryable_period_option.entryable_begin_at,
      entryable_period_option.entryable_end_at
    );
  },
  is_loginable(state) {
    const { loginable_end_at } = state;
    return getters["is_entryable"] && dayjs().isBefore(loginable_end_at);
  },
  formatted_chargeable_period_options(state) {
    return state.charge_configs.map((charge_config) => {
      const { chargeable_period_option } = charge_config;
      return {
        chargeable_begin_at: getFormatDateTime(
          chargeable_period_option.chargeable_begin_at
        ),
        chargeable_end_at: getFormatDateTime(
          chargeable_period_option.chargeable_end_at
        ),
      };
    });
  },
  is_chargeable_periods(state) {
    return state.charge_configs.map((charge_config) => {
      const { chargeable_period_option } = charge_config;
      return isBetween(
        chargeable_period_option.chargeable_begin_at,
        chargeable_period_option.chargeable_end_at
      );
    });
  },
  has_receipt_charge_config(state) {
    return state.charge_configs.some(
      (chargeConfig) => chargeConfig.charge_type === "point_specify"
    );
  },
  formatted_visible_exchange_configs(state) {
    return formatExchangeConfigs(state.exchange_configs);
  },
  formatted_visible_exchange_config_groups(state) {
    return state.exchange_config_groups.map((exchangeConfigGroup) => {
      return {
        ...exchangeConfigGroup,
        exchange_configs: formatExchangeConfigs(
          exchangeConfigGroup.exchange_configs
        ),
      };
    });
  },
  sanitized_entry_steps(state) {
    return state.entry_steps.map((entry_step) => ({
      ...entry_step,
      description: !!entry_step.description
        ? sanitizeHtml(entry_step.description)
        : null,
    }));
  },
  login_page_info_messages(state) {
    return state.info_messages
      .filter((info_message) => info_message.login_page_visible)
      .map((info_message) => info_message.message);
  },
  campaign_page_info_messages(state) {
    return state.info_messages
      .filter((info_message) => info_message.campaign_page_visible)
      .map((info_message) => info_message.message);
  },
  my_page_info_messages(state) {
    return state.info_messages
      .filter((info_message) => info_message.my_page_visible)
      .map((info_message) => info_message.message);
  },
  chargeConfig: (state) => (chargeConfigUrlCode: string) =>
    state.charge_configs.find(
      (chargeConfig) => chargeConfig.url_code === chargeConfigUrlCode
    ) ?? null,
  isChargeablePeriod: (state, getters) => (chargeConfigUrlCode: string) => {
    const chargeConfig = getters.chargeConfig(chargeConfigUrlCode);
    if (!chargeConfig) return false;
    const { chargeable_period_option } = chargeConfig;
    return isBetween(
      chargeable_period_option.chargeable_begin_at,
      chargeable_period_option.chargeable_end_at
    );
  },
  chargeErrorMessage: (state, getters) => (chargeConfigUrlCode: string) => {
    const chargeConfig = getters.chargeConfig(chargeConfigUrlCode);
    return chargeConfig
      ? getters.isChargeablePeriod(chargeConfigUrlCode)
        ? null
        : `${getters.chargeablePeriodText(chargeConfigUrlCode)}外です`
      : "読み込み中です";
  },
  chargeablePeriodText: (state, getters) => (chargeConfigUrlCode: string) => {
    const chargeConfig = getters.chargeConfig(chargeConfigUrlCode);
    if (chargeConfig === null) return null;
    if (chargeConfig.immediately_exchanging_option) {
      return getters["getCustomizingWord"](
        "charge.immediately_exchanging.chargeable_period_text",
        "応募可能期間"
      );
    } else {
      switch (chargeConfig.charge_type) {
        case "point_specify":
          return getters["getCustomizingWord"](
            "receipt_charging.chargeable_period_text",
            "レシート投稿可能期間"
          );
        case "charge_serial":
        default:
          return getters["getCustomizingWord"](
            "charge_serial.chargeable_period_text",
            "ポイント獲得可能期間"
          );
      }
    }
  },
  getCustomizingWord:
    (state) =>
    (key, defaultValue, sanitize = false) => {
      const customizingWord = state.customizing_words.find(
        (customizingWord) => customizingWord.key === key
      );
      return !!customizingWord
        ? sanitize
          ? sanitizeHtml(customizingWord.text)
          : customizingWord.text
        : defaultValue;
    },
};
