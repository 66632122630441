import axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from "axios";
import { getTokenFromCookie } from "@shared/utils/cookie";

const PUBLIC_API_BASE_PATH = "/public_api";
const INTERNAL_API_BASE_PATH = "/internal_api";

const createClient = (config?: AxiosRequestConfig): AxiosInstance => {
  return axios.create(config);
};

export const publicApiClient = createClient({
  baseURL: PUBLIC_API_BASE_PATH,
});

export const internalApiClient = createClient({
  baseURL: INTERNAL_API_BASE_PATH,
});

internalApiClient.interceptors.request.use((request) => {
  const token = getTokenFromCookie();
  if (token) {
    const encodedToken = window.btoa(token);
    request.headers["Authorization"] = `Basic ${encodedToken}:`;
  }

  return request;
});

internalApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // handleCommonErrorIfNeeded(error);
    return Promise.reject(error);
  }
);

publicApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    handleCommonErrorIfNeeded(error);
    return Promise.reject(error);
  }
);

export type SharedAxiosErrorResponse = {
  title: string;
  type: string;
};

const handleCommonErrorIfNeeded = (
  error: AxiosError<SharedAxiosErrorResponse>
): void => {
  if (error.response) {
    if (error.response.status === 404) {
      location.href = "/404";
    }
  }
};

export type PagenationRequest = {
  page?: number;
  per?: number;
};

export type PagenationResponse = {
  total_count: number;
  limit_value: number;
  previous_page: number | null;
  next_page: number | null;
  has_previous_page: boolean;
  has_next_page: boolean;
  current_page: number;
  total_pages: number;
};
