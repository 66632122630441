import { internalApiClient } from "../client";
import { RouteParams } from "vue-router";

const path = "/campaigns";

const putRefreshToken = (campaignUrlCode: RouteParams["number"]) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  return internalApiClient.put<RefreshToken>(
    `${path}/${campaignUrlCodeStr}/refresh_token`
  );
};

export type RefreshToken = {
  token: string;
};

export const refreshTokens = {
  put: putRefreshToken,
};
