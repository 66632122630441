
import { defineComponent, PropType } from "vue";
import {
  ExchangeResult,
  Gift,
} from "@consumer/services/api/internalApi/exchangeResults";

type ExchangeResultWithGift = ExchangeResult & {
  gift: Gift;
};

export default defineComponent({
  name: "Gift",
  props: {
    exchangeResultWithGift: {
      type: Object as PropType<ExchangeResultWithGift>,
      required: true,
    },
    receivedDateLabel: {
      type: String,
      required: false,
    },
  },
  setup() {
    const goGiftUrl = (exchangeResult: ExchangeResultWithGift) => {
      location.href = exchangeResult.gift.url;
    };
    return { goGiftUrl };
  },
});
