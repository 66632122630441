<template>
  <div
    v-if="!!lastExchangeResult.gift && exchangeButtonLabel"
    class="w-full sm:w-6/12 text-center mx-auto justify-center p-2"
  >
    <BaseCard class="p-4">
      <div class="flex justify-center">
        <div class="text-center">
          <div class="text-2xl">{{ title }}</div>
          <div class="text-gray-700 text-sm" v-if="subTitle">
            {{ subTitle }}
          </div>
        </div>
      </div>
      <div class="text-center my-4 w-9/12 m-auto">
        <img :src="lastExchangeResult.gift.image_url" class="mx-auto" />
        <div class="text-gray-500 text-xs mt-1">
          {{ lastExchangeResult.gift.name }}
        </div>
      </div>
      <div class="w-9/12 m-auto">
        <BaseButton class="w-full" buttonClass="main" @click="handleGoGiftClick"
          ><span class="mx-auto">{{ exchangeButtonLabel }}</span></BaseButton
        >
        <BaseButton
          class="w-full mt-3"
          buttonClass="outlined"
          @click="mypageLinkTo"
          ><span class="mx-auto">マイページに戻る</span></BaseButton
        >
      </div>
    </BaseCard>
    <ContinuouslyExchangeCards class="mt-2" />
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";
import { useStore } from "@consumer/store";
import ContinuouslyExchangeCards from "@consumer/components/campaign/exchange/ContinuouslyExchangeCards.vue";

export default defineComponent({
  components: {
    ContinuouslyExchangeCards,
  },
  setup() {
    const store = useStore();
    const { params } = useRoute();
    const router = useRouter();
    const lastExchangeResult = computed(() => {
      const exchangeResultsItems = store.state.exchangeResults.items!;
      return exchangeResultsItems[exchangeResultsItems.length - 1];
    });
    const exchangeConfig = computed(() => {
      const { exchange_config_url_code } = lastExchangeResult.value;
      return store.state.campaign.exchange_configs.find(
        (exchangeConfig) => exchangeConfig.url_code === exchange_config_url_code
      );
    });
    const exchangeButtonLabel = computed(() =>
      exchangeConfig.value?.winning_page_config
        ? exchangeConfig.value?.winning_page_config.exchange_button_label
        : "ギフトをもらう"
    );
    const title = computed(() =>
      exchangeConfig.value?.winning_page_config
        ? exchangeConfig.value.winning_page_config.title
        : "あたり"
    );
    const subTitle = computed(() =>
      exchangeConfig.value?.winning_page_config
        ? exchangeConfig.value.winning_page_config.sub_title
        : null
    );

    const handleGoGiftClick = () => {
      location.href = lastExchangeResult.value.gift?.url || "";
    };

    const mypageLinkTo = () => {
      router.push({
        name: routeNames.CAMPAING_MY_PAGE,
        params: { campaign_url_code: params.campaign_url_code },
      });
    };

    return {
      handleGoGiftClick,
      mypageLinkTo,
      lastExchangeResult,
      exchangeButtonLabel,
      title,
      subTitle,
    };
  },
});
</script>
