import { createRouter, createWebHistory } from "vue-router";
import { routeNames } from "./routeNames";
import NotFound from "@shared/components/NotFound.vue";
import InternalServerError from "@shared/components/InternalServerError.vue";
import Login from "@shared/components/Login.vue";
import AuthenticationError from "@shared/components/AuthenticationError.vue";
import LineFriendshipStatusError from "@consumer/views/LineFriendshipStatusError.vue";
import BlockedLoginUserError from "@consumer/views/BlockedLoginUserError.vue";
import Entry from "@consumer/views/campaign/Entry.vue";
import MyPage from "@consumer/views/campaign/MyPage.vue";
import Campaign from "@consumer/views/Campaign.vue";
import ReceiptUpload from "@consumer/views/campaign/charge_config/ReceiptUpload.vue";
import ReceiptUploadSuccess from "@consumer/views/campaign/charge_config/ReceiptUpload/Success.vue";
import Exchange from "@consumer/views/campaign/Exchange.vue";
import LotteryImage from "@consumer/views/campaign/exchange/LotteryImage.vue";
import Gift from "@consumer/views/campaign/exchange/Gift.vue";
import Defeat from "@consumer/views/campaign/exchange/Defeat.vue";
import Error from "@consumer/views/campaign/exchange/Error.vue";

const routerHistory = createWebHistory();

export const router = createRouter({
  history: routerHistory,
  strict: true,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
  routes: [
    {
      path: "/campaigns/:campaign_url_code",
      component: Campaign,
      name: routeNames.CAMPAIGN,
      props: true,
      children: [
        {
          path: "",
          component: Entry,
          name: routeNames.CAMPAING_ENTRY,
        },
        {
          path: "my_page",
          component: MyPage,
          name: routeNames.CAMPAING_MY_PAGE,
        },
        {
          path: "charge_configs/:charge_config_url_code/receipt_upload/success",
          component: ReceiptUploadSuccess,
          name: routeNames.CHARGE_RECEIPT_UPLOADED_SUCCESS,
          props: true,
        },
        {
          path: "charge_configs/:charge_config_url_code/receipt_upload",
          component: ReceiptUpload,
          name: routeNames.CHARGE_RECEIPT_UPLOAD,
          props: true,
        },
        {
          path: "exchange",
          component: Exchange,
          name: routeNames.EXCHANGE_PAGE,
          children: [
            {
              path: "",
              component: LotteryImage,
              name: routeNames.EXCHANGE_LOTTERY_IMAGE,
            },
            {
              path: "gift",
              component: Gift,
              name: routeNames.EXCHANGE_GIFT,
            },
            {
              path: "defeat",
              component: Defeat,
              name: routeNames.EXCHANGE_DEFEAT,
            },
            {
              path: "exchange_error",
              component: Error,
              name: routeNames.EXCHANGE_ERROR,
            },
          ],
        },
      ],
    },
    // campaign配下でないルーティングを増やす場合、refererのチェック対象かを確認すること
    // app/models/concerns/referer_checkable.rb
    {
      path: "/login",
      component: Login,
      name: routeNames.LOGIN,
    },
    {
      path: "/authentication_error",
      component: AuthenticationError,
      name: routeNames.AUTHENTICATION_ERROR,
    },
    {
      path: "/line_friendship_status_error",
      component: LineFriendshipStatusError,
      name: routeNames.LINE_FRIENDSHIP_STATUS_ERROR,
    },
    {
      path: "/blocked_login_user_error",
      component: BlockedLoginUserError,
      name: routeNames.BLOCKED_LOGIN_USER_ERROR,
    },
    {
      path: "/500",
      component: InternalServerError,
      name: routeNames.INTERNAL_SERVER_ERROR,
    },
    { path: "/404", component: NotFound, name: routeNames.EXACT_NOT_FOUND },
    { path: "/:unknown(.*)", component: NotFound, name: routeNames.NOT_FOUND },
    {
      path: "/oauth_session",
      component: NotFound,
      name: routeNames.OAUTH_SESSION,
    },
  ],
});
