<template>
  <button class="m-2 h-16 line inline-flex items-center rounded-lg relative">
    <img :src="lineIcon" class="inline p-2" />
    <div class="h-16 border line-vertical"></div>
    <div clas="mx-10">
      <p class="mx-6 text-base"><slot /></p>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import lineIcon from "@assets/line_icon.svg";
export default defineComponent({
  name: "LineButton",
  setup() {
    return {
      lineIcon,
    };
  },
});
</script>

<style lang="scss" scoped>
.line {
  @apply bg-line-main text-white;

  .line-vertical {
    @apply border-lineVertical-main;
  }
}

.line:hover {
  @apply bg-line-hover;

  &:hover .line-vertical {
    @apply border-lineVertical-hover;
  }
}

.line:active {
  @apply bg-line-active;

  &:active .line-vertical {
    @apply border-lineVertical-active;
  }
}
</style>
