import { PromiseActions, PromiseActionContext } from "../types";
import { useRouter, useRoute } from "vue-router";
import { internalApi } from "@consumer/services/api";
import { routeNames } from "@consumer/router/routeNames";
import { getTokenFromCookie } from "@shared/utils/cookie";

export const checkRefreshToken = async (
  ctx: PromiseActionContext,
  payload: PromiseActions["checkRefreshToken"]
) => {
  const router = useRouter();
  const route = useRoute();

  // cookieにトークンがない場合はリクエストもしない
  if (!getTokenFromCookie()) {
    router.push({
      name: routeNames.CAMPAING_ENTRY,
      params: { campaign_url_code: payload.campaign_url_code },
      query: { ...route.query },
    });
    return;
  }

  try {
    await internalApi.refreshTokens.put(payload.campaign_url_code);
    ctx.dispatch("ui/putRefreshTokenSuccess", true, { root: true });
  } catch {
    ctx.dispatch("ui/putRefreshTokenFailure", false, { root: true });
    router.push({
      name: routeNames.CAMPAING_ENTRY,
      params: { campaign_url_code: payload.campaign_url_code },
      query: { ...route.query },
    });
  }
};
