<template>
  <div class="fixed z-20 inset-0 overflow-y-auto" v-if="open">
    <div class="flex justify-center items-center h-full">
      <div class="fixed inset-0 transition-opacity" @click.prevent="close">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <div
        class="m-3 p-3 bg-white rounded overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    closable: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  setup(props, context) {
    const close = () => {
      if (props.closable) {
        context.emit("update:open", !props.open);
      }
    };

    return { close };
  },
});
</script>
