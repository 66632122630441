import { AxiosError } from "axios";
import { publicApiClient, SharedAxiosErrorResponse } from "../client";

const path = "/authentication";

const postAuthentication = (queryStrings: QueryStrings) => {
  const code = queryStrings.code;
  const state = queryStrings.state;
  return publicApiClient.post<Authentication>(`${path}`, { code, state });
};

export type QueryStrings = {
  code: string;
  state: string;
  [key: string]: string;
};

export type Authentication = {
  token: string;
  campaign_url_code: string;
  serial_option: SerialOption;
};

export type SerialOption = {
  serial_code: string;
} | null;

export type PostAuthenticationError =
  AxiosError<PostAuthenticationAxiosErrorResponse>;

export type PostAuthenticationAxiosErrorResponse = SharedAxiosErrorResponse & {
  extension?: PostAuthenticationExtension;
};

export type PostAuthenticationExtension = {
  error_type: string;
  campaign_url_code: string;
  serial_code?: string;
};

export const authentications = {
  post: postAuthentication,
};
