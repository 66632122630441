
import { defineComponent, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { routeNames } from "@consumer/router/routeNames";
import { useStore } from "@consumer/store";
import ContinuouslyExchangeCards from "@consumer/components/campaign/exchange/ContinuouslyExchangeCards.vue";

export default defineComponent({
  components: {
    ContinuouslyExchangeCards,
  },
  setup() {
    const route = useRoute();
    const mypageLinkTo = computed(() => ({
      name: routeNames.CAMPAING_MY_PAGE,
      params: { campaign_url_code: route.params.campaign_url_code },
    }));

    const store = useStore();
    const exchangeConfigUrlCode = computed(
      () => store.state.exchange.exchangeConfigUrlCode
    );
    const exchangeConfigTitle = computed(() => {
      const defaultValue = "残念...";
      return exchangeConfigUrlCode.value
        ? store.getters["campaign/getCustomizingWord"](
            `exchange_config.${exchangeConfigUrlCode.value}.defeat_page.title`,
            defaultValue
          )
        : defaultValue;
    });
    const exchangeConfigSubTitle = computed(() => {
      const defaultValue = "またチャレンジしてね";
      return exchangeConfigUrlCode.value
        ? store.getters["campaign/getCustomizingWord"](
            `exchange_config.${exchangeConfigUrlCode.value}.defeat_page.sub_title`,
            defaultValue
          )
        : defaultValue;
    });
    const defeatPageConfig = computed(
      () => store.state.campaign.lottery_page_config?.defeat_page_config
    );
    onMounted(() => {
      store.commit("exchange/updateForceDefeat", false);
    });

    return {
      mypageLinkTo,
      defeatPageConfig,
      exchangeConfigTitle,
      exchangeConfigSubTitle,
    };
  },
});
