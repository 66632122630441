import { Actions } from "vuex";
import {
  PromiseState,
  PromiseGetters,
  PromiseMutations,
  PromiseActions,
} from "./types";
import { getCampaignPage } from "./actions/getCampaignPage";
import { getMyPage } from "./actions/getMyPage";
import { getNextReceiptChargeRequests } from "./actions/getNextReceiptChargeRequests";
import { getInternalApiCampaign } from "./actions/getInternalApiCampaign";
import { postDirectUpload } from "./actions/postDirectUpload";
import { handleError, handleErrorWithSnackbar } from "./actions/handleError";
import { checkRefreshToken } from "./actions/checkRefreshToken";
import { checkAfterLoginExtraConditions } from "./actions/checkAfterLoginExtraConditions";
import { postOAuthSession } from "./actions/postOAuthSession";
import { postAuthentication } from "./actions/postAuthentication";
import { postChargeSerialCharging } from "./actions/postChargeSerialCharging";
import { postChargeRequestReceiptCharging } from "./actions/postChargeRequestReceiptCharging";
import { postChargeRequestImmediatelyExchanging } from "./actions/postChargeRequestImmediatelyExchanging";
import { postExchangeRequest } from "./actions/postExchangeRequest";
import { subscribeUpdateWalletChannel } from "./actions/subscribeUpdateWalletChannel";
import { subscribeIspReceiptResultFinishChannel } from "./actions/subscribeIspReceiptResultFinishChannel";

export const actions: Actions<
  PromiseState,
  PromiseActions,
  PromiseGetters,
  PromiseMutations
> = {
  handleError,
  handleErrorWithSnackbar,
  getMyPage,
  getNextReceiptChargeRequests,
  getInternalApiCampaign,
  postDirectUpload,
  getCampaignPage,
  checkRefreshToken,
  checkAfterLoginExtraConditions,
  postOAuthSession,
  postAuthentication,
  postChargeSerialCharging,
  postChargeRequestReceiptCharging,
  postChargeRequestImmediatelyExchanging,
  postExchangeRequest,
  subscribeUpdateWalletChannel,
  subscribeIspReceiptResultFinishChannel,
};
