import { DirectUploadsState } from "./types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: DirectUploadsState = undefined;

const directUploadsModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default directUploadsModule;
