<template>
  <div
    v-if="footerDescription"
    class="bg-gray-200 p-2 text-xs gray-700 whitespace-pre-wrap"
  >
    {{ footerDescription }}
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "@consumer/store/index";
export default defineComponent({
  setup() {
    const store = useStore();
    const footerDescription = computed(
      () => store.state.campaign.footer_option?.description
    );

    return {
      footerDescription,
    };
  },
});
</script>
