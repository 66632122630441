import sanitize, { IOptions } from "sanitize-html";

const getAllowedAttributes = (): IOptions["allowedAttributes"] => {
  const extendedAllowedAttributes: IOptions["allowedAttributes"] = {};
  const { allowedAttributes } = sanitize.defaults;

  for (const key in allowedAttributes) {
    extendedAllowedAttributes[key] = [...allowedAttributes[key], "style"];
  }
  ["div", "span"].forEach((tag) => {
    extendedAllowedAttributes[tag] = [
      ...(allowedAttributes[tag] || []),
      "style",
    ];
  });

  return extendedAllowedAttributes;
};

const defaultOptions: IOptions = {
  allowedAttributes: getAllowedAttributes(),
};

export const sanitizeHtml = (dirty: string, options = defaultOptions) => {
  return sanitize(dirty, options);
};
