<template>
  <div
    class="mt-2 border-primary-main border-t-2 border-b-1 border-l-8 shadow-sm"
  >
    <a href="#" class="cursor-pointer" @click.prevent="toggle">
      <div class="h-20 p-3 flex justify-between">
        <div class="flex">
          <img
            :src="thumbnail_image_url"
            v-if="thumbnail_image_url"
            class="w-auto h-full mr-2"
          />
          <text
            class="text-primary-main font-medium h-10 leading-10 mt-2 text-center inline-block"
            >{{ title }}</text
          >
        </div>
        <div class="shadow rounded-full w-10 h-10">
          <transition name="rotate" mode="out-in">
            <text
              class="text-gray-400 text-center h-10 leading-10 inline-block"
              v-if="open"
              >&#9650;</text
            >
            <text
              class="text-gray-400 text-center h-10 leading-10 inline-block"
              v-else
              >&#9660;</text
            >
          </transition>
        </div>
      </div>
    </a>
    <transition name="open">
      <div v-if="open" class="p-2">
        <ExchangeCard
          v-for="(exchangeConfig, index) in exchange_configs"
          :key="index"
          :exchangeConfig="exchangeConfig"
        />
      </div>
    </transition>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, Ref, ref } from "vue";
import ExchangeCard from "./ExchangeCard.vue";
import { CampaignGetters } from "@consumer/store/modules/campaign/types";

export default defineComponent({
  name: "ExchangeGroupCard",
  props: {
    exchangeConfigGroup: {
      type: Object as PropType<
        CampaignGetters["formatted_visible_exchange_config_groups"][number]
      >,
      required: true,
    },
  },
  components: {
    ExchangeCard,
  },
  setup(props) {
    let open: Ref<Boolean> = ref(false);

    const { title, thumbnail_image_url, exchange_configs } =
      props.exchangeConfigGroup;

    const toggle = () => {
      open.value = !open.value;
    };

    return { open, title, thumbnail_image_url, exchange_configs, toggle };
  },
});
</script>
<style>
@keyframes open {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.open-enter-active {
  animation: open 0.5s;
}
.open-leave-active {
  animation: open 0.2s linear reverse;
}

@keyframes rotate {
  0% {
    transform: rotate(180deg);
  }
}
.rotate-enter-active {
  animation: rotate 0.1s linear;
}
</style>
