import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { CampaignState } from "./types";

const state: CampaignState = {
  name: "",
  url_code: "",
  loginable_end_at: "",
  header_option: null,
  footer_option: null,
  details: [],
  entry_steps: [],
  landing_page_config: {
    image_path: "",
  },
  my_page_config: null,
  lottery_page_config: null,
  charge_configs: [],
  exchange_configs: [],
  exchange_config_groups: [],
  extra_conditions: [],
  entryable_period_option: {
    entryable_begin_at: "",
    entryable_end_at: "",
  },
  info_messages: [],
  customizing_words: [],
  authenticator_registrations: [],
};

const campaignModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default campaignModule;
