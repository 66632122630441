<template>
  <div
    class="w-full h-24 md:h-32 flex rounded-lg shadow my-2 overflow-hidden cursor-pointer"
    @click="goGiftUrl(exchangeResultWithGift)"
  >
    <div class="h-24 w-24 md:h-32 md:w-32 flex-none items-center flex">
      <img
        :src="exchangeResultWithGift.gift.image_url"
        alt="giftImage"
        class="object-contain flex justify-center"
      />
    </div>

    <div class="p-2 md:p-4 flex items-center w-full">
      <div class="text-left flex-grow h-full relative">
        <h4 class="font-bold multiple-truncate text-sm sm:text-base my-2">
          {{ exchangeResultWithGift.gift.name }}
        </h4>
        <p class="text-gray-600 text-xs sm:text-sm absolute bottom-0">
          {{ receivedDateLabel || "当選日" }}：{{
            exchangeResultWithGift.created_at
          }}
        </p>
      </div>
      <div class="w-6 flex-none">
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          xmlns="http://www.w3.org/2000/svg"
          class="ml-auto"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 12.3004C16 12.5284 15.923 12.7554 15.768 12.9404L10.768 18.9404C10.415 19.3644 9.78398 19.4224 9.35998 19.0684C8.93598 18.7154 8.87898 18.0854 9.23198 17.6604L13.708 12.2894L9.39298 6.92738C9.04698 6.49738 9.11498 5.86738 9.54498 5.52138C9.97498 5.17538 10.604 5.24338 10.951 5.67338L15.779 11.6734C15.926 11.8564 16 12.0784 16 12.3004Z"
            fill="#5F6368"
          />
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="9"
            y="5"
            width="7"
            height="15"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16 12.3004C16 12.5284 15.923 12.7554 15.768 12.9404L10.768 18.9404C10.415 19.3644 9.78398 19.4224 9.35998 19.0684C8.93598 18.7154 8.87898 18.0854 9.23198 17.6604L13.708 12.2894L9.39298 6.92738C9.04698 6.49738 9.11498 5.86738 9.54498 5.52138C9.97498 5.17538 10.604 5.24338 10.951 5.67338L15.779 11.6734C15.926 11.8564 16 12.0784 16 12.3004Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0)">
            <rect
              y="24.3004"
              width="24"
              height="24"
              transform="rotate(-90 0 24.3004)"
              fill="#5F6368"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  ExchangeResult,
  Gift,
} from "@consumer/services/api/internalApi/exchangeResults";

type ExchangeResultWithGift = ExchangeResult & {
  gift: Gift;
};

export default defineComponent({
  name: "Gift",
  props: {
    exchangeResultWithGift: {
      type: Object as PropType<ExchangeResultWithGift>,
      required: true,
    },
    receivedDateLabel: {
      type: String,
      required: false,
    },
  },
  setup() {
    const goGiftUrl = (exchangeResult: ExchangeResultWithGift) => {
      location.href = exchangeResult.gift.url;
    };
    return { goGiftUrl };
  },
});
</script>
