import { DirectUpload } from "@rails/activestorage";
import { PromiseActionContext, PromiseActions } from "../types";
import { internalApi } from "@consumer/services/api";
import { getInternalCable } from "@consumer/services/actioncable/cable";
import {
  ISP_REQUEST_RECEIPT_FINISH_CHANNEL,
  CHARGE_RESULTED_CHANNEL,
  RECEIPT_CHARGING_PRELOTTERY_RESULT_CHANNEL,
} from "@consumer/services/actioncable/channelName";
import { routeNames } from "@consumer/router/routeNames";

const isCurrentChargeRequest = (
  ctx: PromiseActionContext,
  chargeRequestRequestIdentity: string
) =>
  ctx.rootState.receiptCharging.requestIdentity &&
  ctx.rootState.receiptCharging.requestIdentity ===
    chargeRequestRequestIdentity;

const isCurrentChargeRequestValid = (
  ctx: PromiseActionContext,
  chargeRequestRequestIdentity: string
) =>
  isCurrentChargeRequest(ctx, chargeRequestRequestIdentity) &&
  !ctx.rootState.receiptCharging.requestTimeout;

const getChargeConfig = (
  ctx: PromiseActionContext,
  payload: PromiseActions["postChargeRequestReceiptCharging"]
) =>
  ctx.rootState.campaign.charge_configs.find(
    (localChargeConfig) =>
      localChargeConfig.url_code === payload.charge_config_url_code
  );
const isImmediatelyExchangingChargeRequestConfig = (
  ctx: PromiseActionContext,
  payload: PromiseActions["postChargeRequestReceiptCharging"]
) => !!getChargeConfig(ctx, payload)!.immediately_exchanging_option;

const subscribeChargeResultedChannel = (
  ctx: PromiseActionContext,
  payload: PromiseActions["postChargeRequestReceiptCharging"]
) => {
  if (!ctx.rootState.actionCable.chargeResultedChannelSubscribed) {
    getInternalCable().subscriptions.create(CHARGE_RESULTED_CHANNEL, {
      received(data: { charge_request_request_identity: string }) {
        // subscriptionされたデータが直近のリクエストと一致しない場合は何もしない
        if (
          !isCurrentChargeRequestValid(
            ctx,
            data.charge_request_request_identity
          )
        ) {
          return;
        }

        const { campaign_url_code, router } = payload;

        ctx.commit("receiptCharging/updateRequestReceiptResult", "exchanging", {
          root: true,
        });

        const exchange_config_url_code = getChargeConfig(ctx, payload)!
          .immediately_exchanging_option!.exchange_config_url_code;
        ctx.dispatch("postChargeRequestImmediatelyExchanging", {
          campaign_url_code,
          exchange_config_url_code: exchange_config_url_code,
          charge_request_request_identity:
            ctx.rootState.receiptCharging.requestIdentity!,
          router,
        });
      },

      connected() {
        ctx.commit(
          "actionCable/updateActionCable",
          { chargeResultedChannelSubscribed: true },
          {
            root: true,
          }
        );
      },

      disconnected() {
        ctx.commit(
          "actionCable/updateActionCable",
          { chargeResultedChannelSubscribed: false },
          {
            root: true,
          }
        );
      },
    });
  }
};

const subscribeIspRequestReceiptFinishChannel = (
  ctx: PromiseActionContext,
  payload: PromiseActions["postChargeRequestReceiptCharging"]
) => {
  ctx.commit("receiptCharging/updateRequestReceiptResult", "requesting", {
    root: true,
  });

  if (!ctx.rootState.actionCable.ispRequestReceiptFinishChannelSubscribed) {
    getInternalCable().subscriptions.create(
      ISP_REQUEST_RECEIPT_FINISH_CHANNEL,
      {
        received(data: { successed: boolean; request_identity: string }) {
          // subscriptionされたデータが直近のリクエストと一致しない場合は何もしない
          if (!isCurrentChargeRequestValid(ctx, data.request_identity)) {
            return;
          }

          const requestReceiptResult = data.successed
            ? isImmediatelyExchangingChargeRequestConfig(ctx, payload)
              ? "analysing"
              : "success"
            : "failure";

          ctx.commit(
            "receiptCharging/updateRequestReceiptResult",
            requestReceiptResult,
            { root: true }
          );

          if (!data.successed) {
            const snackbarMessage = ctx.rootGetters[
              "campaign/getCustomizingWord"
            ](
              "snackbar.charge.receipt_charging.request_receipt_failed",
              "レシートの読み取りに失敗しました。お手数ですが再度レシートアップロードしてください。"
            );
            ctx.commit("snackbar/updateWithSuccess", snackbarMessage, {
              root: true,
            });
          }
        },

        connected() {
          ctx.commit(
            "actionCable/updateActionCable",
            { ispRequestReceiptFinishChannelSubscribed: true },
            {
              root: true,
            }
          );
        },

        disconnected() {
          ctx.commit(
            "actionCable/updateActionCable",
            { ispRequestReceiptFinishChannelSubscribed: false },
            {
              root: true,
            }
          );
        },
      }
    );
  }
};

const subscribeReceiptChargingPrelotteryResultChannel = (
  ctx: PromiseActionContext,
  payload: PromiseActions["postChargeRequestReceiptCharging"]
) => {
  if (
    !ctx.rootState.actionCable.receiptChargingPrelotteryResultChannelSubscribed
  ) {
    getInternalCable().subscriptions.create(
      RECEIPT_CHARGING_PRELOTTERY_RESULT_CHANNEL,
      {
        received(data: { result: "win" | "lose"; request_identity: string }) {
          // subscriptionされたデータが直近のリクエストと一致しない場合は何もしない
          if (!isCurrentChargeRequestValid(ctx, data.request_identity)) {
            return;
          }

          if (data.result === "lose") {
            setTimeout(
              (targetRequestId: string) => {
                // subscriptionされたデータが直近のリクエストと一致しない場合は何もしない
                if (!isCurrentChargeRequestValid(ctx, targetRequestId)) {
                  return;
                }

                goToDefeatPage(ctx, payload);
              },
              4000,
              data.request_identity
            );
          }
        },

        connected() {
          ctx.commit(
            "actionCable/updateActionCable",
            { receiptChargingPrelotteryResultChannelSubscribed: true },
            {
              root: true,
            }
          );
        },

        disconnected() {
          ctx.commit(
            "actionCable/updateActionCable",
            {
              receiptChargingPrelotteryResultChannelSubscribed: false,
            },
            {
              root: true,
            }
          );
        },
      }
    );
  }
};

const directUploadFile = async (
  ctx: PromiseActionContext,
  payload: PromiseActions["postChargeRequestReceiptCharging"],
  successFunc: (blobId: string) => {}
) => {
  const url = ctx.rootState.directUpload!.url;
  const directUpload = new DirectUpload(payload.receipt_image, url);
  directUpload.create((error, blob) => {
    if (error) {
      // エラー時の処理
      ctx.commit(
        "snackbar/updateWithError",
        "レシートを正しくアップロードできませんでした。お手数ですが再度アップロードをお願いいたします。",
        {
          root: true,
        }
      );
    } else {
      // 成功時の処理
      successFunc(blob.signed_id);
    }
  });
};

const goToDefeatPage = (
  ctx: PromiseActionContext,
  payload: PromiseActions["postChargeRequestReceiptCharging"]
) => {
  const { campaign_url_code, router } = payload;
  const exchange_config_url_code = getChargeConfig(ctx, payload)!
    .immediately_exchanging_option!.exchange_config_url_code;
  ctx.dispatch("postChargeRequestImmediatelyExchanging", {
    campaign_url_code,
    exchange_config_url_code: exchange_config_url_code,
    charge_request_request_identity:
      ctx.rootState.receiptCharging.requestIdentity!,
    router,
  });

  ctx.commit("exchange/updateForceDefeat", true, { root: true });
};

export const postChargeRequestReceiptCharging = async (
  ctx: PromiseActionContext,
  payload: PromiseActions["postChargeRequestReceiptCharging"]
) => {
  ctx.commit("postChargeRequestReceiptChargingStart");
  try {
    const requestId = new Date().getTime().toString();
    ctx.commit("receiptCharging/initializeStatus", requestId, {
      root: true,
    });

    subscribeIspRequestReceiptFinishChannel(ctx, payload);
    if (isImmediatelyExchangingChargeRequestConfig(ctx, payload)) {
      // インスタントウィン以外では利用しないのでインスタントウィンの時のみsubscribe
      subscribeChargeResultedChannel(ctx, payload);
    }
    if (
      getChargeConfig(ctx, payload)?.immediately_exchanging_option?.prelottery
    ) {
      subscribeReceiptChargingPrelotteryResultChannel(ctx, payload);
    }

    setTimeout(
      (targetRequestId: string) => {
        const { requestReceiptResult, requestId } =
          ctx.rootState.receiptCharging;
        if (
          (requestReceiptResult === "requesting" ||
            requestReceiptResult === "analysing") &&
          requestId === targetRequestId
        ) {
          ctx.commit("receiptCharging/requestTimeout", null, {
            root: true,
          });

          if (isImmediatelyExchangingChargeRequestConfig(ctx, payload)) {
            // インスタントウィンの時はハズレの画面を表示
            goToDefeatPage(ctx, payload);
          } else {
            // インスタントウィンではない時にはアラートを表示
            ctx.commit(
              "snackbar/updateWithError",
              "レシートを正しく認識できませんでした。お手数ですが再度アップロードをお願いいたします。",
              {
                root: true,
              }
            );
          }
        }
      },
      15000,
      requestId
    );

    directUploadFile(ctx, payload, async (blobId: string) => {
      try {
        const result = await internalApi.chargeRequestReceiptChargings.post(
          payload.campaign_url_code,
          payload.charge_config_url_code,
          blobId,
          payload.declaration_amount
        );

        ctx.commit(
          "receiptCharging/updateRequestIdentity",
          result.data.request_identity,
          {
            root: true,
          }
        );
        ctx.commit("postChargeRequestReceiptChargingSuccess", result);
      } catch (error: any) {
        if (error.response.status === 400) {
          const invalid_params = error.response.data.invalid_params;

          // とりあえず1件だけ表示する
          const knownErrorMessage = invalid_params.filter((param: any) =>
            [
              "charge_request_receipt_charging_declaration_amount",
              "charge_request_base",
            ].includes(param.name)
          )[0];

          if (knownErrorMessage) {
            ctx.commit(
              "receiptCharging/updateRequestReceiptResult",
              "failure",
              {
                root: true,
              }
            );

            ctx.commit("snackbar/updateWithError", knownErrorMessage.reason, {
              root: true,
            });
          }
        }
        ctx.dispatch("handleError", error);
        ctx.commit("postChargeRequestReceiptChargingFailure");
      }
    });
  } catch (error) {
    ctx.dispatch("handleError", error);
    ctx.commit("postChargeRequestReceiptChargingFailure");
  }
};
