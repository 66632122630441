import { FailedExchangeRequestsState } from "./types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: FailedExchangeRequestsState = {
  items: [],
};

const failedExchangeRequestsModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default failedExchangeRequestsModule;
