import { Mutations } from "vuex";
import {
  FailedExchangeRequestsState,
  FailedExchangeRequestsMutations,
} from "./types";

export const mutations: Mutations<
  FailedExchangeRequestsState,
  FailedExchangeRequestsMutations
> = {
  updateFailedExchangeRequests(state, payload) {
    state.items = [...payload];
  },
};
