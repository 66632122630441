<template>
  <div class="font-body bg-primary-light">
    <router-view />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
});
</script>
<style src="../assets/tailwind.css"></style>
