export const routeNames = {
  EXACT_NOT_FOUND: "exaxtNotFound",
  NOT_FOUND: "notFound",
  CAMPAIGN: "campaign",
  CAMPAING_ENTRY: "campaignEntry",
  CAMPAING_MY_PAGE: "campaignMyPage",
  CHARGE_CONFIG_PAGE: "chargeConfigPage",
  CHARGE_RECEIPT_UPLOAD: "chargeReceiptUploadPage",
  CHARGE_RECEIPT_UPLOADED_SUCCESS: "chargeReceiptUploadSuccessPage",
  EXCHANGE_PAGE: "exchangePage",
  EXCHANGE_LOTTERY_IMAGE: "exchangeLotteryImage",
  EXCHANGE_GIFT: "exchangeGift",
  EXCHANGE_DEFEAT: "exchangeDefeat",
  EXCHANGE_ERROR: "exchangeError",
  LOGIN: "login",
  AUTHENTICATION_ERROR: "authenticationError",
  LINE_FRIENDSHIP_STATUS_ERROR: "lineFriendshipStatusError",
  BLOCKED_LOGIN_USER_ERROR: "blockedLoginUserError",
  INTERNAL_SERVER_ERROR: "internalServerError",
  OAUTH_SESSION: "oAuthSession",
} as const;
