
import { defineComponent, computed } from "vue";
import CampaignDetailCard from "@consumer/components/campaign/shared/CampaignDetailCard.vue";
import { useStore } from "@consumer/store/index";
export default defineComponent({
  components: {
    CampaignDetailCard,
  },
  setup() {
    const store = useStore();
    const details = computed(() => store.getters["campaign/sanitized_details"]);

    return {
      details,
    };
  },
});
