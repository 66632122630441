import { App } from "vue";
import { Router } from "vue-router";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

export const initSentry = (app: App, router: Router): void => {
  if (process.env.ENV === "prd" || process.env.ENV === "stg") {
    Sentry.init({
      app,
      dsn:
        "https://1a5617c9733048ba85b2b2b291eefc75@o100084.ingest.sentry.io/5464336",
      release: process.env.IMAGE_TAG,
      environment: process.env.ENV,
      maxValueLength: 1000,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [
            "localhost",
            "mileage.giftee.biz/",
            "mileage-stg.giftee.biz/",
          ],
        }),
      ],
    });
  } else {
    console.debug("Sentry is not initialized");
  }
};
