<template>
  <div v-if="exchangeConfigGroups.length">
    <ExchangeGroupCard
      v-for="(exchangeConfigGroup, index) in exchangeConfigGroups"
      :key="index"
      :exchangeConfigGroup="exchangeConfigGroup"
    />
  </div>
  <div v-else-if="exchangeConfigs.length">
    <ExchangeCard
      v-for="(exchangeConfig, index) in exchangeConfigs"
      :key="index"
      :exchangeConfig="exchangeConfig"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
import ExchangeCard from "./ExchangeCard.vue";
import ExchangeGroupCard from "./ExchangeGroupCard.vue";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "ExchangeCards",
  components: {
    ExchangeCard,
    ExchangeGroupCard,
  },
  setup() {
    const store = useStore();

    const exchangeConfigs = computed(
      () => store.getters["campaign/formatted_visible_exchange_configs"]
    );
    const exchangeConfigGroups = computed(
      () => store.getters["campaign/formatted_visible_exchange_config_groups"]
    );

    return { exchangeConfigs, exchangeConfigGroups };
  },
});
</script>
