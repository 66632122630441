type ApiUriWebsocket = string;

const HttpWithSSL = "https:";

type WebsocketProtocols =
  typeof WEBSOCKET_PROTOCOLS[keyof typeof WEBSOCKET_PROTOCOLS];
const WEBSOCKET_PROTOCOLS = {
  WITH_HTTP: "ws:",
  WITH_HTTPS: "wss:",
} as const;

export const getApiUriWebsocket = (): ApiUriWebsocket => {
  const { protocol, host } = window.location;
  return `${getWebsocketProtocol(protocol)}//${host}`;
};

const getWebsocketProtocol = (httpProtocol: string): WebsocketProtocols => {
  switch (httpProtocol) {
    case HttpWithSSL:
      return WEBSOCKET_PROTOCOLS.WITH_HTTPS;
    default:
      return WEBSOCKET_PROTOCOLS.WITH_HTTP;
  }
};

export const isWithSSL = window.location.protocol === HttpWithSSL;
