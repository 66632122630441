import { internalApiClient } from "../client";
import { ExchangeResult } from "./exchangeResults";
import { RouteParams } from "vue-router";

const path = "/campaigns";

const postExchangeRequest = (params: {
  campaignUrlCode: RouteParams["number"];
  exchangeConfigUrlCode: string;
  requestIdentity?: string;
}) => {
  const campaignUrlCodeStr =
    typeof params.campaignUrlCode === "string"
      ? params.campaignUrlCode
      : params.campaignUrlCode[0];

  return internalApiClient.post<ExchangeRequest>(
    `${path}/${campaignUrlCodeStr}/exchange_requests`,
    {
      exchange_config_url_code: params.exchangeConfigUrlCode,
      request_identity: params.requestIdentity,
    }
  );
};

export type ExchangeRequest = {
  request_identity: string;
  exchange_result: ExchangeResult;
};
export type ExchangeRequests = ExchangeRequest[];

export const exchangeRequests = {
  post: postExchangeRequest,
};
