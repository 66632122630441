import { PromiseActionContext, PromiseActions } from "../types";
import { internalApi } from "@consumer/services/api";
import * as Sentry from "@sentry/browser";

export const getNextReceiptChargeRequests = async (
  ctx: PromiseActionContext,
  payload: PromiseActions["getNextReceiptChargeRequests"]
) => {
  if (!ctx.rootState.receiptChargeRequests.pagenation?.has_next_page) return;

  ctx.commit("getNextReceiptChargeRequestsStart", null);

  try {
    const result = await internalApi.chargeRequests.get(
      payload.campaign_url_code,
      {
        charge_type: "receipt",
        page: ctx.rootState.receiptChargeRequests.pagenation!.next_page!,
      }
    );
    ctx.commit("receiptChargeRequests/addReceiptChargeRequests", result.data, {
      root: true,
    });
    ctx.commit("getNextReceiptChargeRequestsSuccess", result);
  } catch (error) {
    Sentry.captureException(error);

    ctx.dispatch("handleErrorWithSnackbar", error);
    ctx.commit("getNextReceiptChargeRequestsFailure");
  }
};
