import { LoginUserState } from "./types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: LoginUserState = {
  questionnaire_requesting: null,
};

const LoginUserModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default LoginUserModule;
