import { Mutations } from "vuex";
import { PointLogsState, PointLogsMutations } from "./types";

export const mutations: Mutations<PointLogsState, PointLogsMutations> = {
  initPointLogs(state, payload) {
    state.items = payload.point_logs;
    state.currentPagenation = payload.pagenation;
  },
  updatePointLogs(state, payload) {
    state.items = [...state.items, ...payload.point_logs];
    state.currentPagenation = payload.pagenation;
  },
};
