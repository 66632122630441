import { Actions } from "vuex";
import {
  CampaignState,
  CampaignGetters,
  CampaignMutations,
  CampaignActions,
} from "./types";

export const actions: Actions<
  CampaignState,
  CampaignActions,
  CampaignGetters,
  CampaignMutations
> = {
  getCampaignSuccess(ctx, payload) {
    ctx.commit("updateCampaign", payload);
  },
};
