import { Actions } from "vuex";
import {
  ActionCableState,
  ActionCableGetters,
  ActionCableMutations,
  ActionCableActions,
} from "./types";

export const actions: Actions<
  ActionCableState,
  ActionCableActions,
  ActionCableGetters,
  ActionCableMutations
> = {};
