<template>
  <div class="h-8 flex my-3 justify-center">
    <label
      v-if="lotteryRabelVisibility"
      for="exchangeStatus"
      class="w-16 text-white text-xs flex justify-center items-center rounded mr-1"
      :class="{
        issuable: exchangeResult.gift_issuable,
        'no-issuable': !exchangeResult.gift_issuable,
      }"
    >
      {{ resultLabel }}
    </label>
    <span class="w-56 text-xs text-gray-700 text-left my-auto"
      >{{ exchangingRequestDateTitle }}：{{ exchangeResult.created_at }}</span
    >
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import { ExchangeResult } from "@consumer/services/api/internalApi/exchangeResults";
import { useStore } from "@consumer/store";

export default defineComponent({
  name: "ExchangeResult",
  props: {
    exchangeResult: {
      type: Object as PropType<ExchangeResult>,
      required: true,
    },
    lotteryRabelVisibility: {
      type: Boolean,
      required: true,
    },
    exchangingRequestDateTitle: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const resultLabel = computed(() =>
      props.exchangeResult.gift_issuable
        ? store.getters["campaign/getCustomizingWord"](
            "exchange_result.win_label",
            "アタリ"
          )
        : store.getters["campaign/getCustomizingWord"](
            "exchange_result.defeat_label",
            "ハズレ"
          )
    );

    return { resultLabel };
  },
});
</script>
<style lang="scss" scoped>
.issuable {
  background-color: #7bd7f6;
}
.no-issuable {
  background-color: #f67b7b;
}
</style>
