
import { defineComponent, computed } from "vue";
import { useStore } from "@consumer/store/index";
export default defineComponent({
  setup() {
    const store = useStore();
    const footerDescription = computed(
      () => store.state.campaign.footer_option?.description
    );

    return {
      footerDescription,
    };
  },
});
