import { internalApiClient } from "../../client";
import { RouteParams } from "vue-router";

const path = "/campaigns";

const postQuestionnaireRequesting = (
  campaignUrlCode: RouteParams["number"]
) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  return internalApiClient.post<QuestionnaireRequesting>(
    `${path}/${campaignUrlCodeStr}/login_user/questionnaire_requesting`
  );
};

export type QuestionnaireRequesting = {
  confirmed: boolean;
  form_url: string;
};

export const questionnaireRequesting = {
  post: postQuestionnaireRequesting,
};

