<template>
  <div v-if="visible">
    <CardWithTitle :title="exchangeCardsTitle" class="text-center">
      <div class="bg-primary-light rounded-full w-9/12 m-auto p-1 text-xs">
        {{ exchangeCardsPointPrefix }}{{ wallet.point }}
      </div>
      <ExchangeCards />
    </CardWithTitle>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, onMounted } from "vue";
import ExchangeCards from "../shared/ExchangeCards.vue";
import CardWithTitle from "../shared/CardWithTitle.vue";
import { useStore } from "@consumer/store";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    ExchangeCards,
    CardWithTitle,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const exchangeConfigs = computed(
      () => store.getters["campaign/formatted_visible_exchange_configs"]
    );
    const exchangeConfigGroups = computed(
      () => store.getters["campaign/formatted_visible_exchange_config_groups"]
    );
    const visible = computed(
      () =>
        exchangeConfigs.value.length > 0 ||
        exchangeConfigGroups.value.length > 0
    );
    const wallet = computed(() => store.state.wallet);
    const exchangeCardsTitle = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "exchange.exchange_cards.title",
        "連続して応募する"
      )
    );
    const exchangeCardsPointPrefix = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "exchange.exchange_cards.point_prefix",
        "あなたの所持ポイント"
      )
    );

    // 交換履歴を再取得する必要があるため、promise/getMyPage を呼び出す
    // TODO: 交換履歴を取得するだけのアクションを用意してそれを使う
    
    onMounted(() => {
      store.dispatch("promise/getMyPage", {
        campaign_url_code: route.params.campaign_url_code,
      });
    });

    return {
      visible,
      wallet,
      exchangeCardsTitle,
      exchangeCardsPointPrefix,
    };
  },
});
</script>
