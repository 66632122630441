import { PromiseActions, PromiseActionContext } from "../types";
import { internalApi } from "@consumer/services/api";

export const postDirectUpload = async (
  ctx: PromiseActionContext,
  payload: PromiseActions["postDirectUpload"]
) => {
  try {
    const result = await internalApi.directUploads.post(
      payload.campaign_url_code
    );
    ctx.commit("directUpload/updateDirectUpload", result.data, { root: true });
  } catch {}
};
