import { ReceiptChargeRequestsState } from "./types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: ReceiptChargeRequestsState = {
  items: null,
  pagenation: null,
};

const exchangeResultsModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default exchangeResultsModule;
