import { Mutations } from "vuex";
import { ExchangeResultsState, ExchangeResultsMutations } from "./types";

export const mutations: Mutations<
  ExchangeResultsState,
  ExchangeResultsMutations
> = {
  updateExchangeResults(state, payload) {
    state.items = [...payload];
  },
  pushExchangeResult(state, payload) {
    if (state.items) {
      state.items = [...state.items, payload];
    } else {
      state.items = [payload];
    }
  },
};
