
import { defineComponent } from "vue";
import CardWithTitle from "@consumer/components/campaign/shared/CardWithTitle.vue";
import { Detail } from "@consumer/services/api/publicApi/campaigns";
export default defineComponent({
  components: {
    CardWithTitle,
  },
  props: {
    detail: {
      type: Object as () => Detail,
      required: true,
    },
  },
});
