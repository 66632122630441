import { SnackbarState } from "./types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: SnackbarState = {
  isOpen: false,
  severity: "success",
  message: "",
};

const snackbarModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default snackbarModule;
