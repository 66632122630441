import { Actions } from "vuex";
import {
  ReceiptChargeRequestsState,
  ReceiptChargeRequestsGetters,
  ReceiptChargeRequestsMutations,
  ReceiptChargeRequestsActions,
} from "./types";

export const actions: Actions<
  ReceiptChargeRequestsState,
  ReceiptChargeRequestsActions,
  ReceiptChargeRequestsGetters,
  ReceiptChargeRequestsMutations
> = {};
