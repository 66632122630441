import { Getters } from "vuex";
import { LoginUserGetters, LoginUserState } from "./types";

export const getters: Getters<LoginUserState, LoginUserGetters> = {
  isQuestionnaireRequestingNeeded(state, getters) {
    return !(
      state.questionnaire_requesting &&
      state.questionnaire_requesting!.confirmed
    );
  },
};
