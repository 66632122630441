<template>
  <div>
    <img
      v-if="myPageConfig"
      :src="myPageConfig.image_path"
      alt="myPageImage"
      class="w-full sm:w-6/12 sm:px-1 my-0 mx-auto"
    />
    <div
      v-if="failedExchangeRequests.items.length !== 0"
      class="flex justify-center"
    >
      <div
        class="mx-2 mt-2 sm:w-6/12 w-full bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
      >
        <p>
          結果の確定していない応募があります。大変お手数ですが、結果の確定までしばらくお待ちください
        </p>
      </div>
    </div>
    <InfoMessages :infoMessages="infoMessages" />
    <Status :wallet="wallet" v-if="myPageConfig?.point_visibility" />
    <Charge
      v-if="myPageConfig && !myPageConfig.point_visibility"
      :consent-description-visible="false"
    />
    <ExchangeHistory />
    <CampaignDetailCards />
    <UserInquiryCard />
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "@consumer/store";
import Status from "@consumer/components/campaign/myPage/Status.vue";
import ExchangeHistory from "@consumer/components/campaign/myPage/ExchangeHistory.vue";
import InfoMessages from "@consumer/components/campaign/shared/InfoMessages.vue";
import CampaignDetailCards from "@consumer/components/campaign/shared/CampaignDetailCards.vue";
import Charge from "@consumer/components/campaign/entry/Charge.vue";
import UserInquiryCard from "@consumer/components/campaign/shared/UserInquiryCard.vue";

export default defineComponent({
  name: "MyPage",
  components: {
    Charge,
    Status,
    InfoMessages,
    ExchangeHistory,
    CampaignDetailCards,
    UserInquiryCard,
  },
  setup() {
    const { params } = useRoute();
    const store = useStore();
    const myPageConfig = computed(() => store.state.campaign.my_page_config);

    const wallet = computed(() => store.state.wallet);
    const failedExchangeRequests = computed(
      () => store.state.failedExchangeRequests
    );

    const infoMessages = computed(
      () => store.getters["campaign/my_page_info_messages"]
    );

    store.dispatch("promise/getMyPage", {
      campaign_url_code: params.campaign_url_code,
    });

    return { wallet, myPageConfig, failedExchangeRequests, infoMessages };
  },
});
</script>
