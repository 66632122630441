import { Mutations } from "vuex";
import { UiMutations, UiState } from "./types";

export const mutations: Mutations<UiState, UiMutations> = {
  updateIsAuthenticated(state, payload) {
    state.isAuthenticated = payload;
  },
  popupRedirectModal(state, payload) {
    state.redirectModal = { ...payload, isOpen: true };
  },
};
