<template>
  <div v-if="infoMessages" class="flex justify-center">
    <div class="sm:w-6/12 w-full">
      <BaseInfo
        v-for="infoMessage in infoMessages"
        :key="infoMessage"
        class="m-2"
      >
        <pre class="whitespace-pre-wrap">{{ infoMessage }}</pre>
      </BaseInfo>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import { useStore } from "@consumer/store/index";
export default defineComponent({
  props: {
    infoMessages: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const { infoMessages } = props;

    return {
      infoMessages,
    };
  },
});
</script>
