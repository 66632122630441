import { Mutations } from "vuex";
import { LoginUserState, LoginUserMutations } from "./types";

export const mutations: Mutations<LoginUserState, LoginUserMutations> = {
  updateLoginUser(state, payload) {
    Object.assign(state, payload);
  },
  updateLoginUserQuestionnaireRequesting(state, payload) {
    state.questionnaire_requesting = payload;
  },
};
