export const getLocaleDateString = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = addLeadingZero(date.getMonth() + 1);
  const day = addLeadingZero(date.getDate());
  return `${year}年${month}月${day}日`;
};

export const getLocaleDatetimeString = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = addLeadingZero(date.getMonth() + 1);
  const day = addLeadingZero(date.getDate());
  const hours = addLeadingZero(date.getHours());
  const minutes = addLeadingZero(date.getMinutes());
  return `${year}年${month}月${day}日 ${hours}時${minutes}分`;
};

const addLeadingZero = (dateNumber: number): string => {
  return (dateNumber < 10 ? "0" : "") + dateNumber;
};
