
import { defineComponent, PropType, ref, computed } from "vue";
import { useRoute } from "vue-router";
import ExchangeCards from "../shared/ExchangeCards.vue";
import { useStore } from "@consumer/store";
import { Wallet } from "@consumer/services/api/internalApi/wallets";
import lotterButton from "@assets/lottery_button.svg";
import { routeNames } from "@consumer/router/routeNames";
import { formatPointNumber } from "@shared/utils/formatPointNumber";

export default defineComponent({
  name: "Status",
  components: {
    ExchangeCards,
  },
  props: {
    wallet: {
      type: Object as PropType<Wallet>,
      required: true,
    },
  },
  setup() {
    const lotteryLogo = ref(lotterButton);
    const route = useRoute();
    const store = useStore();
    const entryLinkTo = computed(() => ({
      name: routeNames.CAMPAING_ENTRY,
      params: { campaign_url_code: route.params.campaign_url_code },
    }));

    const pointChargeButtonLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "my_page.status.point_charge_button_label",
        "ポイントをためる"
      )
    );
    const pointLabelTitle = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "my_page.status.point_label_title",
        "あなたのポイントは"
      )
    );
    const nextExchangeNeedPointPrefix = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "my_page.status.next_exchange_need_point_prefix",
        "次まであと"
      )
    );
    const nextExchangeNeedPointSuffix = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "my_page.status.next_exchange_need_point_suffix",
        "ポイント"
      )
    );
    const allExchangeEnalbe = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "my_page.status.all_exchange_enable",
        "全てに応募可能です"
      )
    );

    return {
      lotteryLogo,
      entryLinkTo,
      pointChargeButtonLabel,
      pointLabelTitle,
      nextExchangeNeedPointPrefix,
      nextExchangeNeedPointSuffix,
      allExchangeEnalbe,
      ...useGraph(),
    };
  },
});

const useGraph = () => {
  const store = useStore();
  const wallet = computed(() => store.state.wallet);
  const exchangeConfigs = computed(() => {
    const exchangeConfigs =
      store.getters["campaign/formatted_visible_exchange_configs"];
    return exchangeConfigs.length > 0
      ? exchangeConfigs
      : store.getters[
          "campaign/formatted_visible_exchange_config_groups"
        ].flatMap(
          (exchangeConfigGroup) => exchangeConfigGroup.exchange_configs
        );
  });

  const VIEW_LENGTH = 100;
  const CX = VIEW_LENGTH / 2;
  const CY = VIEW_LENGTH / 2;
  const RADIUS = CX - 10;
  const CIRCUMFERENCE = 2 * RADIUS * Math.PI;
  const strokeWidth = RADIUS / 5;

  const nextExchangeableConfigIndex = computed(() =>
    exchangeConfigs.value.findIndex(
      (exchangeConfig) => wallet.value.point < exchangeConfig.consumption_point
    )
  );

  const nextExchangeableConfig = computed(
    () => exchangeConfigs.value[nextExchangeableConfigIndex.value]
  );

  const exchangeableConfig = computed(
    () => exchangeConfigs.value[nextExchangeableConfigIndex.value - 1]
  );

  const exchangeableConfigConsumptionPoint = computed(() => {
    return exchangeableConfig.value
      ? exchangeableConfig.value.consumption_point
      : 0;
  });

  const goalPoint = computed(() => {
    if (!nextExchangeableConfig.value) {
      // wallet.point が全ての ExchangeConfig.consumption_point より高い場合、グラフを塗りつぶすために 1 にしています
      return 1;
    }
    return (
      nextExchangeableConfig.value.consumption_point -
      exchangeableConfigConsumptionPoint.value
    );
  });

  const reachedPoint = computed(
    () => wallet.value.point - exchangeableConfigConsumptionPoint.value
  );

  const scale = computed(() => CIRCUMFERENCE / goalPoint.value);
  const strokeDasharray = computed(
    () =>
      `${reachedPoint.value * scale.value} ${
        CIRCUMFERENCE - reachedPoint.value * scale.value
      }`
  );

  const walletPoint = computed(() => formatPointNumber(wallet.value.point));
  const nextExchangePoint = computed(
    () =>
      nextExchangeableConfig.value &&
      formatPointNumber(
        nextExchangeableConfig.value.consumption_point - wallet.value.point
      )
  );

  return {
    walletPoint,
    nextExchangePoint,
    VIEW_LENGTH,
    CX,
    CY,
    RADIUS,
    CIRCUMFERENCE,
    strokeDasharray,
    strokeWidth,
  };
};
