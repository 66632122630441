<template>
  <div class="h-8 flex my-3 justify-center">
    <label
      for="exchangeStatus"
      class="w-16 text-white text-sm flex justify-center items-center rounded mr-1"
      :class="{
        'bg-primary-light': isAdding,
        'bg-gray-500': !isAdding,
      }"
    >
      {{ resultLabel }}
    </label>
    <label
      for="exchangeStatus"
      class="w-16 text-xs flex justify-center items-center rounded mr-1"
      :class="{
        'text-primary-light': isAdding,
        'text-gray-500': !isAdding,
      }"
    >
      {{ transactedPoint }}
    </label>
    <p
      class="flex items-center justify-center m-0 text-gray-600 text-xs sm:text-sm"
    >
      {{ loggedAt }}
    </p>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import { PointLog } from "@consumer/services/api/internalApi/pointLogs";
import { getFormatDateTime } from "@shared/utils/dayjs";
export default defineComponent({
  name: "PointLog",
  props: {
    pointLog: {
      type: Object as PropType<PointLog>,
      required: true,
    },
  },
  setup(props) {
    const loggedAt = computed(() =>
      getFormatDateTime(props.pointLog.logged_at)
    );

    const resultLabel = computed(() =>
      props.pointLog.before_point < props.pointLog.after_point ? "獲得" : "消費"
    );

    const transactedPoint = computed(() => {
      const point = props.pointLog.after_point - props.pointLog.before_point;
      return point > 0 ? `+${point}` : point;
    });

    const isAdding = computed(() => {
      const point = props.pointLog.after_point - props.pointLog.before_point;
      return point > 0;
    });

    return { loggedAt, resultLabel, transactedPoint, isAdding };
  },
});
</script>
