
import { defineComponent, PropType, computed } from "vue";
import { PointLog } from "@consumer/services/api/internalApi/pointLogs";
import { getFormatDateTime } from "@shared/utils/dayjs";
export default defineComponent({
  name: "PointLog",
  props: {
    pointLog: {
      type: Object as PropType<PointLog>,
      required: true,
    },
  },
  setup(props) {
    const loggedAt = computed(() =>
      getFormatDateTime(props.pointLog.logged_at)
    );

    const resultLabel = computed(() =>
      props.pointLog.before_point < props.pointLog.after_point ? "獲得" : "消費"
    );

    const transactedPoint = computed(() => {
      const point = props.pointLog.after_point - props.pointLog.before_point;
      return point > 0 ? `+${point}` : point;
    });

    const isAdding = computed(() => {
      const point = props.pointLog.after_point - props.pointLog.before_point;
      return point > 0;
    });

    return { loggedAt, resultLabel, transactedPoint, isAdding };
  },
});
