
import {
  defineComponent,
  computed,
  ref,
  toRefs,
  onMounted,
  PropType,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@consumer/store";
import { ChargeConfig } from "@consumer/services/api/publicApi/campaigns";

export default defineComponent({
  name: "ChargeSerialCharge",
  props: {
    chargeConfig: {
      type: Object as PropType<ChargeConfig>,
      required: true,
    },
    chargeable: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const errorMessage = computed(() =>
      store.state.promise.errors
        ? store.state.promise.errors.invalid_params?.filter(
            (param) => param.name === "code"
          )[0]?.reason || store.state.promise.errors.title
        : null
    );
    const { chargeable, chargeConfig } = toRefs(props);
    const formVisible = computed(
      () => !!chargeConfig.value.serial_option?.form_visibility
    );
    const buttonLabel = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "charge_serial.charge_button_label",
        "ポイントを獲得する"
      )
    );
    const chargeDescription = computed(() =>
      store.getters["campaign/getCustomizingWord"](
        "charge_serial.description",
        "シリアルコードを入力してください"
      )
    );

    return {
      errorMessage,
      chargeConfig,
      chargeable,
      formVisible,
      buttonLabel,
      chargeDescription,
      ...useSerialCode(),
    };
  },
});

const useSerialCode = () => {
  const { query, params } = useRoute();
  const store = useStore();
  const router = useRouter();
  const serialCode = ref("");

  onMounted(() => {
    if (Array.isArray(query.serial_code)) {
      if (query.serial_code[0]) {
        serialCode.value = query.serial_code[0];
      }
    } else {
      serialCode.value = query.serial_code || "";
    }
  });

  const postChargeSerialCharging = (chargeConfigUrlCode: string) => {
    store.dispatch("promise/postChargeSerialCharging", {
      campaign_url_code: params.campaign_url_code,
      charge_config_url_code: chargeConfigUrlCode,
      serial_code: serialCode.value,
      router,
    });
  };

  return { serialCode, postChargeSerialCharging };
};
