import { PromiseActionContext, PromiseActions } from "../types";
import { routeNames } from "@consumer/router/routeNames";
import { internalApi } from "@consumer/services/api";
import * as Sentry from "@sentry/browser";

export const postChargeRequestImmediatelyExchanging = async (
  ctx: PromiseActionContext,
  payload: PromiseActions["postChargeRequestImmediatelyExchanging"]
) => {
  ctx.commit(
    "exchange/updateExchangeConfigUrlCode",
    payload.exchange_config_url_code,
    {
      root: true,
    }
  );
  ctx.commit("exchange/updateForceDefeat", false, { root: true });
  payload.router.push({
    name: routeNames.EXCHANGE_LOTTERY_IMAGE,
    params: { campaign_url_code: payload.campaign_url_code },
  });
  ctx.commit("postChargeRequestImmediatelyExchangingStart");
  try {
    const result = await internalApi.chargeRequests.immediatelyExchanging.post(
      payload.campaign_url_code,
      payload.charge_request_request_identity
    );

    ctx.commit(
      "exchangeResults/pushExchangeResult",
      result.data.exchange_result,
      {
        root: true,
      }
    );

    ctx.commit("postChargeRequestImmediatelyExchangingSuccess", result);
  } catch (error) {
    Sentry.captureException(error);
    ctx.dispatch("handleError", error);
    ctx.commit("postChargeRequestImmediatelyExchangingFailure");
  }
};
