import { internalApiClient } from "../client";
import { RouteParams } from "vue-router";
import {
  QuestionnaireRequesting,
  questionnaireRequesting,
} from "./loginUser/questionnaireRequestings";

const path = "/campaigns";

const getLoginUser = (campaignUrlCode: RouteParams["number"]) => {
  const campaignUrlCodeStr =
    typeof campaignUrlCode === "string" ? campaignUrlCode : campaignUrlCode[0];

  return internalApiClient.get<LoginUser>(
    `${path}/${campaignUrlCodeStr}/login_user`
  );
};

export type LoginUser = {
  questionnaire_requesting: QuestionnaireRequesting | null;
};

export const loginUsers = {
  get: getLoginUser,
  questionnaireRequesting: questionnaireRequesting,
};

